import React from "react";
import { FaEnvelope, FaInstagram, FaTwitter } from "react-icons/fa";
import "./Footer.css";

const Footer = () => {
  return (
    <footer>
      <div className="footer-content">
        <div className="footer-icons">
          <a
            href={`mailto:${encodeURIComponent("takefive.biz@gmail.com")}`}
            className="footer-icon"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaEnvelope />
          </a>
          <a
            href="https://instagram.com/take___five5?igshid=MmIzYWVlNDQ5Yg=="
            className="footer-icon"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaInstagram />
          </a>
          <a
            href="https://twitter.com/take__five5"
            className="footer-icon"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaTwitter />
          </a>
        </div>
        <p className="copy-right">© 2023 테이크파이브 All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
