import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { questionData } from "./data/questionData";
import "./MoodQuestionen.css";
import Take5 from "../../../../components/Take5/Take5";
// import AdSenseComponent from "../../../../AdSenseComponent";

const MoodQuestionen = () => {
  const [questionNo, setQuestionNo] = useState(0);
  const [totalScore, setTotalScore] = useState([
    { id: "EI", score: 0 },
    { id: "SN", score: 0 },
    { id: "TF", score: 0 },
    { id: "JP", score: 0 },
  ]);

  const navigate = useNavigate();

  const handleClickButton = (no, type) => {
    const newScore = totalScore.map((s) =>
      s.id === type ? { id: s.id, score: s.score + no } : s
    );
    setTotalScore(newScore);
    if (questionData.length !== questionNo + 1) {
      setQuestionNo(questionNo + 1);
    } else {
      const mbti = newScore.reduce(
        (acc, curr) =>
          acc +
          (curr.score >= 2 ? curr.id.substring(0, 1) : curr.id.substring(1, 2)),
        ""
      );
      navigate(`/test/en/yourmooden/result/${mbti}`);
    }
  };
  return (
    <div className="mooden_Q_container">
      <div className="mooden_Q_Wrapper">
        <Take5 />
        <h3 className="mooden_num">{questionData[questionNo].id}</h3>
        <div className="mooden_title_container">
          <h2 className="mooden_question_title">
            {questionData[questionNo].title}
          </h2>
        </div>
        <div className="mooden_select_btn_container">
          <button
            className="mooden_select_btn"
            onClick={() => handleClickButton(1, questionData[questionNo].type)}
            type="button"
          >
            {questionData[questionNo].answera}
          </button>
          <button
            className="mooden_select_btn"
            onClick={() => handleClickButton(0, questionData[questionNo].type)}
            type="button"
          >
            {questionData[questionNo].answerb}
          </button>
        </div>
        {/* <AdSenseComponent /> */}
      </div>
    </div>
  );
};

export default MoodQuestionen;
