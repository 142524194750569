import React, { useEffect } from "react";
import "./LoveBalanceMain.css";
import { useNavigate } from "react-router-dom";
import Take5 from "../../../../components/Take5/Take5";
import mainImg from "./img/lovebalance_main.png";
import SEO from "../../SEOko";
import { lovebalanceAttributeko } from "../../seoAttributesko";
import AdSenseComponent from "../../../../AdSenseComponent";

const LoveBalanceMain = () => {
  const navigate = useNavigate();
  const rpGotoQ = () => {
    navigate("/test/lovebalance/question");
  };

  const id = "lovebalance";

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="lovebalance_wrapper">
      <div className="lovebalance_contents">
        <SEO attribute={lovebalanceAttributeko} id={id} />
        <Take5 />
        <div className="lovebalance_main_container">
          <img src={mainImg} alt="main" />

          <button onClick={rpGotoQ} className="lovebalance_start_btn_container">
            시작하기
          </button>
        </div>
      </div>
      <AdSenseComponent />
    </div>
  );
};

export default LoveBalanceMain;
