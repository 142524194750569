export const QuestionData = [
  {
    id: 1,
    title: "What do I do on \na weekend without a date?",
    answera: "Alone or hang out with friends",
    answerb: "Stay home alone",
    type: "EI",
  },
  {
    id: 2,
    title:
      "If you have to go on a 2-night 3-day \ntrip with your lover's friends, \nyou would say?",
    answera: "Sounds great! It'll be fun!",
    answerb: "(It might be a bit tiring)\nIf you want to go, let's go.",
    type: "EI",
  },
  {
    id: 3,
    title: "My partner wants to go on dates \nthroughout the week. I...",
    answera: "I'm fine with that.",
    answerb: "I need some alone time...",
    type: "EI",
  },
  {
    id: 4,
    title: "I went on a blind date. \nWhen I see my date, I...",
    answera: "Make a somewhat initial judgment based on first impressions.",
    answerb: "Try to understand what kind of person they are before judging.",
    type: "SN",
  },
  {
    id: 5,
    title:
      "I get along really well with the person\n I was introduced to. \nAt this point, my feelings are...",
    answera: "Wow, I can't believe\n we get along this well!",
    answerb: "I imagine getting married.",
    type: "SN",
  },
  {
    id: 6,
    title:
      "If my partner asks, \n'If we were to break up, what do you \nthink would be the reason?'",
    answera: "Why are you thinking like that?",
    answerb: "Well... (seriously considers)",
    type: "SN",
  },
  {
    id: 7,
    title:
      "If the person I have a crush \non texts me, 'The moon is beautiful,' \nI would respond with...",
    answera: "Really? (checks the moon)",
    answerb:
      "Did you think of me? Did you need a reason to contact me? Anyway, it's cute.",
    type: "TF",
  },
  {
    id: 8,
    title: "If my partner reads \nmy message and doesn't reply...",
    answera: "They must be busy.",
    answerb: "Ugh... Why isn't there any contact...?",
    type: "TF",
  },
  {
    id: 9,
    title: "When listening to\n my partner's concerns, \nI focus on...",
    answera: "Finding a solution.",
    answerb: "Empathizing with their feelings.",
    type: "TF",
  },
  {
    id: 10,
    title: "On the day of a planned date, I...",
    answera:
      "Calculate and prepare, taking into account preparation time, travel time, and even some spare time.",
    answerb: "Is it okay to just go out roughly around this time?",
    type: "JP",
  },
  {
    id: 11,
    title:
      "My partner is coming over \nto my house tomorrow. \nAt that time, I...",
    answera:
      "Clean up in advance and make sure I have essential household items in stock.",
    answerb: "I'll buy whatever is needed at that time.",
    type: "JP",
  },
  {
    id: 12,
    title: "If my partner suddenly \nsuggests going on a trip, I...",
    answera: "What about plane tickets?\n Car rental? Accommodation?",
    answerb: "Oh yeah~ Let's just go for it!!!",
    type: "JP",
  },
];
