import catQ1 from "../img/catQ1.png";
import catQ2 from "../img/catQ2.png";
import catQ3 from "../img/catQ3.png";
import catQ4 from "../img/catQ4.png";
import catQ5 from "../img/catQ5.png";
import catQ6 from "../img/catQ6.png";
import catQ7 from "../img/catQ7.png";
import catQ8 from "../img/catQ8.png";
import catQ9 from "../img/catQ9.png";
import catQ10 from "../img/catQ10.png";
import catQ11 from "../img/catQ11.png";
import catQ12 from "../img/catQ12.png";
import catQ13 from "../img/catQ13.png";

export const QuestionData = [
  {
    question: "어떤 냥이인가요?",
    answers: [
      { option: "집냥이", score: 1 },
      { option: "마당냥이", score: 2 },
      { option: "길냥이", score: 3 },
    ],
    image: catQ1,
  },
  {
    question: "꾹꾹이를 자주 하나요?",
    answers: [
      { option: "네, 자주 해요", score: 3 },
      { option: "가끔 해요", score: 2 },
      { option: "아니요, 거의 안 해요", score: 1 },
    ],
    image: catQ2,
  },
  {
    question: "당신 곁에서 \n자주 골골거리나요?",
    answers: [
      { option: "네, 자주 해요", score: 3 },
      { option: "가끔 해요", score: 2 },
      { option: "아니요, 거의 안 해요", score: 1 },
    ],
    image: catQ3,
  },
  {
    question: "당신의 얼굴이나 몸에 \n얼굴을 비비나요?",
    answers: [
      { option: "네, 자주 비벼요", score: 3 },
      { option: "가끔 비벼요", score: 2 },
      { option: "아니요, 거의 안 비벼요", score: 1 },
    ],
    image: catQ4,
  },
  {
    question: "머리박치기를 자주 하나요?",
    answers: [
      { option: "네, 자주 해요", score: 3 },
      { option: "가끔 해요", score: 2 },
      { option: "아니요, 거의 안 해요", score: 1 },
    ],
    image: catQ5,
  },
  {
    question: "곤충이나 벌레처럼 \n작은 선물을 가져다 준 적이 있나요?",
    answers: [
      { option: "네, 자주 가져다줘요", score: 3 },
      { option: "가끔 가져다줘요", score: 2 },
      { option: "아니요, 거의 없어요", score: 1 },
    ],
    image: catQ6,
  },
  {
    question: "배를 드러내고 \n누운 적이 자주 있나요?",
    answers: [
      { option: "네, 자주 드러내요", score: 3 },
      { option: "가끔 드러내요", score: 2 },
      { option: "아니요, 거의 안 드러내요", score: 1 },
    ],
    image: catQ7,
  },
  {
    question: "천천히 눈을 깜박이며\n 당신을 바라보나요?",
    answers: [
      { option: "네, 자주 깜박여요", score: 3 },
      { option: "가끔 깜박여요", score: 2 },
      { option: "아니요, 거의 안 깜박여요", score: 1 },
    ],
    image: catQ8,
  },
  {
    question: "당신 곁에서 \n자는 것을 좋아하나요?",
    answers: [
      { option: "네, 자주 곁에서 자요", score: 3 },
      { option: "가끔 곁에서 자요", score: 2 },
      { option: "아니요, 혼자 자는 걸 좋아해요", score: 1 },
    ],
    image: catQ9,
  },
  {
    question: "당신을 여기저기 \n따라다니나요?",
    answers: [
      { option: "네, 자주 따라다녀요", score: 3 },
      { option: "가끔 따라다녀요", score: 2 },
      { option: "아니요, 거의 안 따라다녀요", score: 1 },
    ],
    image: catQ10,
  },
  {
    question: "당신을 부드럽게 \n무는 행동을 하나요?",
    answers: [
      { option: "네, 자주 부드럽게 물어요", score: 3 },
      { option: "가끔 부드럽게 물어요", score: 2 },
      { option: "아니요, 거의 안 물어요", score: 1 },
    ],
    image: catQ11,
  },
  {
    question: "당신의 무릎이나\n 몸 위에 앉기를 좋아하나요?",
    answers: [
      { option: "네, 자주 앉아요", score: 3 },
      { option: "가끔 앉아요", score: 2 },
      { option: "아니요, 거의 안 앉아요", score: 1 },
    ],
    image: catQ12,
  },
  {
    question: "당신의 손이나 \n얼굴을 핥나요?",
    answers: [
      { option: "네, 자주 핥아요", score: 3 },
      { option: "가끔 핥아요", score: 2 },
      { option: "아니요, 거의 안 핥아요", score: 1 },
    ],
    image: catQ13,
  },
];
