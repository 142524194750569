import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Take5 from "../../../../components/Take5/Take5";
import "./WordtypeQ.css";
import wt_Q from "./img/wordtype_Q.png";

import dumbbell from "./img/dumbbell.png";
import tree from "./img/tree.png";
import book from "./img/book.png";
import sun from "./img/sun.png";
import palette from "./img/palette.png";
import balloon from "./img/balloon.png";
import animal from "./img/animal.png";
import home from "./img/home.png";
import moon from "./img/moon.png";

const activitiesList = [
  { id: 1, name: "덤벨", icon: dumbbell },
  { id: 2, name: "책", icon: book },
  { id: 3, name: "해", icon: sun },
  { id: 4, name: "팔레트", icon: palette },
  { id: 5, name: "풍선", icon: balloon },
  { id: 6, name: "동물", icon: animal },
  { id: 7, name: "집", icon: home },
  { id: 8, name: "달", icon: moon },
  { id: 9, name: "나무", icon: tree },
];

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const WordtypeQ = () => {
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [shuffledActivities, setShuffledActivities] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setShuffledActivities(shuffleArray([...activitiesList]));
  }, []);

  const toggleActivity = (activity) => {
    setSelectedActivities((prevSelected) => {
      if (prevSelected.includes(activity)) {
        return prevSelected.filter((a) => a !== activity);
      } else if (prevSelected.length < 3) {
        return [...prevSelected, activity];
      } else {
        return prevSelected;
      }
    });
  };

  const moveActivityUp = (index) => {
    if (index === 0) return;
    const newSelected = [...selectedActivities];
    [newSelected[index - 1], newSelected[index]] = [
      newSelected[index],
      newSelected[index - 1],
    ];
    setSelectedActivities(newSelected);
  };

  const moveActivityDown = (index) => {
    if (index === selectedActivities.length - 1) return;
    const newSelected = [...selectedActivities];
    [newSelected[index + 1], newSelected[index]] = [
      newSelected[index],
      newSelected[index + 1],
    ];
    setSelectedActivities(newSelected);
  };

  const goToResults = () => {
    if (selectedActivities.length === 3) {
      navigate("/test/wordtype/result", { state: { selectedActivities } });
    } else {
      alert("3개의 활동을 선택해주세요.");
    }
  };

  return (
    <div className="wt_Q_Wrapper">
      <Take5 />
      <div className="wt_Q_Contents">
        <div className="wt_Q_Title">
          <img src={wt_Q} alt="title" />
          <h2 className="wt_Q_Title2">
            <span className="wt_Q_Title2_span">눈에 들어오는 단어 3개</span>
            를
            <br />
            차례로 선택해주세요.
          </h2>
        </div>
        <div className="wt_activities">
          {shuffledActivities.map((activity) => (
            <div
              key={activity.id}
              className={`wt_activity ${
                selectedActivities.includes(activity) ? "selected" : ""
              }`}
              onClick={() => toggleActivity(activity)}
            >
              <img
                src={activity.icon}
                alt={activity.name}
                className="wt_activity_icon"
              />
              <span className="wt_activity_name">{activity.name}</span>
            </div>
          ))}
        </div>
        {selectedActivities.length > 0 && (
          <div className="wt_selected_activities">
            <h3 className="wt_selected_h3">선택한 단어</h3>
            {selectedActivities.map((activity, index) => (
              <div key={activity.id} className="wt_selected_activity">
                <button onClick={() => moveActivityUp(index)}>▲</button>
                <span className="wt_selected_activity_container">
                  <img
                    src={activity.icon}
                    alt={activity.name}
                    className="wt_selected_activity_icon"
                  />
                  {activity.name}
                </span>
                <button onClick={() => moveActivityDown(index)}>▼</button>
              </div>
            ))}
          </div>
        )}
        <button className="wt_results_btn" onClick={goToResults}>
          결과보기
        </button>
      </div>
    </div>
  );
};

export default WordtypeQ;
