export const questionData = [
  {
    id: 1,
    title: "What is the most common comment \nabout your first impression?",
    answera: "Energetic",
    answerb: "Calm",
    type: "EI",
  },
  {
    id: 2,
    title: "Which day feels more \ncomfortable for you?",
    answera: "A busy day interacting \nwith people",
    answerb: "A quiet day alone, \ndoing my own thing",
    type: "EI",
  },
  {
    id: 3,
    title: "When meeting new people, \nwhat attitude do you usually have?",
    answera: "Try to take the lead in the conversation",
    answerb: "Observe from a certain distance",
    type: "EI",
  },

  {
    id: 4,
    title: "What type of stress do you\n primarily experience?",
    answera: "Tasks that need to \nbe decided quickly",
    answerb: "Things that haven't happened yet\nbut seem imminent",
    type: "SN",
  },
  {
    id: 5,
    title: "Do you often engage in \nimagination or daydreaming?",
    answera: "No",
    answerb: "Yes",
    type: "SN",
  },
  {
    id: 6,
    title: "When telling a story to someone, \nhow do you usually approach it?",
    answera: "Present the sequence of events in\na clear and systematic manner",
    answerb:
      "While presenting the sequence of events, intermittently express my thoughts and feelings through metaphors",
    type: "SN",
  },

  {
    id: 7,
    title:
      "Your friend accidentally damaged\n your belongings. Which apology \nwould make you feel better?",
    answera: "Explaining the circumstances \nof the incident",
    answerb: "Sincerely apologizing",
    type: "TF",
  },
  {
    id: 8,
    title: "What do you value more?",
    answera: "Logic, rational analysis",
    answerb: "Emotion, emotional expression",
    type: "TF",
  },
  {
    id: 9,
    title:
      "You sent a message to an acquaintance \nafter a long time, but strangely there is no reply. \nWhat thought crosses your mind?",
    answera: "Did their phone number change?",
    answerb: "Is there something \nthey're mad at me about?",
    type: "TF",
  },
  {
    id: 10,
    title: "A day that doesn't go as planned.\n What thoughts come to mind?",
    answera: "(...So annoying,\nI just want to end the day quickly)",
    answerb:
      "(Well, today is not going as planned. \nThere are days like this, right?)",
    type: "JP",
  },
  {
    id: 11,
    title: "When planning, do you tend to\n think about Plans B and C?",
    answera: "Yes",
    answerb: "No",
    type: "JP",
  },
  {
    id: 12,
    title: "Can you easily throw yourself \ninto uncertain possibilities?",
    answera: "If I really have to,\n but... do I have to?",
    answerb: "If it seems interesting,\n I'll do it right away",
    type: "JP",
  },
];
