import React from "react";
import "./CatlovesMeRen.css";
import Footer from "../../../../components/Footer/Footer";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import EnShareButton from "./EnShareButton";
import { SyncLoader } from "react-spinners";
import Take5 from "../../../../components/Take5/Take5";
import Card from "../../Carden";
import TestList from "../../TestListen.json";
import level1 from "./img/level1.png";
import level2 from "./img/level2.png";
import level3 from "./img/level3.png";
import level4 from "./img/level4.png";
import level5 from "./img/level5.png";
import level6 from "./img/level6.png";
import AdSenseComponent from "../../../../AdSenseComponent";

const CatlovesMeRen = () => {
  const navigate = useNavigate();
  const [testList, setTestList] = useState([]);

  const location = useLocation();
  const totalScore = location.state.totalScore;

  const excludedTestId = "catlovesmeen";
  const filteredTestList = testList.filter(
    (test) => test.id !== excludedTestId
  );

  useEffect(() => {
    setTestList(TestList);
  }, []);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, []);

  let result = "";
  if (totalScore >= 12 && totalScore <= 16) {
    result = level1;
  } else if (totalScore >= 17 && totalScore <= 20) {
    result = level2;
  } else if (totalScore >= 21 && totalScore <= 24) {
    result = level3;
  } else if (totalScore >= 25 && totalScore <= 28) {
    result = level4;
  } else if (totalScore >= 29 && totalScore <= 32) {
    result = level5;
  } else if (totalScore >= 33 && totalScore <= 39) {
    result = level6;
  }

  return (
    <div className="en_clm_R_Wrapper">
      {loading ? (
        <div className="en_clm_loading_container">
          <p className="en_clm_loading_text">Measuring Affection Level...💗</p>
          <SyncLoader
            color={"#000000"}
            loading={loading}
            size={9}
            speedMultiplier={0.5}
            cssOverride={{
              marginTop: "3rem",
              backgroundColor: "transparent",
              marginBottom: "3rem",
            }}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
          <AdSenseComponent />
        </div>
      ) : (
        <>
          <Take5 />
          <div className="en_clm_R_Contents">
            <div className="en_clm_R_ResultImg_Container">
              {result && <img src={result} alt="result" />}
            </div>
            <AdSenseComponent />
            <div className="en_clm_R_Restart_Btn_Container">
              <button
                className="en_clm_R_Restart_Btn"
                style={{
                  minWidth: "210px",
                  maxWidth: "210px",
                  minHeight: "50px",
                  boxShadow: "none",
                }}
                onClick={() => navigate("/test/en/catlovesmeen")}
              >
                Retake Test!
              </button>
            </div>
            <EnShareButton />
            <div className="TestList_container">
              {filteredTestList.map((test) => (
                <div key={test.id} className="TestList_container2">
                  <Link to={`/test/en/${test.id}`}>
                    <Card test={test} />
                  </Link>
                </div>
              ))}
            </div>
            <Footer />
          </div>
        </>
      )}
    </div>
  );
};

export default CatlovesMeRen;
