import React, { useState } from "react";
import "./LoveBalanceQ.css";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import { useNavigate } from "react-router-dom";
import { QuestionData } from "./data/QuestionData";
import Take5 from "../../../../components/Take5/Take5";
import backImg from "./img/lovabalanceQ_back.png";

const LoveBalanceQ = () => {
  const [questionNo, setQuestionNo] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [clickedAnswer, setClickedAnswer] = useState(null); // 새로운 상태 추가
  const navigate = useNavigate();

  const handleClickButton = (answer) => {
    setSelectedAnswers((prevAnswers) => [...prevAnswers, answer]);
    setClickedAnswer(answer);

    if (QuestionData.length !== questionNo + 1) {
      // 다음 문제로 이동
      setQuestionNo(questionNo + 1);
      setClickedAnswer(null); // 다음 문제로 넘어가면 다시 초기화
    } else {
      // 결과 페이지로 이동
      navigate("/test/lovebalance/result", {
        state: { selectedAnswers: selectedAnswers.concat(answer) },
      });
    }
  };

  const isLastQuestion = questionNo === QuestionData.length - 1;
  const isAnswerClicked = clickedAnswer !== null; // 선택지를 클릭했는지 여부

  return (
    <div className="lovebalance_Q_Wrapper">
      <Take5 />
      <Progress
        percent={(questionNo / QuestionData.length) * 100}
        status="error"
        theme={{
          error: {
            symbol: "⚖️",
            color: "#98e0a195",
          },
        }}
      />
      <div className="lovebalance_Q_BackImg">
        <img src={backImg} alt="rp_back" />
      </div>
      <div className="lovebalance_Q_Contents">
        <div className="lovebalance_Q_Title">
          {QuestionData[questionNo].title}
        </div>
        <div className="lovebalance_Q_Btn_Container">
          <button
            className="lovebalance_Q_Btn"
            onClick={() => handleClickButton(QuestionData[questionNo].answera)}
            type="button"
            disabled={isAnswerClicked}
          >
            {QuestionData[questionNo].answera}
          </button>
          <p className="lovebalance_R_vs">VS</p>
          <button
            className="lovebalance_Q_Btn"
            onClick={() => handleClickButton(QuestionData[questionNo].answerb)}
            type="button"
            disabled={isAnswerClicked}
          >
            {QuestionData[questionNo].answerb}
          </button>
        </div>
        {isLastQuestion && isAnswerClicked && (
          <button
            className="lovebalance_Q_Btn"
            onClick={() => {
              console.log("User Selected Answers:", selectedAnswers);
              navigate("/test/lovebalance/result", {
                state: { selectedAnswers },
              });
            }}
            type="button"
          >
            결과 보기
          </button>
        )}
      </div>
    </div>
  );
};

export default LoveBalanceQ;
