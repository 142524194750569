import React, { useState, useEffect } from "react";
import Footer from "../../../../components/Footer/Footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import { resultData } from "./data/resultData";
import "./MoodResult.css";
import ReactGA from "react-ga";
import { MoonLoader } from "react-spinners";
import ShareButton from "./ShareButton";
import Take5 from "../../../../components/Take5/Take5";
import CoupangBanner from "./CoupangBanner";
import Card from "../../Cardko";
import TestList from "../../TestListko.json";

const MoodResult = () => {
  const navigate = useNavigate();
  const { mbti } = useParams();
  const [data, setData] = useState({});
  const [showResult, setShowResult] = useState(false);
  const [hasCookie, setHasCookie] = useState(false);
  const [testList, setTestList] = useState([]);

  const excludedTestId = "yourmood";
  const filteredTestList = testList.filter(
    (test) => test.id !== excludedTestId
  );

  useEffect(() => {
    // 결과를 찾을 때 동적으로 읽어온 MBTI 유형을 사용
    const result = resultData.find((s) => s.best === mbti);
    setData(result);
    ReactGA.pageview(window.location.pathname);
  }, [mbti]);

  useEffect(() => {
    setTestList(TestList);
  }, []);

  const reloadBtn = () => {
    navigate("/test/yourmood");
  };

  useEffect(() => {
    const result = resultData.find((s) => s.best === mbti);
    setData(result);
    ReactGA.pageview(window.location.pathname);
  }, [mbti]);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3500);
  }, []);

  useEffect(() => {
    setHasCookie(document.cookie.indexOf("coupang=true") !== -1);
  }, []);

  const handleClick = () => {
    setShowResult(true);
    handleResultShow();
  };

  const handleResultShow = () => {
    const resultContainer = document.querySelector(".mood_no_cookie");
    resultContainer.style.overflow = "visible";
    resultContainer.style.height = "100vh";
  };

  return (
    <>
      <div className="mood_result_container">
        <div className="mood_R_Wrapper">
          {loading ? (
            <MoonLoader
              color={"#754826"}
              loading={loading}
              size={90}
              cssOverride={{
                marginTop: "15rem",
                marginBottom: "20rem",
                backgroundColor: "transparent",
              }}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          ) : (
            <>
              <Take5 />
              {hasCookie ? (
                <>
                  <div className="mood_has_cookie">
                    <h1 className="mood_result_main_text">
                      당신의 분위기 TEST
                    </h1>
                    <h1 className="mood_result_sub_text">
                      남들이 보는 당신의 분위기는...
                    </h1>
                    <div className="mood_result_image">
                      <img src={data.image} alt={data.title} />
                    </div>
                    <div className="mood_result_sub_title">{data.title}</div>
                    <div className="mood_desc_container">
                      <div className="mood_result_desc">{data.desc}</div>
                    </div>
                    <div className="mood_result_btn_container">
                      <button className="mood_restart_btn" onClick={reloadBtn}>
                        다시하기
                      </button>

                      <ShareButton />
                      <div className="TestList_container">
                        {filteredTestList.map((test) => (
                          <div key={test.id} className="TestList_container2">
                            <Link to={`/test/${test.id}`}>
                              <Card test={test} />
                            </Link>
                          </div>
                        ))}
                      </div>
                      <Footer />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="mood_no_cookie_container">
                    <div className="mood_no_cookie">
                      <h1 className="mood_result_main_text">
                        당신의 분위기 TEST
                      </h1>
                      <h1 className="mood_result_sub_text">
                        남들이 보는 당신의 분위기는...
                      </h1>
                      <div className="mood_result_image">
                        <img src={data.image} alt={data.title} />
                      </div>
                      <div className="mood-coupang-banner">
                        <CoupangBanner handleClick={handleClick} />
                      </div>
                      {showResult && (
                        <div className="mood_showResult">
                          <div className="mood_result_sub_title">
                            {data.title}
                          </div>
                          <div className="mood_result_desc">{data.desc}</div>
                          <div className="mood_result_btn_container">
                            <button
                              className="mood_restart_btn"
                              onClick={reloadBtn}
                            >
                              다시하기
                            </button>

                            <ShareButton />
                            <div className="TestList_container">
                              {filteredTestList.map((test) => (
                                <div
                                  key={test.id}
                                  className="TestList_container2"
                                >
                                  <Link to={`/test/${test.id}`}>
                                    <Card test={test} />
                                  </Link>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default MoodResult;
