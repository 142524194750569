export const QuestionData = [
  {
    id: 1,
    title: "How do you spend your leisure time?",
    answera: "Socializing with others",
    answerb: "Relaxing alone",
    type: "EI",
  },
  {
    id: 2,
    title: "In a new environment, I...",
    answera: "Approach others first",
    answerb: "Quietly observe my surroundings",
    type: "EI",
  },
  {
    id: 3,
    title: "I usually...",
    answera: "Lead the conversation",
    answerb: "Listen mostly and speak only\n when necessary",
    type: "EI",
  },
  {
    id: 4,
    title: "When telling a story to a friend, I...",
    answera: "Relay the facts accurately",
    answerb: "Add feelings and meaning\nto the story",
    type: "SN",
  },
  {
    id: 5,
    title: "When judging a person, I...",
    answera: "Rely on first impressions and feelings",
    answerb: "Have my own set of criteria for judgment",
    type: "SN",
  },
  {
    id: 6,
    title: "Before starting a task, I...",
    answera: "Prepare all necessary materials and tools in advance",
    answerb: "Start with an overall plan first",
    type: "SN",
  },
  {
    id: 7,
    title: "What's important \nwhen making a decision?",
    answera: "Logical and objective facts",
    answerb: "People's emotions and relationships",
    type: "TF",
  },
  {
    id: 8,
    title: "When conflicts arise, I...",
    answera: "Analyze the situation and find solutions",
    answerb: "Understand each other's emotions and find a compromise",
    type: "TF",
  },
  {
    id: 9,
    title: "Which type of person \ndo you prefer?",
    answera: "Logical and consistent",
    answerb: "Warm and understanding",
    type: "TF",
  },
  {
    id: 10,
    title: "When planning a vacation, I...",
    answera: "Plan out every detail",
    answerb: "Set a general plan",
    type: "JP",
  },
  {
    id: 11,
    title: "When you have a deadline, you...",
    answera: "Finish well before the deadline",
    answerb: "Adjust and finish just in time",
    type: "JP",
  },
  {
    id: 12,
    title: "When managing your schedule, you...",
    answera: "Make detailed plans and follow them exactly",
    answerb: "Set a general framework and proceed flexibly",
    type: "JP",
  },
];
