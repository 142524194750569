import tarot_back1 from "../img/tarot_back1.png";
import tarot_back2 from "../img/tarot_back2.png";
import tarot_back3 from "../img/tarot_back3.png";
import tarot_back4 from "../img/tarot_back4.png";
import tarot_back5 from "../img/tarot_back5.png";
import tarot_back6 from "../img/tarot_back6.png";
import tarot_back7 from "../img/tarot_back7.png";
import tarot_back8 from "../img/tarot_back8.png";
import tarot_back9 from "../img/tarot_back9.png";
import tarot_back10 from "../img/tarot_back10.png";
import tarot_back11 from "../img/tarot_back11.png";
import tarot_back12 from "../img/tarot_back12.png";
import tarot_back13 from "../img/tarot_back13.png";
import tarot_back14 from "../img/tarot_back14.png";
import tarot_back15 from "../img/tarot_back15.png";
import tarot_back16 from "../img/tarot_back16.png";
import tarot_back17 from "../img/tarot_back17.png";
import tarot_back18 from "../img/tarot_back18.png";
import tarot_back19 from "../img/tarot_back19.png";
import tarot_back20 from "../img/tarot_back20.png";

const cardData = [
  { id: 1, image: tarot_back1 },
  { id: 2, image: tarot_back2 },
  { id: 3, image: tarot_back3 },
  { id: 4, image: tarot_back4 },
  { id: 5, image: tarot_back5 },
  { id: 6, image: tarot_back6 },
  { id: 7, image: tarot_back7 },
  { id: 8, image: tarot_back8 },
  { id: 9, image: tarot_back9 },
  { id: 10, image: tarot_back10 },
  { id: 11, image: tarot_back11 },
  { id: 12, image: tarot_back12 },
  { id: 13, image: tarot_back13 },
  { id: 14, image: tarot_back14 },
  { id: 15, image: tarot_back15 },
  { id: 16, image: tarot_back16 },
  { id: 17, image: tarot_back17 },
  { id: 18, image: tarot_back18 },
  { id: 19, image: tarot_back19 },
  { id: 20, image: tarot_back20 },
];

export default cardData;
