import React, { useEffect } from "react";
import "./AptitudeMain.css";
import { useNavigate } from "react-router-dom";
import main from "./img/aptitude_Main.png";
import Take5 from "../../../../components/Take5/Take5";
import SEO from "../../SEOko";
import { aptitudeAttributeko } from "../../seoAttributesko";
import AdSenseComponent from "../../../../AdSenseComponent";

const AptitudeMain = () => {
  const navigate = useNavigate();

  const QPage = () => {
    navigate("/test/aptitude/question");
  };

  const id = "aptitude";

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="aptitude_wrapper">
      <div className="aptitude_contents">
        <SEO attribute={aptitudeAttributeko} id={id} />
        <Take5 />

        <div className="aptitude_main_container">
          <img src={main} alt="main" />
        </div>
        <div className="aptitude_btn_container">
          <button onClick={QPage} className="aptitude_start_btn">
            시작하기
          </button>
        </div>
      </div>
      <AdSenseComponent />
    </div>
  );
};

export default AptitudeMain;
