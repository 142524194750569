import catQ1 from "../img/catQ1.png";
import catQ2 from "../img/catQ2.png";
import catQ3 from "../img/catQ3.png";
import catQ4 from "../img/catQ4.png";
import catQ5 from "../img/catQ5.png";
import catQ6 from "../img/catQ6.png";
import catQ7 from "../img/catQ7.png";
import catQ8 from "../img/catQ8.png";
import catQ9 from "../img/catQ9.png";
import catQ10 from "../img/catQ10.png";
import catQ11 from "../img/catQ11.png";
import catQ12 from "../img/catQ12.png";
import catQ13 from "../img/catQ13.png";

export const QuestionData = [
  {
    question: "What kind of cat is it?",
    answers: [
      { option: "Indoor cat", score: 1 },
      { option: "Outdoor cat", score: 2 },
      { option: "Stray cat", score: 3 },
    ],
    image: catQ1,
  },
  {
    question: "Does your cat knead often?",
    answers: [
      { option: "Yes, often", score: 3 },
      { option: "Sometimes", score: 2 },
      { option: "No, rarely", score: 1 },
    ],
    image: catQ2,
  },
  {
    question: "Does your cat often purr next to you?",
    answers: [
      { option: "Yes, often", score: 3 },
      { option: "Sometimes", score: 2 },
      { option: "No, rarely", score: 1 },
    ],
    image: catQ3,
  },
  {
    question: "Does your cat rub its\nface against your face or body?",
    answers: [
      { option: "Yes, often", score: 3 },
      { option: "Sometimes", score: 2 },
      { option: "No, rarely", score: 1 },
    ],
    image: catQ4,
  },
  {
    question: "Does your cat often headbutt you?",
    answers: [
      { option: "Yes, often", score: 3 },
      { option: "Sometimes", score: 2 },
      { option: "No, rarely", score: 1 },
    ],
    image: catQ5,
  },
  {
    question:
      "Has your cat ever brought \nyou small gifts like insects or bugs?",
    answers: [
      { option: "Yes, often", score: 3 },
      { option: "Sometimes", score: 2 },
      { option: "No, rarely", score: 1 },
    ],
    image: catQ6,
  },
  {
    question: "Does your cat often lie \non its back showing its belly?",
    answers: [
      { option: "Yes, often", score: 3 },
      { option: "Sometimes", score: 2 },
      { option: "No, rarely", score: 1 },
    ],
    image: catQ7,
  },
  {
    question: "Does your cat slowly blink at you?",
    answers: [
      { option: "Yes, often", score: 3 },
      { option: "Sometimes", score: 2 },
      { option: "No, rarely", score: 1 },
    ],
    image: catQ8,
  },
  {
    question: "Does your cat like to sleep \nnext to you?",
    answers: [
      { option: "Yes, often", score: 3 },
      { option: "Sometimes", score: 2 },
      { option: "No, prefers to sleep alone", score: 1 },
    ],
    image: catQ9,
  },
  {
    question: "Does your cat follow you around?",
    answers: [
      { option: "Yes, often", score: 3 },
      { option: "Sometimes", score: 2 },
      { option: "No, rarely", score: 1 },
    ],
    image: catQ10,
  },
  {
    question: "Does your cat gently bite you?",
    answers: [
      { option: "Yes, often", score: 3 },
      { option: "Sometimes", score: 2 },
      { option: "No, rarely", score: 1 },
    ],
    image: catQ11,
  },
  {
    question: "Does your cat like to \nsit on your lap or body?",
    answers: [
      { option: "Yes, often", score: 3 },
      { option: "Sometimes", score: 2 },
      { option: "No, rarely", score: 1 },
    ],
    image: catQ12,
  },
  {
    question: "Does your cat lick \nyour hands or face?",
    answers: [
      { option: "Yes, often", score: 3 },
      { option: "Sometimes", score: 2 },
      { option: "No, rarely", score: 1 },
    ],
    image: catQ13,
  },
];
