import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./BigfiveRen.css";
import Take5 from "../../../../components/Take5/Take5";
import Footer from "../../../../components/Footer/Footer";
import EnShareButton from "./EnSharebutton";
import { SyncLoader } from "react-spinners";
import Card from "../../Carden";
import TestList from "../../TestListen.json";

import questions from "./data/questions.json";
import descriptions from "./data/result.json";
import overallProfiles from "./data/OverallProfiles.json";

import { Radar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const BigfiveRen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { answers } = location.state || { answers: {} };

  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState({
    Extraversion: 0,
    Neuroticism: 0,
    Openness: 0,
    Agreeableness: 0,
    Conscientiousness: 0,
  });
  const [testList, setTestList] = useState([]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, []);

  useEffect(() => {
    setTestList(TestList);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0); // 페이지 로드 시 스크롤을 최상단으로 이동

    const calculateResults = () => {
      const scores = {
        Extraversion: 0,
        Neuroticism: 0,
        Openness: 0,
        Agreeableness: 0,
        Conscientiousness: 0,
      };
      let answerCount = 0;

      for (let key in answers) {
        const question = questions.find((q) => q.id === parseInt(key));
        if (question) {
          const category = question.category;
          scores[category] += parseInt(answers[key]);
          answerCount++;
        }
      }

      if (answerCount !== questions.length) {
        alert("Please answer all the questions.");
        return;
      }

      for (let category in scores) {
        scores[category] = Math.round((scores[category] / 3) * 10) / 10; // 각 특성 점수를 평균으로 나누어 5점 만점으로 환산하고 소수점 1자리로 표현
      }

      setResults(scores);
    };

    calculateResults();
  }, [answers]);

  const getDescription = (category, score) => {
    if (descriptions[category] && descriptions[category].descriptions) {
      if (score <= 2) return descriptions[category].descriptions["1-2"];
      if (score === 3) return descriptions[category].descriptions["3"];
      return descriptions[category].descriptions["4-5"];
    } else {
      return "Description not available.";
    }
  };

  const getOverallProfile = (results) => {
    const profileParts = [];
    for (let category in results) {
      const score = results[category];
      let scoreRange = "3";
      if (score <= 2) scoreRange = "1-2";
      if (score >= 4) scoreRange = "4-5";
      profileParts.push(overallProfiles.overallProfiles[category][scoreRange]);
    }
    return profileParts.join(" ");
  };

  const excludedTestId = "bigfiveen";
  const filteredTestList = testList.filter(
    (test) => test.id !== excludedTestId
  );

  return (
    <>
      <div className="en_b5_R_Wrapper">
        {loading ? (
          <div className="en_b5_loading_container">
            <p className="en_b5_loading_text">Profiling...🕵🏻</p>
            <SyncLoader
              color={"#178aa1"}
              loading={loading}
              size={9}
              speedMultiplier={0.5}
              cssOverride={{
                marginTop: "3rem",
                backgroundColor: "transparent",
              }}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        ) : (
          <>
            <Take5 />
            <div className="en_b5_R_Contents">
              <div className="en_b5_R_title">
                <h3>Your results are...</h3>
              </div>
              <div className="en_b5_R_ResultImg_Container" id="coupangDiv">
                <div
                  className="en_b5_chart"
                  style={{ maxWidth: "300px", margin: "0 auto" }}
                >
                  <Radar
                    data={{
                      labels: [
                        "Extraversion",
                        "Agreeableness",
                        "Conscientiousness",
                        "Neuroticism",
                        "Openness",
                      ],
                      datasets: [
                        {
                          label: "",
                          data: [
                            parseFloat(results["Extraversion"]),
                            parseFloat(results["Agreeableness"]),
                            parseFloat(results["Conscientiousness"]),
                            parseFloat(results["Neuroticism"]),
                            parseFloat(results["Openness"]),
                          ],
                          backgroundColor: "rgba(34, 202, 236, .2)",
                          borderColor: "rgba(34, 202, 236, 1)",
                          borderWidth: 2,
                        },
                      ],
                    }}
                    options={{
                      maintainAspectRatio: false,
                      scales: {
                        r: {
                          ticks: {
                            display: false,
                          },
                          pointLabels: {
                            font: {
                              size: 13,
                            },
                          },
                        },
                      },
                      plugins: {
                        legend: {
                          display: false,
                        },
                      },
                    }}
                    height={300}
                    width={300}
                  />
                </div>
                <div className="en_b5_R_details">
                  {Object.keys(results).map((key) => (
                    <div key={key}>
                      <h3>
                        🔎 {key}: {results[key]}/5
                      </h3>
                      <p>{getDescription(key, results[key])}</p>
                    </div>
                  ))}
                </div>
                <div className="en_b5_R_profile">
                  <h3>🕵🏻 Overall Personality Profile</h3>
                  <p>{getOverallProfile(results)}</p>
                </div>
              </div>
              <div className="en_b5_R_Restart_Btn_Container">
                <button
                  className="en_b5_R_Restart_Btn"
                  style={{
                    minWidth: "210px",
                    maxWidth: "210px",
                    minHeight: "50px",
                    boxShadow: "none",
                  }}
                  onClick={() => navigate("/test/en/bigfiveen")}
                >
                  Retry
                </button>
              </div>
              <EnShareButton />
              <div className="TestList_container">
                {filteredTestList.map((test) => (
                  <div key={test.id} className="TestList_container2">
                    <Link to={`/test/en/${test.id}`}>
                      <Card test={test} />
                    </Link>
                  </div>
                ))}
              </div>
              <Footer />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default BigfiveRen;
