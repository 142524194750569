import ESTJ from "../img/ESTJ.png";
import ESFP from "../img/ESFP.png";
import ENTJ from "../img/ENTJ.png";
import INTP from "../img/INTP.png";
import INTJ from "../img/INTJ.png";
import ISTJ from "../img/ISTJ.png";
import ESTP from "../img/ESTP.png";
import INFP from "../img/INFP.png";
import ISFP from "../img/ISFP.png";
import ISFJ from "../img/ISFJ.png";
import ENFP from "../img/ENFP.png";
import INFJ from "../img/INFJ.png";
import ESFJ from "../img/ESFJ.png";
import ENTP from "../img/ENTP.png";
import ISTP from "../img/ISTP.png";
import ENFJ from "../img/ENFJ.png";

export const resultData = [
  {
    id: 1,
    best: "ESTJ",
    image: `${ESTJ}`,
    title: "冬の朝に",
    desc: "責任感が強く、優れた仕事の処理能力を持っているあなたは、実践的な印象を与えます。まるで冬の夜明けのようです。強いリーダーシップと優れた能力を放っています。計画を立て、仕事を体系的に処理することを好み、入念で細部に注意を払っているように見えます。事実情報を感情よりも優先する傾向があります。現実的で率直な一方で、時折、断固とした印象を与えることがあります。しかし、あなたはどこにいても安定した雰囲気を維持する役割を果たす存在として、なくてはならない存在です。",
  },
  {
    id: 2,
    best: "ESFP",
    image: `${ESFP}`,
    title: "春の正午",
    desc: "あなたは活気に満ち、いつも明るく暖かな光を放っているようです。まるで春の昼のようです。他者との交流を楽しんでおり、優れた社交性を持っています。自発的で現在重視のアプローチは、あらゆる経験を抱きしめ、楽しむことが魅力的です。単調なタスクは退屈だと感じる一方で、新しさを求め、新しいことを受け入れる意欲が感じられます。感情や状況を率直に表現することもあなたの魅力的な特徴の一つです。時折、強い偏見や先入観を持っているように見えるかもしれませんが、新しい人、環境、経験に積極的にアプローチすることを恐れていないようです。公の注目を好むあなたにとって、多くの人があなたを評価するのも無理はありません。",
  },
  {
    id: 3,
    best: "ENTJ",
    image: `${ENTJ}`,
    title: "深紅のシルク",
    desc: "あなたは生まれつきのリーダーシップを持ち、常に自信に満ち、落ち着いて見えます。紅のシルクにふさわしいカリスマ性と高い自尊心は、あなたを観察する人々に確かな確信を抱かせます。あなたは実用的で目標志向的な個人です。自然なリーダーシップが時折断言的に見えるかもしれませんが、他者と協力して目標を達成するのが得意です。効率と達成があなたにとって極めて重要であり、目標に向けて勤勉に働く人物として描かれています。明確なコミュニケーションスタイルは、他の人々があなたを過度に厳格で直接的だと感じさせるかもしれませんが、その魅力を感じる人もいます。人々は時折、状況を理解するためのあなたの明確で具体的なアプローチから知恵を授かることがあります。",
  },
  {
    id: 4,
    best: "INTP",
    image: `${INTP}`,
    title: "謎めいたオーロラ",
    desc: "一般的には穏やかで静かな雰囲気を漂わせ、注意深く静かな態度を取ることがあります。事実と原理を探求する強い欲求に駆られ、他の人はあなたを非常に集中力があり優れた分析力を持つと見なしています。あなたは深い問いに対する深い答えを考え、独自の想像力豊かな理論を構築します。豊かな想像力と創造性を発揮している一方で、世俗的な事柄にはほとんど興味を示さず、友好的でありながらも控えめな距離を保つことが好ましいと感じています。あなたは周囲の人々に無関心に見えるかもしれませんが、興味を引く分野に熱心に情熱を傾けています。あなたの内面は複雑で美しく絡み合っており、その対比が多くの人に興味深く魅力的に映ります。",
  },
  {
    id: 5,
    best: "INTJ",
    image: `${INTJ}`,
    title: "月明かりの輝く海の下",
    desc: "あなたは冷静で落ち着いており、他者とは適度な距離を保ちながら深い会話を楽しんでいます。あなたを観察することは、真っ暗な夜に海の下で月明かりが注ぐのを見ているようです。あなたは独立心と論理的な性格からやや距離を置いているように見えますが、独自の問題解決スキルで他者をよくサポートしています。効率と迅速さを重視し、自分のビジョンのために熟考し計画を立て続けています。あなたの情熱と忍耐力は他者に感動を与えます。あなたは一貫して深い自己探求に没頭し、人生の意味と方向を追求し、周囲の人々にインスピレーションを提供しています。",
  },
  {
    id: 6,
    best: "ISTJ",
    image: `${ISTJ}`,
    title: "茂み茂る森",
    desc: "強い忍耐力と注意深い考慮をもって、あなたは豊かな森のイメージを喚起します。あなたは安定した信頼できる雰囲気を放っています。決定を下す前に、あなたは情報を徹底的に収集し評価します。高い責任感、集中力、そして細心の性格を持ちながら、自信を持ってタスクに取り組んでいます。あなたの積極的で責任感のある態度は、他者に安定感と信頼感を植え付けます。控えめに見えるかもしれませんが、あなたは些細で見落とされがちな思い出にもしっかりとこだわる一面もあります。",
  },
  {
    id: 7,
    best: "ESTP",
    image: `${ESTP}`,
    title: "昇る太陽",
    desc: "あなたは昇る太陽に似て、熱意と無限のエネルギーを放っています。挑戦的でスリリングな経験を楽しむあなたは、どんな状況でも恐れずに前進します。新しい環境や状況に迅速に適応し、柔軟に状況に対処する卓越した能力を示しています。周りの人々は、あなたの適応力や巧妙な問題解決能力をよく見ています。明るい性格は周りの雰囲気を高め、ポジティブなエネルギーを発散しています。自信を持って明確にコミュニケーションをとりながら、他の人の視点を巧みに理解し尊重するスキルも兼ね備えています。異なる視点を考慮し尊重する能力は、他者の立場を理解し尊重するスキルを示しています。",
  },
  {
    id: 8,
    best: "INFP",
    image: `${INFP}`,
    title: "調和のとれたパレット",
    desc: "豊かな想像力と創造力を持って、あなたはさまざまな色が調和して組み合わさるパレットのようです。広範な感情を感じ表現する能力は非凡です。さらに、高い共感性により多くの人々にとって素晴らしい友達となっています。他人のニーズと価値を尊重する人を評価しないわけがありません。あなたの温かさと柔軟性により、新しいアイデア、方法、視点を受け入れ適用することができます。一貫してより良い人間になることを追求し、誠実さと自己信頼を重視しています。あなたは長い間そばに置きたいと思われる種類の人物です。",
  },
  {
    id: 9,
    best: "ISFP",
    image: `${ISFP}`,
    title: "幾何学模様",
    desc: "本能的な感性を持って、あなたは予測不能な幾何学模様のようです。あなたの創造的で芸術的な表現は優れています。本能的な感性と想像力を使って独自のものを作り出す能力に優れています。細部に対する鋭い興味は他の人が見落とすかもしれないことに気づかせてくれます。これを見て、人々はあなたの直感力と洞察力を賞賛しています。他人の意見や思考を尊重する寛容さがあり、感情の敏感さにより他人の感情を感じ共感することができます。あなたの予測不能さはあなたの人格に神秘的な要素を加えています。",
  },
  {
    id: 10,
    best: "ISFJ",
    image: `${ISFJ}`,
    title: "上手に織りなされた生地",
    desc: "丹念で注意深い性格は、何も隙間のない緻密に織りなされた生地を思わせます。仕事に対する強い責任感と約束された仕事を完遂する姿勢は、勤勉さを示し、「信頼できる人物」としての評判を築いています。あなたは周りから高い評価と信頼を受ける可能性が高いです。細かいことにも気配りがあり、他の人が感謝する繊細なタッチがあります。他人の感情の変化を正確に察知する傾向があり、他の人のニーズに思いやりを持って考え、手助けをすることがよくあります。あまり外向的な性格でなくても、どんな環境にもスムーズに馴染み、安定感と安心感を生み出します。",
  },
  {
    id: 11,
    best: "ENFP",
    image: `${ENFP}`,
    title: "可能性の空間",
    desc: "あなたは積極的な性格で、可能性のある空間を航海しているようです。やりたいことがたくさんあり、常に新しい試みをしています。タスクに対する情熱的で自発的なアプローチは、周りの人々に刺激を与えます。エネルギッシュで好奇心旺盛、熱意をもってタスクを開始し、あなたの行動は他者を鼓舞します。活気に満ちた好奇心旺盛な性格が、決意と情熱をもって目標達成を目指す原動力となっています。社交的で新しい人と出会うことにためらいがなく、自然に馴染み、社交的な交流を楽しんでいます。人間関係はあなたにとって重要です。楽観的な見方で、どんな状況にも積極的に取り組み、周りにポジティブなエネルギーを発散しています。",
  },
  {
    id: 12,
    best: "INFJ",
    image: `${INFJ}`,
    title: "太陽の下で咲いた花",
    desc: "穏やかで温かい態度は、太陽の光の下で静かに咲く花を思わせます。配慮深い性格と共感力が表れ、あなたの言葉や行動には繊細さがあります。人間関係を大切にし、他者との深い会話を楽しむことを重視しています。困難な状況でも忍耐強さ、持続力、そして問題解決への決意が表れます。周りの人々はあなたの不屈の努力を黙って讃えます。倫理観と原則へのコミットメントを持ち、真直で静かで不動の存在とされています。",
  },
  {
    id: 13,
    best: "ESFJ",
    image: `${ESFJ}`,
    title: "陽だまりの窓辺に",
    desc: "太陽の光が差し込む窓辺のような調和と穏やかな雰囲気を放っています。人間関係に優れ、しばしば調停者やカウンセラーのような役割を果たし、どこへ行っても冷静に紛争を解決する手腕を持っています。人々との交流を楽しむ中で、高い感受性と感情の知覚力があり、他人の感情を理解し同感することが容易です。あなたは親切で思いやり深いとされ、効果的にタスクの優先順位をつけ、様々な視点から問題を解決する能力を認識されています。",
  },
  {
    id: 14,
    best: "ENTP",
    image: `${ENTP}`,
    title: "まばゆい花火",
    desc: "好奇心旺盛で迅速な思考力を持ち、暗い夜に打ち上げられるような輝かしい花火のようです。自発的なアイデア、明確な視点、卓越した直感力により、複雑な問題を楽に単純化することができます。ディベートや議論を通じて自分自身や他者の世界を広げることを楽しんでいます。豊かな言語スキルを武器に、一つのトピックについて数時間にわたる対話が可能です。他の人はあなたの雄弁さを認め、賞賛せざるを得ません。外向的で社交的な交流があなたには適していますが、個性的で独立心旺盛な性格が人々を引きつけています。",
  },
  {
    id: 15,
    best: "ISTP",
    image: `${ISTP}`,
    title: "夜空の星",
    desc: "自分自身の時間と空間を必要とし、夜空の星々のようです。不必要な物語や見せかけ、偽善から解放され、静かに輝いています。問題の本質を掴み、実践的な解決策を提供する能力があなたを際立たせています。実践的な問題解決力と適応性を備えているため、周りの人々はあなたを非常に才能ある存在と見ています。一人で過ごすことを好む姿勢が他者にとっては神秘的に映るかもしれません。",
  },
  {
    id: 16,
    best: "ENFJ",
    image: `${ENFJ}`,
    title: "秋の葦原",
    desc: "心優しく深く思いやり深いあなたは、秋の草原のような暖かな雰囲気を醸し出しています。他者の話に耳を傾け、優しく柔らかな態度でコミュニケーションをとることに長けています。思考や感情を共有することを楽しみ、人間関係を重視し維持する努力を惜しまない姿勢があります。どこにいても活気に満ちたポジティブなエネルギーが広がります。コミュニケーションに長けたあなたは、感情を効果的に表現し、人間関係を大切にしています。寛大な性格のために時折当たり前にされたり、利用されたりするかもしれませんが、大丈夫です。あなたの温かさを理解し感謝する人がたくさんいます。",
  },
];
