export const QuestionData = [
  {
    id: 1,
    numbering: 1,
    title: "두근두근 새학기야! \n 개학을 앞두고 \n기분이 어땠어?",
    answera:
      "어떤 애들이랑 같은 반이 될까? \n 벌써부터 신나! \n (설레서 늦게 잠)",
    answerb:
      "아는 얼굴이 있어야 할 텐데.. \n (설레는 마음 반, 걱정되는 마음 반)",
    type: "EI",
  },
  {
    id: 2,
    numbering: 2,
    title:
      "설레는 마음으로 \n 교실 문을 열었던 거 기억나니...? \n 내가 제일 먼저 한 행동은?",
    answera: `우리는 모두 칭긔칭긔! \n 들어가면서부터 인사한다.
        "안녕^0^~~!"`,
    answerb: `아는 얼굴 있는지부터 스캔한다.
      '아싸, 아는 친구 있다! >_<'`,
    type: "EI",
  },
  {
    id: 3,
    numbering: 3,
    title:
      "모르는 친구랑 짝꿍이 됐어. \n 아직 좀 어색하네...\n 이때 내 행동은?",
    answera: `"안녕~ 너 이름이 뭐야?😊 \n 작년에는 몇 반이었어?"
        살갑게 먼저 말 거는 편`,
    answerb: `'시간이 지나면 \n 자연스럽게 친해지겠지?🙂'
      반갑지만 일단 가만히 있는 편`,
    type: "EI",
  },
  {
    id: 4,
    numbering: 4,
    title: "처음 수학여행 간 날 기억나? \n 뭐가 떠올라?",
    answera: "같이 놀았던 친구, 수학여행 장소, \n 그때 있었던 일들😌",
    answerb: "그날의 감정... 분위기... 공기... 온도...✨",
    type: "SN",
  },
  {
    id: 5,
    numbering: 5,
    title: "만들기 시간! \n 찰흙으로 동물을 만들었어. \n 내가 만든 동물은?",
    answera: "키우고 싶었던 반려동물🐶 \n (강아지, 고양이, 병아리 등...)",
    answerb: "내 상상 속에 존재하는 동물🦄",
    type: "SN",
  },
  {
    id: 6,
    numbering: 6,
    title:
      "선생님이 재밌는 영화를 틀어주셨어. \n 근데 짝꿍이 잠을 잤네? \n 무슨 영화였는지 묻는데... \n내 대답은?",
    answera: `"겨울왕국에 엘사랑 안나가 있었는데~"\n 주인공과 주요 사건을 \n 생각나는대로 말해준다`,
    answerb: `"겨울왕국! 진정한 사랑에 대한... 그런 영화였어" \n 영화의 주제의식을 나름대로 말해준다`,
    type: "SN",
  },
  {
    id: 7,
    numbering: 7,
    title:
      "중간놀이 시간! \n 친구랑 그네를 타다가 \n친구가 다쳤어. \n 이때 내 반응은?",
    answera: "양호실 가자! (바로 데리고 감)",
    answerb: "헉 괜찮아? (선걱정, 후양호실)",
    type: "TF",
  },
  {
    id: 8,
    numbering: 8,
    title: "친구랑 싸웠어. \n 나보고 못돼먹었다는 거야!😡 \n 내 대답은?",
    answera:
      "시비 먼저 건 니가 할 말은 아닌디?🤷🏻‍♀️ \n (처음부터 짚어가며 받아친다)",
    answerb:
      "너도 나쁜 말했잖아. 나도 기분 나빴거든?🤦🏻‍♀️ \n (역지사지를 깨닫게 해준다)",
    type: "TF",
  },
  {
    id: 9,
    numbering: 9,
    title: "친구가 키우던 다마고치가 죽었어. \n 이때 내 행동은?",
    answera: "잘 봐, 이때 똥을 치워주고 밥을 줘야 돼. \n (해결책을 알려준다)",
    answerb: "괜찮아, 다시 키우면 되지! \n (자연스럽게 위로한다)",
    type: "TF",
  },
  {
    id: 10,
    numbering: 10,
    title:
      "학교 끝! \n 엄마가 집에 올 때 \n두부 한 모만 사오랬어. \n 언제 갈까?",
    answera: "엄마 심부름 먼저!",
    answerb: "나중에 살래~",
    type: "JP",
  },
  {
    id: 11,
    numbering: 11,
    title:
      "다른 반 친구가 기분이 안 좋아보여. \n 알고보니 숙제를 안 해서 벌을 섰대. \n 나는 무슨 생각할까?",
    answera: "왜 안 했지...🤔",
    answerb: "아, 그래서 기분 안 좋아보였구나",
    type: "JP",
  },
  {
    id: 12,
    numbering: 12,
    title:
      "피아노 학원 갈 시간인데 \n 친구가 문방구 구경을 하자고 해. \n 이때 나는?",
    answera: "내일 가자! 나 지금은 학원 가야 돼~!",
    answerb: "(빨리 보고 나오면 되겠지) 그래, 가자!",
    type: "JP",
  },
];
