import React, { useEffect } from "react";
import "./MoodHome.css";
import { useNavigate } from "react-router-dom";
import homeimg from "./img/homeimg.png";
import Take5 from "../../../../components/Take5/Take5";
import SEO from "../../SEOko";
import { yourmoodAttributeko } from "../../seoAttributesko";

const MoodHome = () => {
  const navigate = useNavigate();

  const handleClickStart = () => {
    navigate("/test/yourmood/question");
  };

  const id = "yourmood";

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="mood_wrapper">
      <div className="mood_contents">
        <SEO attribute={yourmoodAttributeko} id={id} />
        <Take5 />
        <div className="mood_container">
          <div className="mood_home_title">당신의 분위기 TEST</div>
          <div className="mood_sub_title">
            남들이 보는 <br />
            나의 분위기는?
          </div>
          <div className="mood_homeimg_container">
            <img src={homeimg} alt="homeimg" />

            <div className="mood_button_contanier">
              <button onClick={handleClickStart} className="mood_start_btn">
                시작하기
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoodHome;
