export const QuestionData = [
  {
    id: 1,
    title: "연인과의 대화에서 당신은?",
    answera: "주로 말하는 편이에요",
    answerb: "주로 듣는 편이에요",
    type: "EI",
  },
  {
    id: 2,
    title: "상대를 처음 볼 때, 당신은?",
    answera: "첫인상을 보면 \n어느정도 느낌이 와요",
    answerb: "어떤 사람인지 파악하면서 \n결정해요",
    type: "SN",
  },
  {
    id: 3,
    title: "예정에 없던 일이 생긴다면?",
    answera: "스트레스 받아요",
    answerb: "재밌어요",
    type: "JP",
  },
  {
    id: 4,
    title: "연인이랑 다퉜을 때 당신은?",
    answera: "서로 의견을 나누고 \n타협점을 찾는 편",
    answerb: "상대의 의견과 감정을 \n공감하려 노력하는 편",
    type: "TF",
  },
  {
    id: 5,
    title: "나에게 '멍 때린다'의 의미란?",
    answera: "진짜 아무 생각도 안해요",
    answerb: "오만가지 생각하면서 몸만 쉬어요",
    type: "SN",
  },
  {
    id: 6,
    title: "지나가면서 차 사고를 목격했다. \n이때 당신은?",
    answera: "누구 과실일까...?",
    answerb: "사람은 안 다쳤나...?",
    type: "TF",
  },
  {
    id: 7,
    title: "연인이 맛집이라고 데려갔는데\n웨이팅이 한 시간이다.\n이때 나는?",
    answera: "연인과 수다 떨면서 \n재밌게 기다려요",
    answerb: "실시간으로 지쳐가요",
    type: "EI",
  },
  {
    id: 8,
    title: "연인과의 여행을 계획할 때는?",
    answera: "구체적인 일정과 계획을 세워요",
    answerb: "대략적인 계획만 세우고 즐겨요",
    type: "JP",
  },
  {
    id: 9,
    title: "연인이 맛있는 거 사 먹으라고 \n용돈을 줬다. 이때 당신은?",
    answera: "? 꽁돈 생겼나? 아무튼 땡큐!",
    answerb: "헙... 🥹 (감동)",
    type: "TF",
  },
  {
    id: 10,
    title: "주로 당신의 데이트 코스는?",
    answera: "야외 데이트, 핫플레이스 놀러가기",
    answerb: "집 데이트, 조용한 곳 놀러가기",
    type: "EI",
  },
  {
    id: 11,
    title: "연인과 함께 요리할 때, 당신은?",
    answera: "레시피를 철저히 따르는 편이에요",
    answerb: "레시피를 참고만 하고 \n자유롭게 만들어요",
    type: "JP",
  },
  {
    id: 12,
    title: "연인과의 미래를 상상할 때 당신은?",
    answera: "현실적으로 계획을 생각해요",
    answerb: "여러가지 가정을 하면서 \n미래를 상상해요",
    type: "SN",
  },
];
