import "./MoodHomeen.css";
import { useNavigate } from "react-router-dom";
import homeimg from "./img/homeimg.png";
import Take5 from "../../../../components/Take5/Take5";
import SEO from "../../SEOen";
import { yourmoodAttributeen } from "../../seoAttributesen";
import AdSenseComponent from "../../../../AdSenseComponent";

const MoodHomeen = () => {
  const navigate = useNavigate();

  const handleClickStart = () => {
    navigate("/test/en/yourmooden/question");
  };

  const id = "yourmooden";

  return (
    <div className="mooden_wrapper">
      <div className="mooden_contents">
        <SEO attribute={yourmoodAttributeen} id={id} />
        <Take5 />
        <div className="mooden_container">
          <div className="mooden_home_title">Your Mood TEST</div>
          <div className="mooden_sub_title">
            What is my atmosphere
            <br /> that others see?
          </div>
          <div className="mooden_homeimg_container">
            <img src={homeimg} alt="homeimg" />

            <div className="mooden_button_contanier">
              <button onClick={handleClickStart} className="mooden_start_btn">
                Get Start!
              </button>
            </div>
          </div>
          <AdSenseComponent />
        </div>
      </div>
    </div>
  );
};

export default MoodHomeen;
