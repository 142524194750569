export const QuestionData = [
  {
    id: 1,
    title: "Q1.",
    answera: "전 애인과 \n술 한 잔 하는 애인",
    answerb: "이성 친구와 \n1박 2일 놀러가는 애인",
  },
  {
    id: 2,
    title: "Q2.",
    answera: "애인한테 \n플러팅하는 베프",
    answerb: "베프한테\n 플러팅하는 애인",
  },
  {
    id: 3,
    title: "Q3.",
    answera: "간섭 하나도 안 하는 \n방목형",
    answerb: "수시로 연락하는 \n불안형",
  },
  {
    id: 4,
    title: "Q4.",
    answera: "낮져밤이",
    answerb: "낮이밤져",
  },
  {
    id: 5,
    title: "Q5.",
    answera: "매일 사랑을 \n표현하는 애인",
    answerb: "말 없다가 깜짝 \n이벤트 하는 애인",
  },
  {
    id: 6,
    title: "Q6.",
    answera: "나를 좋아하는 사람",
    answerb: "내가 좋아하는 사람",
  },
  {
    id: 7,
    title: "Q7.",
    answera: "외모 빼고 모든 게 \n안 맞는 애인",
    answerb: "외모 빼고 모든 게 \n잘 맞는 애인",
  },
  {
    id: 8,
    title: "Q8.",
    answera: "잠수 이별",
    answerb: "환승 이별",
  },
  {
    id: 9,
    title: "Q9.",
    answera: "고양이상",
    answerb: "강아지상",
  },
  {
    id: 10,
    title: "Q10.",
    answera: "연락없이 매번 \n1시간씩\n 늦는 애인",
    answerb: "매번 1시간씩 \n일찍 나와서 \n재촉하는 애인",
  },
];
