export const QuestionData = [
  {
    id: 1,
    title: "내가 선호하는 데이트는?",
    answera: "신나는 야외 데이트",
    answerb: "편안한 실내 데이트",
    type: "EI",
  },
  {
    id: 2,
    title: "데이트가 없는 주말에 나는?",
    answera: "친구를 만나거나\n혼자 바람쐬러 나간다",
    answerb: "집에서 뒹굴뒹굴 충전한다",
    type: "EI",
  },

  {
    id: 3,
    title: "주말에는 하루종일 \n붙어있고 싶어하는 연인. \n내 속마음은?",
    answera: "나도 좋아!! ",
    answerb: "주말에도 가끔은 혼자 쉬고 싶어",
    type: "EI",
  },
  {
    id: 4,
    title: `연인이 '만약에 놀이*'를 시작했어. \n이때 내 속마음은?\n(*만약에 이러저러하면 어쩔거야~?)`,
    answera: "(피곤)",
    answerb: "(존잼)",
    type: "SN",
  },
  {
    id: 5,
    title: "내가 더 자주 생각하는 것은?",
    answera: "지금 눈 앞에 놓인 현실에 대해",
    answerb: "가까운 미래, 혹은 먼 미래에 대해",
    type: "SN",
  },
  {
    id: 6,
    title: `카페에서 음료를 가지고 오는 \n연인을 보면서...`,
    answera: "(나눠서 들걸 그랬나? \n불안해보인다)",
    answerb: "(쏟으면 잽싸게 달려가서 \n정리해야지)",
    type: "SN",
  },
  {
    id: 7,
    title: `연인이 나를 생각하면서 시를 썼대. \n이때 내 속마음은?`,
    answera: `리액션 어떻게 해줘야 되지🤔\n(머리 굴리기)`,
    answerb: `날 생각하면서 시를...?!🥺\n(감동)`,
    type: "TF",
  },
  {
    id: 8,
    title: "연인이 다쳤다고 연락왔어.\n내 반응은?",
    answera: `어디를, 얼마나, 어떻게 다쳤는데?`,
    answerb: `으아 어떡해 아프겠다ㅠㅠ`,
    type: "TF",
  },
  {
    id: 9,
    title: `연인의 고민을 들어줄 때 나는`,
    answera: "해결에 초점",
    answerb: "공감에 초점",
    type: "TF",
  },
  {
    id: 10,
    title: "당일치기로 여행을 가자는 연인.\n 이때 나는?",
    answera: "어? 갑자기? \n어디로 갈진 생각해봤어?",
    answerb: "재밌겠다!! 일단 만나!!",
    type: "JP",
  },
  {
    id: 11,
    title: `알고보니 아무런 계획도 없었던 연인.\n내 속마음은?`,
    answera: "아니 이렇게 무턱대고 간다고...?",
    answerb: "그래~ 원래 무계획이 계획이지!",
    type: "JP",
  },
  {
    id: 12,
    title: `이번 주에 볼거냐고 물어본 나.\n연인이 "난 아무때나 좋아"라고 해`,
    answera: "그럼 수요일 어때?\n(구체적으로 약속 잡음)",
    answerb: "좋아~! 볼 수 있을 때 보자ㅋㅋㅋ\n(즉흥적으로 잡음)",
    type: "JP",
  },
];
