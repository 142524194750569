import ESTJ from '../img/ESTJ.png';
import ESFP from '../img/ESFP.png';
import ENTJ from '../img/ENTJ.png';
import INTP from '../img/INTP.png';
import INTJ from '../img/INTJ.png';
import ISTJ from '../img/ISTJ.png';
import ESTP from '../img/ESTP.png';
import INFP from '../img/INFP.png';
import ISFP from '../img/ISFP.png';
import ISFJ from '../img/ISFJ.png';
import ENFP from '../img/ENFP.png';
import INFJ from '../img/INFJ.png';
import ESFJ from '../img/ESFJ.png';
import ENTP from '../img/ENTP.png';
import ISTP from '../img/ISTP.png';
import ENFJ from '../img/ENFJ.png';


export const ResultData = [
  {
    id: 1,
    best: 'ESTJ',
    image: `${ESTJ}`,
  },
  {
    id: 2,
    best: 'ESFP',
    image: `${ESFP}`,
  },
  {
    id: 3,
    best: 'ENTJ',
    image: `${ENTJ}`,
  },
  {
    id: 4,
    best: 'INTP',
    image: `${INTP}`,
  },
  {
    id: 5,
    best: 'INTJ',
    image: `${INTJ}`, 
  },
  {
    id: 6,
    best: 'ISTJ',
    image: `${ISTJ}`,
  },
  {
    id: 7,
    best: 'ESTP',
    image: `${ESTP}`,
  },
  {
    id: 8,
    best: 'INFP',
    image: `${INFP}`,
  },
  {
    id: 9,
    best: 'ISFP',
    image: `${ISFP}`,
  },
  {
    id: 10,
    best: 'ISFJ',
    image: `${ISFJ}`,
  },
  {
    id: 11,
    best: 'ENFP',
    image: `${ENFP}`,
  },
  {
    id: 12,
    best: 'INFJ',
    image: `${INFJ}`, 
  },
  {
    id: 13,
    best: 'ESFJ',
    image: `${ESFJ}`,
  },
  {
    id: 14,
    best: 'ENTP',
    image: `${ENTP}`,
  },
  {
    id: 15,
    best: 'ISTP',
    image: `${ISTP}`,
  },
  {
    id: 16,
    best: 'ENFJ',
    image: `${ENFJ}`
  },
]