import React, { useEffect, useState } from "react";
import "./CatlovesMeMain.css";
import { useNavigate } from "react-router-dom";
import main from "./img/catloveme_Main.png";
import Take5 from "../../../../components/Take5/Take5";
import SEO from "../../SEOko";
import { catlovesmeAttributeko } from "../../seoAttributesko";
import AdSenseComponent from "../../../../AdSenseComponent";

const CatlovesMeMain = () => {
  const [catName, setCatName] = useState("");
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    setCatName(e.target.value);
  };

  const QPage = () => {
    navigate("/test/catlovesme/question", { state: { catName } });
  };

  const id = "catlovesme";

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="clm_wrapper">
      <div className="clm_contents">
        <SEO attribute={catlovesmeAttributeko} id={id} />
        <Take5 />
        <div className="clm_main_container">
          <img src={main} alt="main" />
          <input
            type="text"
            placeholder="우리 고양이 이름은?"
            value={catName}
            onChange={handleInputChange}
            className="clm_cat_name_input"
          />
          <button onClick={QPage} className="clm_start_btn">
            시작하기
          </button>
        </div>
      </div>
      <AdSenseComponent />
    </div>
  );
};

export default CatlovesMeMain;
