import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import ReactGA from "react-ga";
import { LanguageProvider } from "./LanguageContext";

const element = (
  <React.StrictMode>
    <LanguageProvider>
      <App />
    </LanguageProvider>
  </React.StrictMode>
);

ReactGA.initialize("G-QCXRTP5N13");

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(element, rootElement);
} else {
  ReactDOM.render(element, rootElement);
}
