export const QuestionData = [
  {
    id: 1,
    title: "주말에 연인과 데이트가 없다면?",
    answera: "오히려 좋아ㅋ\n친구 만나러 밖에 나감",
    answerb: "쉬어야징~\n집에서 혼자 시간을 보냄",
    type: "EI",
  },
  {
    id: 2,
    title: "연인과 함께 파티에 가게 된다면?",
    answera: "재밌겠다!! 신나게 놀아야지!!!",
    answerb: "기 빨리넴,,,\n 분위기 맞추면서 그냥 조용히 있는다",
    type: "EI",
  },
  {
    id: 3,
    title: "연인이 바빠서 연락이 없네🤔 \n이때 나는?",
    answera: "나중에 보겠지ㅎ~ \n혼자 이것저것 보낸다",
    answerb: "나중에 연락오겠지 머...\n 내버려둔다",
    type: "EI",
  },
  {
    id: 4,
    title: "연인이 새로운 취미를 가졌어!\n 이때 나는?",
    answera: "오 재밌겠는데? 같이 해보자!!",
    answerb: "그게 왜 재밌엉? (상상해봄)",
    type: "SN",
  },
  {
    id: 5,
    title: "연인과의 미래를 생각할 때는...",
    answera: "지금 행복한게 중요한 거지!",
    answerb: "먼 미래까지 생각해봐야지!",
    type: "SN",
  },
  {
    id: 6,
    title: "상대방과의 첫 데이트에서 나는?",
    answera: "첫인상이 중요해.\n외모와 분위기를 보고 매력을 느낀다",
    answerb: "찬찬히 알아봐야지.\n대화와 성격을 통해 매력을 느낀다",
    type: "SN",
  },
  {
    id: 7,
    title: "연인이 카페에서\n눈물을 흘린다면?",
    answera: "왜, 왜, 무슨 일인데!\n(원인 파악 후 해결책 생각)",
    answerb: "헉 무슨 일인데 그래ㅠㅠ\n (선공감 후 해결책 생각)",
    type: "TF",
  },
  {
    id: 8,
    title: "나는 연인과 갈등이 생겼을 때...",
    answera: "각자 의견을 말한 뒤,\n 논리적이고 합리적으로 해결함",
    answerb: "감정을 보듬으면서\n서로를 이해하려고 함",
    type: "TF",
  },
  {
    id: 9,
    title: "연인의 기분이 안 좋을 때, 나는...",
    answera: "무슨 일 있어? 내가 해줄 건 없고?",
    answerb: "왜 기분이 안 좋아~ 말해봐, 들어줄게",
    type: "TF",
  },
  {
    id: 10,
    title: "나는 데이트 계획을 세울 때...",
    answera: "계획 딱딱 지켜",
    answerb: "일단 만나서 정해",
    type: "JP",
  },
  {
    id: 11,
    title: "평화롭게 데이트 중, \n갑자기 계획이 틀어진다면?",
    answera: "씁 이건 예상 밖인데...\n(계획 다시 생각함)",
    answerb: "ㅋㅋㅋ이럴 수도 있지 모~ \n(유연하게 대처함)",
    type: "JP",
  },
  {
    id: 12,
    title: "연인과의 여행을 준비할 때, 나는...",
    answera: "우리 첫날에는 이거이거 하고, \n둘째날에는~ $%^*",
    answerb: "음~ 뭐 가서 정하면 되지 않을까?",
    type: "JP",
  },
];
