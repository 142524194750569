export const QuestionData = [
  {
    id: 1,
    title: "友達の中で私のポジションは？",
    answera: "おしゃべり好きな方です。",
    answerb: "主に聞き手です。",
    type: "EI",
  },
  {
    id: 2,
    title:
      "友達と遊んでいる最中、友達が\n「友達も呼んでもいい?」\nと言ったら、",
    answera: "もちろん、君の友達は僕の友達！\n呼んで!",
    answerb: "(笑)、今度遊ぼう...!",
    type: "EI",
  },
  {
    id: 3,
    title:
      "今、家に帰るところなんだけど、\nよく知らない友達と同じ方向だ。\nどうする?",
    answera: "一緒に行ってもいいよ。",
    answerb: "正直、一人で行きたい。",
    type: "EI",
  },
  {
    id: 4,
    title: "私はどんな人？",
    answera: "説明は得意だけど、\n頭の中でアイデアを出すのが難しい。",
    answerb: "頭ではよく知っているけど、\n言葉で説明するのは難しい。",
    type: "SN",
  },
  {
    id: 5,
    title: "人を見るとき、私は？",
    answera: "第一印象と感じに基づいて判断する。",
    answerb: "人を評価するための独自の基準がある。",
    type: "SN",
  },
  {
    id: 6,
    title: "「なぜ私たちは生まれたのだろう？」と友達が言ったら、",
    answera: "? まあ、生まれたからには... \n何かあったの?",
    answerb: "人間の存在の意味についてディスカッションする。",
    type: "SN",
  },
  {
    id: 7,
    title: "「今日、ちょっとふくれた？」と\n友達が尋ねたら、",
    answera: "うん、ちょっとふくれてるね。\n昨日何食べたの?",
    answerb: "ふくれてる？変わらないよ！！",
    type: "TF",
  },
  {
    id: 8,
    title: "友達が不快なことを愚痴っているとき、私の内心は...",
    answera:
      "ああ...そうだったのね。その友達はこういうところがイライラするのか。",
    answerb: "(聞いて反応している間に内心の考えがない)",
    type: "TF",
  },
  {
    id: 9,
    title: "「寝坊してドライシャンプー使ってきたんだ」と友達が言ったら、",
    answera: "おお、どこのブランド使ってるの？\n髪はふわふわになった?",
    answerb: "それでも上手に使ったね 昨日何してたの？寝坊したの？",
    type: "TF",
  },
  {
    id: 10,
    title: "友達が急に電話して「今出て！」と\n言ったら、",
    answera: "急に？ (即席の予定は嫌い)",
    answerb: "笑、どこ？ (即席の予定は楽しそう!)",
    type: "JP",
  },
  {
    id: 11,
    title: "明日友達が家に遊びに来ることになった。この状況で私は？",
    answera: "前もって掃除もして、\n何食べるか考えておく。",
    answerb: "友達が来たらその都度決める。",
    type: "JP",
  },
  {
    id: 12,
    title: "私はどんな人？",
    answera: "早まって判断しない。",
    answerb: "早く判断したい。",
    type: "JP",
  },
];
