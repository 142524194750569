import React from "react";
import "./JpSharebutton.css";
import Swal from "sweetalert2";
import { CopyToClipboard } from "react-copy-to-clipboard";
import share from "./img/share.png";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";

const Sawl = () => {
  require("sweetalert2");
  Swal.fire("Copy!");
};

function JpSharebutton() {
  const currentUrl = "https://zombiembti.net/test/jp/rollingpaper2jp";
  const testTitle = "ローリングペーパー MBTI - Love | TAKEFIVE TEST";

  return (
    <>
      <div className="FlexContainer_jp">
        <CopyToClipboard text={currentUrl}>
          <div className="URLShareButton_jp" onClick={Sawl}>
            <img src={share} alt="url" />
          </div>
        </CopyToClipboard>
        <div className="GridContainer_jp">
          <FacebookShareButton
            style={{ marginRight: "20px", marginTop: "5px" }}
            url={currentUrl}
            quote={testTitle}
            hashtag={"#" + testTitle.replace(/\s/g, "")}
          >
            <FacebookIcon
              size={32}
              round={true}
              borderRadius={24}
            ></FacebookIcon>
          </FacebookShareButton>

          <TwitterShareButton
            style={{ marginRight: "20px", marginTop: "5px" }}
            url={currentUrl}
            title={testTitle}
            hashtags={[
              "ローリングペーパーMBTILove",
              "MBTIテスト",
              "性格テスト",
              "タイプテスト",
              "恋愛テスト",
            ]}
          >
            <TwitterIcon size={32} round={true} borderRadius={24}></TwitterIcon>
          </TwitterShareButton>
        </div>
      </div>
    </>
  );
}

export default JpSharebutton;
