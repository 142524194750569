export const QuestionData = [
  {
    id: 1,
    title: "나는 업무를 수행할 때...",
    answera: "구체적인 지침과 \n절차를 따르는 것을 좋아한다",
    answerb: "창의적인 접근 방식을 선호한다",
    type: "SN",
  },
  {
    id: 2,
    title: "업무에서 중요한 결정을 내릴 때,\n나는",
    answera: "이성적이고 분석적인 방법을 사용한다",
    answerb: "직관적이고 감정적인 방법을 사용한다",
    type: "TF",
  },
  {
    id: 3,
    title: "나는 회의나 그룹 토론에서...",
    answera: "적극적으로 의견을 제시한다",
    answerb: "주로 경청하는 편이다",
    type: "EI",
  },
  {
    id: 4,
    title: "내가 더 자신있는 것은?",
    answera: "미래의 트렌드와 가능성에 대해 \n생각하는 것",
    answerb: "현재의 실질적인 문제 해결에 \n집중하는 것",
    type: "SN",
  },
  {
    id: 5,
    title: "나는 업무 마감일을...",
    answera: "철저히 지킨다",
    answerb: "필요에 따라 조정한다",
    type: "JP",
  },
  {
    id: 6,
    title: "직장에서 갈등이 발생한다면,\n나는?",
    answera: "공정하고 객관적으로 \n문제를 해결하려고 한다",
    answerb: "관계를 유지하기 위해 \n조화를 중요시 한다",
    type: "TF",
  },
  {
    id: 7,
    title: "프로젝트를 수행할 때는?",
    answera: "명확한 계획과 일정을 \n세우는 걸 선호한다",
    answerb: "유연하게 상황에 맞추어 \n진행하는 걸 선호한다",
    type: "JP",
  },
  {
    id: 8,
    title: "복잡한 문제를 해결할 때는...",
    answera: "실용적인 접근 방식을 사용한다",
    answerb: "창의성을 발휘해 \n새로운 아이디어를 낸다",
    type: "SN",
  },
  {
    id: 9,
    title: "둘 중 내가 선호하는 것은?",
    answera: "사람들과 협력하며 일하기",
    answerb: "혼자서 집중해서 일하기",
    type: "EI",
  },
  {
    id: 10,
    title: "업무 프로세스에 대해 나는...",
    answera: "업무 프로세스를 개선하고 \n효율성을 높이는 것을 중시한다",
    answerb: "현재의 방식을 유지하는 것을 선호한다",
    type: "JP",
  },
  {
    id: 11,
    title: "팀 프로젝트에서 나는?",
    answera: "팀장으로서 리더십을 발휘하고 \n팀을 이끄는 것을 즐긴다",
    answerb: "팀의 일원으로서\n 기여하는 것을 더 좋아한다",
    type: "EI",
  },
  {
    id: 12,
    title: "내가 팀장이라면, \n업무에서 결정을 내릴 때...",
    answera: "논리와 데이터에 근거하여 결정을 내린다",
    answerb: "팀원들의 감정과 의견을 고려한다",
    type: "TF",
  },
];
