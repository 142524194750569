import React from "react";
import "./RealloveMainjp.css";
import { useNavigate } from "react-router-dom";
import main from "./img/reallove_Main.png";
import Take5 from "../../../../components/Take5/Take5";
import SEO from "../../SEOjp";
import { realloveAttributejp } from "../../seoAttributesjp";
import AdSenseComponent from "../../../../AdSenseComponent";

const RealLoveMainjp = () => {
  const navigate = useNavigate();
  const QPage = () => {
    navigate("/test/jp/reallovejp/question");
  };

  const id = "reallovejp";

  return (
    <div className="reallovejp_wrapper">
      <div className="reallovejp_contents">
        <SEO attribute={realloveAttributejp} id={id} />
        <Take5 />
        <div className="reallovejp_main_container">
          <img src={main} alt="main" />
          <button onClick={QPage} className="reallovejp_start_btn">
            始める
          </button>
        </div>
      </div>
      <AdSenseComponent />
    </div>
  );
};

export default RealLoveMainjp;
