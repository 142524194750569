import React from "react";
import { useState } from "react";
import "./SchoolQuestion.css";
import { useNavigate } from "react-router-dom";
import { QuestionData } from "./data/questionData";
import backimg from "./img/backimg.png";
import Take5 from "../../../../components/Take5/Take5";

const SchoolQuestion = () => {
  const [questionNo, setQuestionNo] = useState(0);
  const [totalScore, setTotalScore] = useState([
    { id: "EI", score: 0 },
    { id: "SN", score: 0 },
    { id: "TF", score: 0 },
    { id: "JP", score: 0 },
  ]);
  const navigate = useNavigate();

  const handleClickButton = (no, type) => {
    const newScore = totalScore.map((s) =>
      s.id === type ? { id: s.id, score: s.score + no } : s
    );
    setTotalScore(newScore);
    if (QuestionData.length !== questionNo + 1) {
      setQuestionNo(questionNo + 1);
    } else {
      const mbti = newScore.reduce(
        (acc, curr) =>
          acc +
          (curr.score >= 2 ? curr.id.substring(0, 1) : curr.id.substring(1, 2)),
        ""
      );
      navigate(`/test/school/result/${mbti}`);
    }
  };

  return (
    <div className="School_Q_Wrapper">
      <Take5 />
      <div className="School_Q_BackImg">
        <img src={backimg} className="backimg" alt="" />
      </div>
      <div className="School_Q_container">
        <div className="School_Q_Numbering">
          {QuestionData[questionNo].numbering}
        </div>
        <div className="School_Q_Title">{QuestionData[questionNo].title}</div>
        <div className="School_Q_Btn_Container">
          <button
            className="School_Q_Btn"
            style={{
              minWidth: "280px",
              maxWidth: "280px",
              minHeight: "100px",
              maxHeight: "100px",
            }}
            onClick={() => handleClickButton(1, QuestionData[questionNo].type)}
            type="button"
          >
            {QuestionData[questionNo].answera}
          </button>
          <button
            className="School_Q_Btn"
            style={{
              minWidth: "280px",
              maxWidth: "280px",
              minHeight: "100px",
              maxHeight: "100px",
            }}
            onClick={() => handleClickButton(0, QuestionData[questionNo].type)}
            type="button"
          >
            {QuestionData[questionNo].answerb}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SchoolQuestion;
