import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./LoveThinkQ.css";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import { QuestionData } from "./data/QuestionData";
import Take5 from "../../../../components/Take5/Take5";
import backImg from "./img/lovethink_Q_back.png";

const LoveThinkQ = () => {
  const location = useLocation();
  const { name } = location.state || { name: "" };
  const [questionNo, setQuestionNo] = useState(0);
  const [totalScore, setTotalScore] = useState([
    { id: "EI", score: 0 },
    { id: "SN", score: 0 },
    { id: "TF", score: 0 },
    { id: "JP", score: 0 },
  ]);
  const navigate = useNavigate();

  const handleClickButton = (no, type) => {
    const newScore = totalScore.map((s) =>
      s.id === type ? { id: s.id, score: s.score + no } : s
    );

    setTotalScore(newScore);

    if (QuestionData.length !== questionNo + 1) {
      // 다음문제로 문제수 증가
      setQuestionNo(questionNo + 1);
    } else {
      // mbti도출
      const mbti = newScore.reduce(
        (acc, curr) =>
          acc +
          (curr.score >= 2 ? curr.id.substring(0, 1) : curr.id.substring(1, 2)),
        ""
      );
      navigate(`/test/lovethink/result/${mbti}`, { state: { name } });
    }
  };

  return (
    <div className="lovethink_Q_Wrapper">
      <Take5 />
      <Progress
        percent={(questionNo / QuestionData.length) * 100}
        status="error"
        theme={{
          error: {
            symbol: "💘",
            color: "#3a45a94d",
          },
        }}
      />
      <div className="lovethink_Q_BackImg">
        <img src={backImg} alt="rp_back" />
      </div>
      <div className="lovethink_Q_Contents">
        <div className="lovethink_Q_Title">
          {QuestionData[questionNo].title}
        </div>
        <div className="lovethink_Q_Btn_Container">
          <button
            className="lovethink_Q_Btn"
            onClick={() => handleClickButton(1, QuestionData[questionNo].type)}
            type="button"
          >
            {QuestionData[questionNo].answera}
          </button>
          <button
            className="lovethink_Q_Btn"
            onClick={() => handleClickButton(0, QuestionData[questionNo].type)}
            type="button"
          >
            {QuestionData[questionNo].answerb}
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoveThinkQ;
