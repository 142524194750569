import React from "react";
import "./OlymposMainen.css";
import { useNavigate } from "react-router-dom";
import main from "./img/olympos_main.png";
import Take5 from "../../../../components/Take5/Take5";
import SEO from "../../SEOen";
import { olymposAttributeen } from "../../seoAttributesen";
import AdSenseComponent from "../../../../AdSenseComponent";

const Olymposen = () => {
  const navigate = useNavigate();
  const QPage = () => {
    navigate("/test/en/olymposen/question");
  };

  const id = "olymposen";

  return (
    <div className="olymposen_wrapper">
      <div className="olymposen_contents">
        <SEO attribute={olymposAttributeen} id={id} />
        <Take5 />
        <div className="olymposen_main_container">
          <img src={main} alt="main" />
          <button onClick={QPage} className="olymposen_start_btn">
            Get Start!
          </button>
        </div>
      </div>
      <AdSenseComponent />
    </div>
  );
};

export default Olymposen;
