import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BiRightArrowAlt } from "react-icons/bi";
import characters from "./data/characters.json";
import Take5 from "../../../../components/Take5/Take5";
import SEO from "../../SEOko";
import "./AngelMain.css";
import { angelAttributeko } from "../../seoAttributesko";
import angel_main from "./img/angel_main.png";
import AdSenseComponent from "../../../../AdSenseComponent";

function AngelMain() {
  const [name, setName] = useState("");
  const navigate = useNavigate();
  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const id = "angel";

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const handleScoreCalculation = () => {
    // 초성이 하나라도 있는 경우
    if (/[ㄱ-ㅎㅏ-ㅣ]/.test(name)) {
      alert("이름을 정확히 입력해주세요");
      return; // 함수 종료
    } else if (name.length === 0) {
      alert("이름을 입력해주세요");
      return;
    } else if (!/[^a-zA-Z]/.test(name)) {
      alert("한글 이름을 입력해주세요");
      return;
    }

    const nameCharacters = [...name];
    const calculatedScores = nameCharacters.map((char) => {
      return {
        character: char,
        score: characters[char]?.score || 0, // 해당 초성에 대한 score 값이 없는 경우 0으로 설정
      };
    });

    const totalScore = calculatedScores.reduce(
      (acc, cur) => acc + cur.score,
      0
    );

    const targetScores = [
      ((totalScore + 3) % 70) + 1,
      ((totalScore + 10) % 70) + 1,
      ((totalScore - 5) % 70) + 1,
      // ((totalScore + 8) % 70) + 1,
      // ((totalScore - 7) % 70) + 1,
    ];

    navigate("/test/angel/result", { state: { targetScores, name } });
  };

  return (
    <div className="angel_wrapper">
      <div className="alngel_contents">
        <SEO attribute={angelAttributeko} id={id} />
        <Take5 />
        <div className="angel_main_container">
          <h3 className="angel_title">
            수호천사가 <br />
            나를 배정받을 때🪽
          </h3>
          <img src={angel_main} className="angel_main_img" alt="angel_main" />

          <input
            className="angel_input"
            type="text"
            placeholder="이름을 입력해주세요"
            value={name}
            onChange={handleNameChange}
          />
          <p className="name_alret">
            이름은 해당 테스트 이외의 용도로 사용되지 않습니다
          </p>
          <button className="angel_btn" onClick={handleScoreCalculation}>
            <BiRightArrowAlt style={{ fontSize: "35px" }} />
          </button>
          <AdSenseComponent />
        </div>
      </div>
    </div>
  );
}

export default AngelMain;
