import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./BigfiveR.css";
import Take5 from "../../../../components/Take5/Take5";
import Footer from "../../../../components/Footer/Footer";
import ShareButton from "./ShareButton";
import { SyncLoader } from "react-spinners";
import CoupangBanner from "../../CoupangBanner/CoupangBanner";
import Card from "../../Cardko";
import TestList from "../../TestListko.json";
import CoupangPromotionCP from "../../CoupangPromotionCP/CoupangPromotionCP";
import InnerCoupang from "../../InnerCoupang/InnerCoupang";

import questions from "./data/questions.json";
import descriptions from "./data/result.json";
import overallProfiles from "./data/OverallProfiles.json";

import { Radar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const BigfiveR = () => {
  const navigate = useNavigate();
  const [showResult, setShowResult] = useState(false);
  const [hasCookie, setHasCookie] = useState(false);
  const [testList, setTestList] = useState([]);

  const location = useLocation();
  const { answers } = location.state || { answers: {} };

  const excludedTestId = "bigfive";
  const filteredTestList = testList.filter(
    (test) => test.id !== excludedTestId
  );

  const handleResultShow = () => {
    const resultContainer = document.querySelector(".All_Test_no_cookie");
    resultContainer.style.overflow = "visible";
    resultContainer.style.height = "100%";
  };

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, []);

  const [results, setResults] = useState({
    외향성: 0,
    신경성: 0,
    창의성: 0,
    협력성: 0,
    성실성: 0,
  });

  useEffect(() => {
    window.scrollTo(0, 0); // 페이지 로드 시 스크롤을 최상단으로 이동

    const calculateResults = () => {
      const scores = {
        외향성: 0,
        신경성: 0,
        창의성: 0,
        협력성: 0,
        성실성: 0,
      };
      let answerCount = 0;

      for (let key in answers) {
        const question = questions.find((q) => q.id === parseInt(key));
        if (question) {
          const category = question.category;
          scores[category] += parseInt(answers[key]);
          answerCount++;
        }
      }

      if (answerCount !== questions.length) {
        alert("모든 질문에 답변해 주세요.");
        return;
      }

      for (let category in scores) {
        scores[category] = Math.round((scores[category] / 3) * 10) / 10; // 각 특성 점수를 평균으로 나누어 5점 만점으로 환산하고 소수점 1자리로 표현
      }

      setResults(scores);
    };

    calculateResults();
  }, [answers]);

  const getDescription = (category, score) => {
    if (score <= 2) return descriptions[category].descriptions["1-2"];
    if (score === 3) return descriptions[category].descriptions["3"];
    return descriptions[category].descriptions["4-5"];
  };

  const getOverallProfile = (results) => {
    const profileParts = [];
    for (let category in results) {
      const score = results[category];
      let scoreRange = "3";
      if (score <= 2) scoreRange = "1-2";
      if (score >= 4) scoreRange = "4-5";
      profileParts.push(overallProfiles.overallProfiles[category][scoreRange]);
    }
    return profileParts.join(" ");
  };

  useEffect(() => {
    setTestList(TestList);
  }, []);

  useEffect(() => {
    setHasCookie(document.cookie.indexOf("coupang=true") !== -1);
  }, []);

  const handleClick = () => {
    setShowResult(true);
    handleResultShow();
  };

  return (
    <>
      <div className="b5_R_Wrapper">
        {loading ? (
          <div className="b5_loading_container">
            <p className="b5_loading_text">프로파일링중...🕵🏻</p>
            <SyncLoader
              color={"#178aa1"}
              loading={loading}
              size={9}
              speedMultiplier={0.5}
              cssOverride={{
                marginTop: "3rem",
                backgroundColor: "transparent",
              }}
              aria-label="Loading Spinner"
              data-testid="loader"
            />

            <CoupangPromotionCP />
          </div>
        ) : (
          <>
            <Take5 />
            {hasCookie ? (
              <>
                <div className="All_Test_has_cookie">
                  <div className="b5_R_Contents">
                    <div className="b5_R_title">
                      <h3>당신의 결과는...</h3>
                    </div>
                    <div className="b5_R_ResultImg_Container" id="coupangDiv">
                      <div
                        className="b5_chart"
                        style={{ maxWidth: "300px", margin: "0 auto" }}
                      >
                        <Radar
                          data={{
                            labels: [
                              "외향성",
                              "협력성",
                              "성실성",
                              "신경성",
                              "창의성",
                            ],
                            datasets: [
                              {
                                label: "",
                                data: [
                                  parseFloat(results["외향성"]),
                                  parseFloat(results["협력성"]),
                                  parseFloat(results["성실성"]),
                                  parseFloat(results["신경성"]),
                                  parseFloat(results["창의성"]),
                                ],
                                backgroundColor: "rgba(34, 202, 236, .2)",
                                borderColor: "rgba(34, 202, 236, 1)",
                                borderWidth: 2,
                              },
                            ],
                          }}
                          options={{
                            maintainAspectRatio: false,
                            scales: {
                              r: {
                                ticks: {
                                  display: false,
                                },
                                pointLabels: {
                                  font: {
                                    size: 13,
                                  },
                                },
                              },
                            },
                            plugins: {
                              legend: {
                                display: false,
                              },
                            },
                          }}
                          height={300}
                          width={300}
                        />
                      </div>
                      <div className="b5_R_details">
                        {Object.keys(results).map((key) => (
                          <div key={key}>
                            <h3>
                              🔎 {key}: {results[key]}/5
                            </h3>
                            <p>{getDescription(key, results[key])}</p>
                          </div>
                        ))}
                      </div>
                      <div className="b5_R_profile">
                        <h3>🕵🏻 종합적인 성격 프로파일</h3>
                        <p>{getOverallProfile(results)}</p>
                      </div>
                    </div>
                    <InnerCoupang />
                    <div className="b5_R_Restart_Btn_Container">
                      <button
                        className="b5_R_Restart_Btn"
                        style={{
                          minWidth: "210px",
                          maxWidth: "210px",
                          minHeight: "50px",
                          boxShadow: "none",
                        }}
                        onClick={() => navigate("/test/bigfive")}
                      >
                        테스트 다시하기
                      </button>
                    </div>

                    <ShareButton />
                    <div className="TestList_container">
                      {filteredTestList.map((test) => (
                        <div key={test.id} className="TestList_container2">
                          <Link to={`/test/${test.id}`}>
                            <Card test={test} />
                          </Link>
                        </div>
                      ))}
                    </div>
                    <Footer />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="All_Test_no_cookie_container">
                  <div className="All_Test_no_cookie">
                    <div className="b5_R_Contents">
                      <div className="b5_R_title">
                        <h3>당신의 결과는...</h3>
                      </div>

                      <div className="b5_R_ResultImg_Container" id="coupangDiv">
                        <div
                          className="b5_chart"
                          style={{ maxWidth: "300px", margin: "0 auto" }}
                        >
                          <Radar
                            data={{
                              labels: [
                                "외향성",
                                "협력성",
                                "성실성",
                                "신경성",
                                "창의성",
                              ],
                              datasets: [
                                {
                                  label: "",
                                  data: [
                                    parseFloat(results["외향성"]),
                                    parseFloat(results["협력성"]),
                                    parseFloat(results["성실성"]),
                                    parseFloat(results["신경성"]),
                                    parseFloat(results["창의성"]),
                                  ],
                                  backgroundColor: "rgba(34, 202, 236, .2)",
                                  borderColor: "rgba(34, 202, 236, 1)",
                                  borderWidth: 2,
                                },
                              ],
                            }}
                            options={{
                              maintainAspectRatio: false,
                              scales: {
                                r: {
                                  ticks: {
                                    display: false,
                                  },
                                  pointLabels: {
                                    font: {
                                      size: 13,
                                    },
                                  },
                                },
                              },
                              plugins: {
                                legend: {
                                  display: false,
                                },
                              },
                            }}
                            height={300}
                            width={300}
                          />
                        </div>
                        <div className="b5_R_details">
                          {Object.keys(results).map((key) => (
                            <div key={key}>
                              <h3>
                                🔎 {key}: {results[key]}/5
                              </h3>
                              <p>{getDescription(key, results[key])}</p>
                            </div>
                          ))}
                        </div>
                        <div className="b5_R_profile">
                          <h3>🕵🏻 종합적인 성격 프로파일</h3>
                          <p>{getOverallProfile(results)}</p>
                        </div>
                      </div>
                      <InnerCoupang />
                      <div className="b5_Coupang_div">
                        <CoupangBanner handleClick={handleClick} />
                      </div>

                      {showResult && (
                        <>
                          <div className="b5_R_Restart_Btn_Container">
                            <button
                              className="b5_R_Restart_Btn"
                              style={{
                                minWidth: "210px",
                                maxWidth: "210px",
                                minHeight: "50px",
                                boxShadow: "none",
                              }}
                              onClick={() => navigate("/test/bigfive")}
                            >
                              테스트 다시하기
                            </button>
                          </div>

                          <ShareButton />
                          <div className="TestList_container">
                            {filteredTestList.map((test) => (
                              <div
                                key={test.id}
                                className="TestList_container2"
                              >
                                <Link to={`/test/${test.id}`}>
                                  <Card test={test} />
                                </Link>
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default BigfiveR;
