export const QuestionData = [
  {
    id: 1,
    title: "What would you like to do \nwith someone you like?",
    answera: "Travel to many places and create memories together",
    answerb: "Spend quiet, intimate time together",
    type: "EI",
  },
  {
    id: 2,
    title: "Which type of gathering \ndo you prefer?",
    answera: "Events where you can meet new people",
    answerb: "Get-togethers with close friends",
    type: "EI",
  },
  {
    id: 3,
    title: "How do you recharge your energy?",
    answera: "I recharge by socializing with people",
    answerb: "I recharge by spending time alone",
    type: "EI",
  },
  {
    id: 4,
    title:
      "They sent a picture of an \ninteresting-looking cloud.\n My reaction was:",
    answera: "Wow, that cloud looks beautiful",
    answerb: "Oh, it reminds me of a cloud that looks like a dinosaur",
    type: "SN",
  },
  {
    id: 5,
    title: `If they say, \n"Have you ever imagined \nhaving a Death Note?" \nI would respond with:`,
    answera: "Really? \n(They have quite an imagination)",
    answerb:
      "Me too! I've even thought about how to use it to kill people, lol",
    type: "SN",
  },
  {
    id: 6,
    title: "How would you describe yourself?",
    answera:
      "I'm good at explaining things, \nbut I struggle with visualizing them in my mind",
    answerb:
      "I can visualize things well in my mind, but I find it difficult to describe them verbally",
    type: "SN",
  },
  {
    id: 7,
    title: "When I say 'I'll think about it,'\nit means:",
    answera: "I really think about it\n (deep thought)",
    answerb: "I actually don't want to \n(evasive rejection)",
    type: "TF",
  },
  {
    id: 8,
    title: "When I'm going through \na tough problem, I need:",
    answera: "Words that can help me solve the problem",
    answerb: "Empathy for my current situation and emotions",
    type: "TF",
  },
  {
    id: 9,
    title:
      "They said they went to the hospital \nbecause they were sick. \nWhat I'm curious about is:",
    answera:
      "Which part of them is hurting?\nWhat did they say at the hospital?",
    answerb: "How difficult is it for them?\nShould I be there for them?",
    type: "TF",
  },
  {
    id: 10,
    title:
      "We went to a cafe together, \nbut the door is closed! \nIn this situation, I:",
    answera: "Had another cafe in mind just in case",
    answerb: "Look around for another cafe right there",
    type: "JP",
  },
  {
    id: 11,
    title: "They are looking for nail clippers. \nAt this moment, I:",
    answera: "Pinpoint the exact location and tell them",
    answerb: "I search and say, 'It's probably around here somewhere,'",
    type: "JP",
  },
  {
    id: 12,
    title: "I'm trying a new recipe! \nHow do I make it?",
    answera: "Follow the recipe exactly as it is",
    answerb: "Take a quick look at the recipe and make it with a gut feeling",
    type: "JP",
  },
];
