import QTEA from "../img/QTEA.png";
import QTES from "../img/QTES.png";
import QTDA from "../img/QTDA.png";
import QTDS from "../img/QTDS.png";
import QCEA from "../img/QCEA.png";
import QCES from "../img/QCES.png";
import QCDA from "../img/QCDA.png";
import QCDS from "../img/QCDS.png";
import BTEA from "../img/BTEA.png";
import BTES from "../img/BTES.png";
import BTDA from "../img/BTDA.png";
import BTDS from "../img/BTDS.png";
import BCEA from "../img/BCEA.png";
import BCES from "../img/BCES.png";
import BCDA from "../img/BCDA.png";
import BCDS from "../img/BCDS.png";

const resultData = {
  QTEA: {
    image: QTEA,
  },
  QTES: {
    image: QTES,
  },
  QTDA: {
    image: QTDA,
  },
  QTDS: {
    image: QTDS,
  },
  QCEA: {
    image: QCEA,
  },
  QCES: {
    image: QCES,
  },
  QCDA: {
    image: QCDA,
  },
  QCDS: {
    image: QCDS,
  },
  BTEA: {
    image: BTEA,
  },
  BTES: {
    image: BTES,
  },
  BTDA: {
    image: BTDA,
  },
  BTDS: {
    image: BTDS,
  },
  BCEA: {
    image: BCEA,
  },
  BCES: {
    image: BCES,
  },
  BCDA: {
    image: BCDA,
  },
  BCDS: {
    image: BCDS,
  },
};

export default resultData;
