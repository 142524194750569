import React, { useEffect, useState, useMemo } from "react";
import "./AngelR.css";
import Footer from "../../../../components/Footer/Footer";
import { Link, useNavigate, useLocation } from "react-router-dom";
import ShareButton from "./ShareButton";
import { MoonLoader } from "react-spinners";
import Take5 from "../../../../components/Take5/Take5";
import CoupangBanner from "./CoupangBanner";
import Card from "../../Cardko";
import oneWords from "./data/oneWord.json";
import twoWords from "./data/twoWord.json";
import threeWords from "./data/threeWord.json";
import TestListko from "../../TestListko.json";
import angelbackImg from "./img/angel_back.png";
import CoupangPromotionCP from "../../CoupangPromotionCP/CoupangPromotionCP";
import InnerCoupang from "../../InnerCoupang/InnerCoupang";
// import AdSenseComponent from "../../../../AdSenseComponent";

function AngelR() {
  const navigate = useNavigate();
  const location = useLocation();
  const targetScores = useMemo(() => {
    return location.state?.targetScores || [];
  }, [location.state?.targetScores]);
  const name = location.state?.name || "";

  const [showResult, setShowResult] = useState(false);
  const [hasCookie, setHasCookie] = useState(false);
  const [testList, setTestList] = useState([]);

  const excludedTestId = "angel";
  const filteredTestList = testList.filter(
    (test) => test.id !== excludedTestId
  );

  useEffect(() => {
    setTestList(TestListko);
  }, []);

  useEffect(() => {
    setHasCookie(document.cookie.indexOf("coupang=true") !== -1);
  }, []);

  const handleClick = () => {
    setShowResult(true);
    handleResultShow();
  };

  const handleResultShow = () => {
    const resultContainer = document.querySelector(".angel_no_cookie");
    resultContainer.style.overflow = "visible";
    resultContainer.style.height = "100%";
  };

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3500);
  }, []);

  // 결과 로직
  useEffect(() => {
    const timer = setTimeout(() => {}, 0);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const getWordByScore = (score, isBaseWord) => {
    let wordList;
    if (isBaseWord) {
      wordList = oneWords;
    } else {
      if (score === targetScores[2]) {
        wordList = threeWords;
      } else {
        wordList = twoWords;
      }
    }

    const wordObj = wordList.find((wordObj) => wordObj.score === score);
    return wordObj ? { word: wordObj.word } : null;
  };

  const renderAngelRecipe = () => {
    const oneWord = getWordByScore(targetScores[0], true)?.word;
    const twoWord = getWordByScore(targetScores[1], false)?.word;
    const threeWord = getWordByScore(targetScores[2], false)?.word;

    return (
      <div className="angel_result_all_container">
        <h2 className="angel_result_title">
          수호천사가 <br />
          나를 배정받을 때🪽
        </h2>
        <div className="angel_resultImg">
          <img src={angelbackImg} alt="" />
        </div>
        <div className="angel_reslut_container">
          <p className="angel_input_name">
            어디 보자~ 내가 담당할 <br />
            인간의 이름은... <span className="result_angel_name">{name}</span>?
          </p>

          <p className="oneWord_container">
            <span className="oneWord">{oneWord}</span>
          </p>
          <p className="twoWord_container">
            자세히 보니
            <br />
            <span className="twoWord">{twoWord}</span>
          </p>
          <p className="threeWord_container">
            <span className="threeWord">{threeWord}</span>
            <br />
            요거는 선물!
          </p>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="Angel_R_Wrapper">
        {loading ? (
          <div className="angel_loading_container">
            <p className="angel_loading_text">
              천사 배정중...🪽
              <MoonLoader
                color={"#1fadff"}
                loading={loading}
                size={50}
                cssOverride={{
                  marginTop: "2rem",
                  backgroundColor: "transparent",
                }}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </p>
            <CoupangPromotionCP />
            {/* <AdSenseComponent /> */}
          </div>
        ) : (
          <>
            <Take5 />
            {hasCookie ? (
              <>
                <div className="angel_has_cookie">
                  <div className="Angel_R_Contents">
                    <div className="Angel_R_Result_Container">
                      <>{renderAngelRecipe()}</>
                    </div>
                    <div className="angel_IC_container">
                      <InnerCoupang />
                    </div>
                    <div className="Angel_R_Btn_Container">
                      <button
                        className="Angel_R_Btn"
                        style={{
                          minWidth: "210px",
                          maxWidth: "210px",
                          minHeight: "50px",
                          boxShadow: "none",
                        }}
                        onClick={() => navigate("/test/angel")}
                      >
                        다른 이름으로 해볼래!
                      </button>
                    </div>
                    <ShareButton />
                    <div className="TestList_container">
                      {filteredTestList.map((test) => (
                        <div key={test.id} className="TestList_container2">
                          <Link to={`/test/${test.id}`}>
                            <Card test={test} />
                          </Link>
                        </div>
                      ))}
                    </div>
                    <Footer />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="angel_no_cookie_container">
                  <div className="angel_no_cookie">
                    <div className="Angel_R_Contents">
                      <div className="Angel_R_Result_Container" id="coupangDiv">
                        <>{renderAngelRecipe()}</>
                      </div>
                      <div className="angel_coupang_banner">
                        <CoupangBanner handleClick={handleClick} />
                        {/* <AdSenseComponent /> */}
                      </div>
                      {showResult && (
                        <>
                          <div className="angel_IC_container">
                            <InnerCoupang />
                          </div>
                          <div className="Angel_R_Btn_Container">
                            <button
                              className="Angel_R_Btn"
                              style={{
                                minWidth: "210px",
                                maxWidth: "210px",
                                minHeight: "50px",
                                boxShadow: "none",
                              }}
                              onClick={() => navigate("/test/angel")}
                            >
                              다른 이름으로 해볼래!
                            </button>
                          </div>
                          <ShareButton />
                          <div className="TestList_container">
                            {filteredTestList.map((test) => (
                              <div
                                key={test.id}
                                className="TestList_container2"
                              >
                                <Link to={`/test/${test.id}`}>
                                  <Card test={test} />
                                </Link>
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}
export default AngelR;
