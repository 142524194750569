import React from "react";
import "./KeywordMbtiMainen.css";
import { useNavigate } from "react-router-dom";
import main from "./img/KeywordMbti_Main.png";
import Take5 from "../../../../components/Take5/Take5";
import SEO from "../../SEOen";
import { keywordMbtiAttributeen } from "../../seoAttributesen";
import AdSenseComponent from "../../../../AdSenseComponent";

const KeywordMbtiMainen = () => {
  const navigate = useNavigate();
  const QPage = () => {
    navigate("/test/en/keywordMbtien/question");
  };

  const id = "keywordMbtien";

  return (
    <div className="keywordMbtien_wrapper">
      <div className="keywordMbtien_contents">
        <SEO attribute={keywordMbtiAttributeen} id={id} />
        <Take5 />
        <div className="keywordMbtien_main_container">
          <img src={main} alt="main" />
          <button onClick={QPage} className="keywordMbtien_start_btn">
            Let's Go!
          </button>
        </div>
      </div>
      <AdSenseComponent />
    </div>
  );
};

export default KeywordMbtiMainen;
