export const QuestionData = [
  {
    id: 1,
    title: "In my opinion, I am...",
    answera: "An extroverted person",
    answerb: "An introverted person",
    type: "EI",
  },
  {
    id: 2,
    title: "I feel like having a drink after \na long time! What do I do?",
    answera: "Call friends and drink together",
    answerb: "Drink alone~",
    type: "EI",
  },
  {
    id: 3,
    title: "My preferred gathering is...",
    answera: "A gathering with many people",
    answerb: "A gathering with close friends",
    type: "EI",
  },
  {
    id: 4,
    title: "After watching a movie \nthat is difficult to understand, I...",
    answera: "Think there must be \nsome message in it",
    answerb: "Look for reviews and interpretations to read",
    type: "SN",
  },
  {
    id: 5,
    title: "What do I consider \nmore important?",
    answera: "The present rather than \nthe future",
    answerb: "The future rather than\n the present",
    type: "SN",
  },
  {
    id: 6,
    title:
      "When I visit an art museum and\n see a piece of art, \nmy thoughts are...",
    answera: "Oh, the colors are nice.\nThe brushstrokes are also artful.",
    answerb:
      "I wonder what emotions and situations led to the creation of this piece?",
    type: "SN",
  },
  {
    id: 7,
    title: "When I feel betrayed by \nsomeone, it's when...",
    answera: "I find out they lied to me",
    answerb: "They don't share my struggles, \npain, and sadness",
    type: "TF",
  },
  {
    id: 8,
    title:
      "When I share a problem that is \nbothering me with someone, \nthe reaction I want is...",
    answera: "Realistic and practical words that help with problem-solving",
    answerb:
      "Emotionally empathetic words that connect with my current feelings",
    type: "TF",
  },
  {
    id: 9,
    title:
      "If I am putting my best \neffort into reacting to someone, \nit means...",
    answera: "I'm reacting just to get by, \nwithout much interest",
    answerb: "I'm expressing myself through reactions because I care a lot",
    type: "TF",
  },
  {
    id: 10,
    title:
      "I had a set plan, \nbut it got slightly messed up. \nIn this situation, I...",
    answera: "Feel a bit annoyed",
    answerb: "Just think, \n'Well, that's how it goes.'",
    type: "JP",
  },
  {
    id: 11,
    title: "What is the current\n state of my room?",
    answera: "The positions of each \nobject are fixed.",
    answerb: "I don't really know \nwhere everything is.",
    type: "JP",
  },
  {
    id: 12,
    title: "What kind of person am I?",
    answera: "I don't make judgments hastily.",
    answerb: "I want to make judgments quickly.",
    type: "JP",
  },
];
