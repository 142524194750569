import React from "react";
import "./CatlovesMeMainjp.css";
import { useNavigate } from "react-router-dom";
import main from "./img/catloveme_Main.png";
import Take5 from "../../../../components/Take5/Take5";
import SEO from "../../SEOjp";
import { catlovesmeAttributejp } from "../../seoAttributesjp";
import AdSenseComponent from "../../../../AdSenseComponent";

const CatlovesMeMainjp = () => {
  const navigate = useNavigate();

  const QPage = () => {
    navigate("/test/jp/catlovesmejp/question");
  };

  const id = "catlovesmejp";

  return (
    <div className="jp_clm_wrapper">
      <div className="jp_clm_contents">
        <SEO attribute={catlovesmeAttributejp} id={id} />
        <Take5 />
        <div className="jp_clm_main_container">
          <img src={main} alt="main" />
          <button onClick={QPage} className="jp_clm_start_btn">
            始める
          </button>
        </div>
      </div>
      <AdSenseComponent />
    </div>
  );
};

export default CatlovesMeMainjp;
