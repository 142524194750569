export const QuestionData = [
  {
    id: 1,
    title: "한달동안 아무런 약속도 \n잡지 못한다면 나는?",
    answera: "어떻게 그럴수가.. 심심하잖아!",
    answerb: "...? 지금도 없는데",
    type: "EI",
  },
  {
    id: 2,
    title: "둘 중 더 끌리는 것은?",
    answera: "광란의 락 페스티벌",
    answerb: "고즈넉한 곳에서 휴식",
    type: "EI",
  },
  {
    id: 3,
    title: "나는 코로나 때",
    answera: "너무 답답했어",
    answerb: "평소랑 똑같았어",
    type: "EI",
  },
  {
    id: 4,
    title: "나에게 더 어려운 것은?",
    answera: "머릿속으로 구상하는 일",
    answerb: "말로 설명하는 일",
    type: "SN",
  },
  {
    id: 5,
    title: "잠을 자기 전에 나는?",
    answera: "자야지, 하고 잠",
    answerb: "온갖 상상이 시작됨",
    type: "SN",
  },
  {
    id: 6,
    title: `친구가 ”한달동안 군만두만 \n먹을 수 있어?”라고 묻는다면?`,
    answera: "왜… 그래야 되는데?",
    answerb: "어디 갇혔어? 한달 버티면 돈 주나?",
    type: "SN",
  },
  {
    id: 7,
    title: `”나 꿈에서 죽었어”라는 말을 들으면?`,
    answera: `어쩌다가? 그거 길몽일걸`,
    answerb: `아우… 고통도 느껴졌어? 아팠겠다`,
    type: "TF",
  },
  {
    id: 8,
    title: `"난 너랑 취향 안 맞는 듯"\n이런 말을 들으면?`,
    answera: `ㅇㅇ맞아 진짜 안맞음 상극 그 자체`,
    answerb: `그렇긴 하지… (살짝 상처)`,
    type: "TF",
  },
  {
    id: 9,
    title: `"길냥이가 졸졸 따라오는데 \n어떡하지?”라는 말을 들으면?`,
    answera: "키울 거 아니면 \n신경 안 쓰는게 최선이야",
    answerb: "편의점에서 츄르라도 사는 거 어때?",
    type: "TF",
  },
  {
    id: 10,
    title: "집-회사-운동… \n매일같은 루틴은 나에게",
    answera: "안정감 그 자체. 불만없음",
    answerb: "답답해… 뭔가 재밌는 일이 필요해!",
    type: "JP",
  },
  {
    id: 11,
    title: "꽁돈 100만원이 생긴다면 나는?",
    answera: "계획있게 쓰거나 저축한다",
    answerb: "일단 사고싶은 거 다 사!",
    type: "JP",
  },
  {
    id: 12,
    title: "선택을 해야 하는 순간, 이때 나는?",
    answera: "이전 경험을 바탕으로 결정함",
    answerb: "나의 촉과 감을 믿고 결정함",
    type: "JP",
  },
];
