import React, { useState } from "react";
import "./BlackcartTarotCard.css";
import { useNavigate } from "react-router-dom";
import Take5 from "../../../../components/Take5/Take5";
import SEO from "../../SEOko";
import { blackcattarotAttributeko } from "../../seoAttributesko";
import AdSenseComponent from "../../../../AdSenseComponent";
import TwinklingStars from "./TwinklingStars";
import title from "./img/tarot_title.png";
import cardData from "./data/MainCardData";

const BlackcartTarotCard = () => {
  const [shuffledCards, setShuffledCards] = useState(
    [...cardData].sort(() => Math.random() - 0.5).slice(0, 4)
  );
  const [isShuffling, setIsShuffling] = useState(false);
  const navigate = useNavigate();

  const handleCardSelect = () => {
    navigate("/test/blackcattarot/result");
  };

  const handleShuffleCards = () => {
    setIsShuffling(true);

    setTimeout(() => {
      const shuffled = [...cardData].sort(() => Math.random() - 0.5);
      const firstFourCards = shuffled.slice(0, 4);
      setShuffledCards(firstFourCards);
      setIsShuffling(false);
    }, 1000);
  };

  const id = "blackcattarot";

  return (
    <div className="blackcat_Wrapper">
      <div className="blackcat_contents">
        <SEO attribute={blackcattarotAttributeko} id={id} />
        <Take5 />
        <div className="blackcat_card_container">
          <img src={title} alt="personalMessgaeTarot" />
          <p className="blackcat_card_p">
            조언이 필요한 질문을
            <br />
            마음 속으로 떠올리고,
            <br />
            카드 한 장을 선택해주세요
          </p>

          <div className="blackcat_back_container">
            {shuffledCards.map((card) => (
              <img
                key={card.id}
                src={card.image}
                alt={`Card${card.id}`}
                onClick={handleCardSelect}
                className={isShuffling ? "shuffling" : ""}
              />
            ))}
          </div>
        </div>
        <button className="blackcat_Btn" onClick={handleShuffleCards}>
          카드섞기
        </button>
        <TwinklingStars />
      </div>
      <div className="blackcat_ADSC_container">
        <AdSenseComponent />
      </div>
    </div>
  );
};

export default BlackcartTarotCard;
