import React, { useState } from "react";
import "./Song2000Q.css";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import { useNavigate } from "react-router-dom";
import { QuestionData } from "./data/QuestionData";
import Take5 from "../../../../components/Take5/Take5";
import backImg from "./img/Song_Q.png";

const Song2000Q = () => {
  const [questionNo, setQuestionNo] = useState(0);
  const [totalScore, setTotalScore] = useState(0);

  const navigate = useNavigate();

  const handleClickButton = (score) => {
    const newScore = totalScore + score;
    setTotalScore(newScore);

    if (QuestionData.length !== questionNo + 1) {
      setQuestionNo(questionNo + 1);
    } else {
      navigate("/test/song2000/result", { state: { totalScore: newScore } });
    }
  };

  return (
    <div className="song2000_Q_Wrapper">
      <Take5 />
      <Progress
        percent={(questionNo / QuestionData.length) * 100}
        status="error"
        theme={{
          error: {
            symbol: "🎵",
            color: "#468bb075",
          },
        }}
      />
      <div className="song2000_Q_BackImg">
        <img src={backImg} alt="rp_back" />
      </div>
      <div className="song2000_Q_Contents">
        <div className="song2000_Q_titlenum">
          {QuestionData[questionNo].num}
        </div>
        <div className="song2000_Q_titleQuestion">
          {QuestionData[questionNo].titlequestion}
        </div>
        <div className="song2000_Q_Title">
          {QuestionData[questionNo].question}
        </div>
        <div className="song2000_Q_Btn_Container">
          {QuestionData[questionNo].answers.map((answer, index) => (
            <button
              key={index}
              className="song2000_Q_Btn"
              onClick={() => handleClickButton(answer.score)}
              type="button"
            >
              {answer.option}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Song2000Q;
