export const QuestionData = [
  {
    id: 1,
    title: "평일 내내 정신없이 바빴어. \n돌아오는 주말에는...",
    answera: "스트레스 풀어야지! 나가서 논다",
    answerb: "집이 최고야... 혼자 있을거야",
    type: "EI",
  },
  {
    id: 2,
    title: "친구랑 노는 중인데, 친구가 \n“내 친구 불러도 돼?”라고 한다면?",
    answera: "니 친구가 내 친구지! 불러!",
    answerb: "ㅎ담에 놀자...!",
    type: "EI",
  },
  {
    id: 3,
    title: "친구들 사이에서\n내 포지션은?",
    answera: "재잘재잘 말하는 편",
    answerb: "주로 듣는 편",
    type: "EI",
  },
  {
    id: 4,
    title: `“우리는 왜 태어난 걸까?”\n친구가 이렇게 말하면 나는?`,
    answera: "? 그냥 뭐 태어났으니까...\n무슨 일 있음?",
    answerb: "인간의 존재의의에 대해 토론함",
    type: "SN",
  },
  {
    id: 5,
    title: "내가 더 좋아하는 스타일의 \n노래 가사는?",
    answera: "피부로 확 와닿는\n일상적이고 현실적인 가사",
    answerb: "어떻게 이런 가사를 쓰지? \n상상력과 감탄을 부르는 가사",
    type: "SN",
  },
  {
    id: 6,
    title: "어떤 것을 설명할 때 나는?",
    answera: "군더더기 없이 사실으로 표현함",
    answerb: "다양한 비유로 풍부하게 묘사함",
    type: "SN",
  },
  {
    id: 7,
    title: `"난 네가 너무 좋아!"라는\n말을 듣는다면?`,
    answera: `왜? 어떤 부분이?\n(순수궁금)`,
    answerb: `헛... 정말?\n(쑥스감동)`,
    type: "TF",
  },
  {
    id: 8,
    title: `내가 "나 지금 차 사고났어"라고 \n말한다는건?`,
    answera: `이거 먼저 처리해야 하니까 알아둬`,
    answerb: `지금 내 상태가 어떤지 물어봐줘`,
    type: "TF",
  },
  {
    id: 9,
    title: `"대충 하는 거 같은데 \n왜 이렇게 잘해?"라는 말을 들으면?`,
    answera: "(칭찬으로 들음. 어깨광대승천)",
    answerb: "(열심히 했는데 대충이라니.. 상처ㅠ)",
    type: "TF",
  },
  {
    id: 10,
    title: "나는 여행 계획을 세울 때...",
    answera: "시간과 동선의 효율이 최우선!",
    answerb: "발길 닿는대로~",
    type: "JP",
  },
  {
    id: 11,
    title: "내가 원하는 일상은?",
    answera: "루틴이 있는 안정적인 일상",
    answerb: "새롭고 변화무쌍한 일상",
    type: "JP",
  },
  {
    id: 12,
    title: "밥을 먹고나면 나는...",
    answera: "바로바로 설거지를 하는 편",
    answerb: "일단 쌓아두는 편",
    type: "JP",
  },
];
