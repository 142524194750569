export const QuestionData = [
  {
    id: 1,
    title: "私の意見では、私は...",
    answera: "社交的な人",
    answerb: "内向的な人",
    type: "EI",
  },
  {
    id: 2,
    title: "久しぶりに飲みたい気分！\nどうする？",
    answera: "友達を呼んで一緒に飲む",
    answerb: "一人で飲む〜",
    type: "EI",
  },
  {
    id: 3,
    title: "私の好きな集まりは...",
    answera: "たくさんの人との集まり",
    answerb: "親しい友達との集まり",
    type: "EI",
  },
  {
    id: 4,
    title: "理解しにくい映画を見た後、私は...",
    answera: "それには何かメッセージがあると思う",
    answerb: "レビューや解釈を探して読む",
    type: "SN",
  },
  {
    id: 5,
    title: "私がより重要だと考えるのは？",
    answera: "未来ではなく現在",
    answerb: "現在ではなく未来",
    type: "SN",
  },
  {
    id: 6,
    title: "美術館を訪れてアートを見るとき、\n私の考えは...",
    answera: "ああ、色が素敵だ。筆触りも巧妙だ。",
    answerb: "この作品の制作にはどんな\n感情や状況が導いたのだろう？",
    type: "SN",
  },
  {
    id: 7,
    title: "誰かに裏切られると感じるのは...",
    answera: "私に嘘をつかれたと知ったとき",
    answerb: "相手が私の苦労や痛み、\n悲しみを共有しないとき",
    type: "TF",
  },
  {
    id: 8,
    title: "悩みを共有するとき、\n私が望む反応は...",
    answera: "現実的で問題解決に役立つ言葉",
    answerb: "感情的に共感し、\n現在の気持ちに繋がる言葉",
    type: "TF",
  },
  {
    id: 9,
    title: "誰かに反応するとき、\n私が一生懸命に反応しているとは...",
    answera: "あまり興味がないけれど、\nとりあえず反応している",
    answerb: "気にかけているから、\n反応を通して自分を表現している",
    type: "TF",
  },
  {
    id: 10,
    title: "計画があったけれど、\nちょっと狂ってしまった。\nこの状況では...",
    answera: "少しイライラする",
    answerb: "まあ、そういうことだと思う。",
    type: "JP",
  },
  {
    id: 11,
    title: "私の部屋の現在の状態は？",
    answera: "各オブジェクトの位置が固定されている。",
    answerb: "どこに何があるのかよくわからない。",
    type: "JP",
  },
  {
    id: 12,
    title: "私はどんな人間ですか？",
    answera: "軽率に判断を下さない。",
    answerb: "速やかに判断を下したい。",
    type: "JP",
  },
];
