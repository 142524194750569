import React, { useEffect } from "react";
import "./BigfiveMainen.css";
import { useNavigate } from "react-router-dom";
import main from "./img/bigfiveMain.png";
import Take5 from "../../../../components/Take5/Take5";
import SEO from "../../SEOen";
import { bigfiveAttributeen } from "../../seoAttributesen";
import AdSenseComponent from "../../../../AdSenseComponent";

const BigfiveMainen = () => {
  const navigate = useNavigate();

  const QPage = () => {
    navigate("/test/en/bigfiveen/question");
  };

  const id = "bigfiveen";

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="en_b5_wrapper">
      <div className="en_b5_contents">
        <SEO attribute={bigfiveAttributeen} id={id} />
        <Take5 />
        <div className="en_b5_main_container">
          <img src={main} alt="main" />
          <button onClick={QPage} className="en_b5_start_btn">
            Start
          </button>
        </div>
      </div>
      <AdSenseComponent />
    </div>
  );
};

export default BigfiveMainen;
