import React from "react";
import "./RP2Mainen.css";
import { useNavigate } from "react-router-dom";
import main from "./img/rp_main2.png";
import startBtn from "./img/rp_start_btn.png";
import Take5 from "../../../../components/Take5/Take5";
import SEO from "../../SEOen";
import { rollingPaper2Attributeen } from "../../seoAttributesen";
import AdSenseComponent from "../../../../AdSenseComponent";

const RP2Mainen = () => {
  const navigate = useNavigate();
  const rpGotoQ = () => {
    navigate("/test/en/rollingpaper2en/question");
  };

  const id = "rollingpaper2en";

  return (
    <div className="rollingpaper2_wrapper">
      <div className="rollingpaper2_contents">
        <SEO attribute={rollingPaper2Attributeen} id={id} />
        <Take5 />
        <div className="rollingpaper2_main_container">
          <img src={main} alt="main" />
          <div onClick={rpGotoQ} className="rollingpaper2_start_btn_container">
            <img src={startBtn} alt="startBtn" />
          </div>
        </div>
      </div>
      <AdSenseComponent />
    </div>
  );
};

export default RP2Mainen;
