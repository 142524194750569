export const QuestionData = [
  {
    id: 1,
    title: "데이트가 없는 주말에 나는?",
    answera: "혼자 or 친구랑 논다",
    answerb: "집에서 혼자 있는다",
    type: "EI",
  },
  {
    id: 2,
    title: "연인의 친구들과 함께 \n 2박 3일로 여행을 가야 한다면?",
    answera: "좋지~! 재밌겠다!",
    answerb: "(좀 피곤하긴 하겠다) \n 네가 가고싶다면… 가자",
    type: "EI",
  },
  {
    id: 3,
    title: "일주일 내내 데이트를 \n하고 싶어하는 연인. 나는?",
    answera: "나도 좋아",
    answerb: "혼자만의 시간이 필요한데...",
    type: "EI",
  },
  {
    id: 4,
    title: "소개팅에 나왔다. \n상대를 보면서 나는?",
    answera: "첫인상 보고 어느정도 결정함",
    answerb: "어떤 사람인지 파악하며 판단함",
    type: "SN",
  },
  {
    id: 5,
    title: "소개받은 사람과 너무 잘 맞는다. \n이때 내 마음은?",
    answera: "와, 이렇게 잘 맞는 사람이 있다니!",
    answerb: "결혼까지 상상해봄",
    type: "SN",
  },
  {
    id: 6,
    title: `연인이 "만약에 우리가 헤어진다면, \n뭐 때문에 헤어지게 될까?"라고 \n묻는다면?`,
    answera: "뭘 그런 걸 생각하고 그래!",
    answerb: "음... (진지하게 생각함)  ",
    type: "SN",
  },
  {
    id: 7,
    title: `썸 타는 상대가 \n“달이 예쁘다”고 연락이 온다면?`,
    answera: `? 그런가? (달 확인함) `,
    answerb: `내 생각이 났나? 연락할 핑계가 \n필요했나? 암튼 귀엽군`,
    type: "TF",
  },
  {
    id: 8,
    title: "연인이 카톡을 읽고\n답장을 안 한다면? ",
    answera: `바쁜가 보네`,
    answerb: `어잇쒸… 왜 연락이 없지…`,
    type: "TF",
  },
  {
    id: 9,
    title: `연인의 고민을 들어줄 때 나는`,
    answera: "해결에 초점",
    answerb: "공감에 초점",
    type: "TF",
  },
  {
    id: 10,
    title: "데이트 하기로 한 날, 나는?",
    answera: "준비시간+이동시간+여유시간까지 \n계산해서 준비한다",
    answerb: "대충 이쯤 나가면 되지 않을까?",
    type: "JP",
  },
  {
    id: 11,
    title: "내일 연인이 집에 오기로 했다.\n이때 나는?",
    answera: "미리 청소도 하고 \n생활용품도 채워 놓는다",
    answerb: "필요한 거 있으면 그때 사면 되지",
    type: "JP",
  },
  {
    id: 12,
    title: "연인이 갑자기 제주도로 \n놀러가자고 한다. 이때 나는?",
    answera: "비행기표는? 렌트는? 숙소는?",
    answerb: "오예~ 일단 가자!!!",
    type: "JP",
  },
];
