import React from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../img/logo.png";
import "./Take5.css";

const Take5 = () => {
  const navigate = useNavigate();
  const gotoHome = () => {
    navigate("/");
  };

  return (
    <div className="logo_cp">
      <header className="logo_cp_header">
        <div onClick={gotoHome} className="logo_cp_container">
          <img src={Logo} alt="logo" />
        </div>
      </header>
    </div>
  );
};

export default Take5;
