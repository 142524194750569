import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./StabilityQ.css";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import { QuestionData } from "./data/QuestionData";
import Take5 from "../../../../components/Take5/Take5";
import backImg from "./img/lovethink_Q_back.png";

const StabilityQ = () => {
  const location = useLocation();
  const { name } = location.state || { name: "" };
  const [questionNo, setQuestionNo] = useState(0);
  const [styleScores, setStyleScores] = useState({
    안정형: 0,
    중립형: 0,
    불안형: 0,
  });
  const navigate = useNavigate();

  const handleClickButton = (type) => {
    setStyleScores((prevScores) => ({
      ...prevScores,
      [type]: prevScores[type] + 1,
    }));

    if (QuestionData.length !== questionNo + 1) {
      setQuestionNo(questionNo + 1);
    } else {
      const resultType = Object.keys(styleScores).reduce((a, b) =>
        styleScores[a] > styleScores[b] ? a : b
      );
      const resultStage = getResultStage(resultType, styleScores[resultType]);
      navigate(`/test/stability/result/${resultType}/${resultStage}`, {
        state: { name },
      });
    }
  };

  const getResultStage = (type, score) => {
    if (score >= 10) return getHighStage(type);
    if (score >= 7) return getMediumStage(type);
    return getLowStage(type);
  };

  const getHighStage = (type) => {
    if (type === "안정형") return "강철멘탈형";
    if (type === "중립형") return "균형잡힌중립형";
    return "조마조마형";
  };

  const getMediumStage = (type) => {
    if (type === "안정형") return "평온한바다형";
    if (type === "중립형") return "흔들리는중립형";
    return "안절부절형";
  };

  const getLowStage = (type) => {
    if (type === "안정형") return "잔잔한호수형";
    if (type === "중립형") return "위태로운중립형";
    return "불안폭발형";
  };

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [questionNo]);

  return (
    <div className="stability_Q_Wrapper">
      <Take5 />
      <Progress
        percent={(questionNo / QuestionData.length) * 100}
        status="error"
        theme={{
          error: {
            symbol: "⚠️",
            color: "#ffcc0057",
          },
        }}
      />
      <div className="stability_Q_BackImg">
        <img src={backImg} alt="rp_back" />
      </div>
      <div className="stability_Q_Contents">
        <div className="stability_Q_Title">
          {QuestionData[questionNo].title}
        </div>
        <div className="stability_Q_Btn_Container">
          {QuestionData[questionNo].options.map((option, index) => (
            <button
              key={index}
              className="stability_Q_Btn"
              onClick={() => handleClickButton(option.type)}
              type="button"
            >
              {option.text}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StabilityQ;
