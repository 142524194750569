import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { MoonLoader } from "react-spinners";
import Footer from "../../../../components/Footer/Footer";
import "./AlcatineR.css";
import DonutChart from "./DonutChart";
import Take5 from "../../../../components/Take5/Take5";
import CoupangBanner from "./CoupangBanner";
import Card from "../../Cardko";
import TestList from "../../TestListko.json";
import ShareButton from "./ShareButton";
import AImg from "./img/A.png";
import BImg from "./img/B.png";
import CImg from "./img/C.png";
import DImg from "./img/D.png";
import EImg from "./img/E.png";
import descBack from "./img/descBack.png";
import InnerCoupang from "../../InnerCoupang/InnerCoupang";

const AlcatineR = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showResult, setShowResult] = useState(false);
  const [hasCookie, setHasCookie] = useState(false);
  const [testList, setTestList] = useState([]);

  const excludedTestId = "alcatine";
  const filteredTestList = testList.filter(
    (test) => test.id !== excludedTestId
  );

  useEffect(() => {
    setTestList(TestList);
  }, []);

  useEffect(() => {
    setHasCookie(document.cookie.indexOf("coupang=true") !== -1);
  }, []);

  const handleClick = () => {
    setShowResult(true);
    handleResultShow();
  };

  const handleResultShow = () => {
    const resultContainer = document.querySelector(".alcatine_no_cookie");
    resultContainer.style.overflow = "visible";
    resultContainer.style.height = "100%";
  };

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3500);
  }, []);

  const result = location?.state?.result || {};

  const { alcoholScore, coffeeScore, tobaccoScore } = result;

  const averageScore = (alcoholScore + coffeeScore + tobaccoScore) / 3;

  let imageToShow = AImg;
  let alcatineResultDesc =
    "‣ 당신... 정체가 뭐야❓갓생러야 광인이야❓\n‣ 당신의 간을 용왕이 탐내고 있어요 🐢 \n ‣ 알코올 無, 카페인 無, 니코틴 無 삼무인간!\n ‣ 근데 삼무인간이 이 테스트는 왜...?🤔";

  if (averageScore >= 0 && averageScore < 20) {
    imageToShow = AImg;
    alcatineResultDesc =
      "‣ 당신... 정체가 뭐야❓갓생러야 광인이야❓\n‣ 당신의 간을 용왕이 탐내고 있어요 🐢 \n ‣ 알코올 無, 카페인 無, 니코틴 無 삼무인간!\n ‣ 근데 삼무인간이 이 테스트는 왜...?🤔";
  } else if (averageScore >= 20 && averageScore < 40) {
    imageToShow = BImg;
    alcatineResultDesc =
      "‣ 적당한 알카틴으로 활력 얻는 중🔥 \n ‣ 다 먹고 살자고 하는 일인데 \n이 정도는 괜찮잖아?👨🏻‍🎤 \n ‣ 운동까지 한다면 완-벽!🚴🏻‍♀️";
  } else if (averageScore >= 40 && averageScore < 60) {
    imageToShow = CImg;
    alcatineResultDesc =
      "‣ 퇴근 후 생맥🍻 한 잔은 보약 아님? \n ‣ 이게 담배연기 같니..? 이건 내 한숨이야..💨 \n ‣ 산다는 건 어느 정도 정줄을 놓는 것 😇\n ‣ 이것도 못하면 난 어찌 살라구 🤷🏻";
  } else if (averageScore >= 60 && averageScore < 80) {
    imageToShow = DImg;
    alcatineResultDesc =
      "‣ 인생이 쓰고 떫으니 술이 달구나...🍶 \n ‣ 쿡^-^... 넌 이런 거 하지마라 꼬맹아★ \n ‣ 내돈내산💸 반박시 님 말이 맞음✋🏻 \n ‣ 그치만 알카틴 없으면 현생이 힘든걸!😫";
  } else if (averageScore >= 80 && averageScore <= 100) {
    imageToShow = EImg;
    alcatineResultDesc =
      "( 의사가 퇴장했습니다 ) \n‣ 이제 진료 누가 봐주냐 \n ‣ 난 오늘만 산다. 내일을 사는 놈은... 힘내라! \n‣ 아! 술, 담배, 커피 사러 가야징☺️";
  }

  return (
    <>
      <div className="alcatine_loading_container">
        {loading ? (
          <p className="alcatine_loading_text">
            진 단 중 . . . 👨🏻‍⚕️
            <MoonLoader
              color={"#6fb065"}
              loading={loading}
              size={70}
              cssOverride={{
                marginTop: "3rem",
                marginBottom: "20rem",
                backgroundColor: "transparent",
              }}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </p>
        ) : (
          <div className="alcatine_R_container">
            <div>
              <Take5 />
              {hasCookie ? (
                <div className="alcatine_has_cookie">
                  <h2 className="alcatine_result_title">
                    당신의 중독 유형은...
                  </h2>
                  <div className="alcatine_img_container" id="coupangDiv">
                    <img src={imageToShow} alt="resultImg" />
                  </div>
                  <div className="alcatine_donutChart">
                    <DonutChart
                      percentage={alcoholScore}
                      radius={30}
                      color="#FF878A"
                    />
                    <DonutChart
                      percentage={coffeeScore}
                      radius={30}
                      color="#C08974"
                    />
                    <DonutChart
                      percentage={tobaccoScore}
                      radius={30}
                      color="#8bb992"
                    />
                  </div>
                  <div className="alcatine_icon">
                    <p>🍺 ☕️ 🚬</p>
                  </div>
                  <div className="alcatine_result_desc">
                    <img
                      className="alcatine_descBack"
                      src={descBack}
                      alt="desc"
                    />
                    <p className="alcatine_desc_p">{alcatineResultDesc}</p>
                  </div>
                  <div className="alcatine_IC_container">
                    <InnerCoupang />
                  </div>
                  <div className="alcatine_Btn_container">
                    <button
                      className="alcatine_R_Btn"
                      onClick={() => navigate("/test/alcatine")}
                    >
                      테스트 다시하기
                    </button>
                    <ShareButton />
                    <div className="TestList_container">
                      {filteredTestList.map((test) => (
                        <div key={test.id} className="TestList_container2">
                          <Link to={`/test/${test.id}`}>
                            <Card test={test} />
                          </Link>
                        </div>
                      ))}
                    </div>
                    <Footer />
                  </div>
                </div>
              ) : (
                <>
                  <div className="alcatine_no_cookie_container">
                    <div className="alcatine_no_cookie">
                      <h2 className="alcatine_result_title">
                        당신의 중독 유형은...
                      </h2>
                      <div className="alcatine_img_container" id="coupangDiv">
                        <img src={imageToShow} alt="resultImg" />
                      </div>
                      <div className="alcatine_coupang_banner">
                        <CoupangBanner handleClick={handleClick} />
                      </div>

                      {showResult && (
                        <>
                          <div className="alcatine_donutChart">
                            <DonutChart
                              percentage={alcoholScore}
                              radius={30}
                              color="#FF878A"
                            />
                            <DonutChart
                              percentage={coffeeScore}
                              radius={30}
                              color="#C08974"
                            />
                            <DonutChart
                              percentage={tobaccoScore}
                              radius={30}
                              color="#8bb992"
                            />
                          </div>
                          <div className="alcatine_icon">
                            <p>🍺 ☕️ 🚬</p>
                          </div>
                          <div className="alcatine_result_desc">
                            <img
                              className="alcatine_descBack"
                              src={descBack}
                              alt="desc"
                            />
                            <p className="alcatine_desc_p">
                              {alcatineResultDesc}
                            </p>
                          </div>
                          <div className="alcatine_IC_container">
                            <InnerCoupang />
                          </div>
                          <div className="alcatine_Btn_container">
                            <button
                              className="alcatine_R_Btn"
                              onClick={() => navigate("/test/alcatine")}
                            >
                              테스트 다시하기
                            </button>
                            <ShareButton />
                          </div>
                        </>
                      )}
                      <div className="TestList_container">
                        {filteredTestList.map((test) => (
                          <div key={test.id} className="TestList_container2">
                            <Link to={`/test/${test.id}`}>
                              <Card test={test} />
                            </Link>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default AlcatineR;
