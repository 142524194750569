import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./AlcatineMain.css";
import Take5 from "../../../../components/Take5/Take5";
import SEO from "../../SEOko";
import { alcatineAttributeko } from "../../seoAttributesko";
import alcatineMain from "./img/alcatineMain.png";
import AdSenseComponent from "../../../../AdSenseComponent";

const AlcatineMain = () => {
  const navigate = useNavigate();
  const handleClickButton = () => {
    navigate("/test/alcatine/question");
  };
  const id = "alcatine";

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <div className="alcatine_wapper">
        <div className="alcatine_contents">
          <SEO attribute={alcatineAttributeko} id={id} />
          <Take5 />
          <div className="alcatine_main_container">
            <div className="alcatine_mainImg_container">
              <img
                className="alcatineMain_img"
                src={alcatineMain}
                alt="alcatineMain"
              />
            </div>
            <button className="alcatine_start_btn" onClick={handleClickButton}>
              테스트 하기
            </button>
          </div>
        </div>
        <AdSenseComponent />
      </div>
    </>
  );
};

export default AlcatineMain;
