import ESTJ from "../img/ESTJ.png";
import ESFP from "../img/ESFP.png";
import ENTJ from "../img/ENTJ.png";
import INTP from "../img/INTP.png";
import INTJ from "../img/INTJ.png";
import ISTJ from "../img/ISTJ.png";
import ESTP from "../img/ESTP.png";
import INFP from "../img/INFP.png";
import ISFP from "../img/ISFP.png";
import ISFJ from "../img/ISFJ.png";
import ENFP from "../img/ENFP.png";
import INFJ from "../img/INFJ.png";
import ESFJ from "../img/ESFJ.png";
import ENTP from "../img/ENTP.png";
import ISTP from "../img/ISTP.png";
import ENFJ from "../img/ENFJ.png";

export const ResultData = [
  {
    id: 1,
    name: "극한의 효율을 자랑하는",
    subname: "팀 리더",
    best: "ESTJ",
    desc: "#추진력 갑",
    desc1: "#리더십",
    desc2: "#철저한 자기관리",
    detail:
      "✦ 좀비대책팀을 만든다면 실질적 리더를 맡을 사람 \n ✦ 먼 미래보다 현재를 중요시하므로 실현가능한 목표를 세움 \n ✦ 현실적이고 냉철함 \n ✦ 자기 자신한테 엄격함 \n ✦ 호불호가 확실함 \n ✦ 고집 세고 직설적 \n ✦ 무질서 극혐함 \n ✦ 갈등과 경쟁을 좋아하진 않지만 해야 한다면? 오직 승리뿐. \n ✦ 답장 하나 보내도 맞춤법 검사함 \n ✦ 여유로우면 왠지 불안함 \n ✦ 친해지면 물음표살인마 됨. 하지만 그게 다 관심인걸...? \n ✦ 불필요한 인간관계 굳이 만들지 않음 \n ✦ 멀티 겁나 잘함 \n ✦ 본인 mbti에 대만족 \n ✦ 망상따위 하지 않음. 눈 감자마자 잠",
    detail1:
      "- 최단시간, 고효율! \n - 성과에 대한 칭찬 \n - 밀당보다 솔직하게 감정표현 해주는 것",
    detail2: "- 책임감 없는 것 \n - 시간 낭비하는 것 \n - 비효율적인 것 ",
    image: `${ESTJ}`,
  },
  {
    id: 2,
    name: "좀비도 팬이 되는",
    subname: "타고난 연예인",
    best: "ESFP",
    desc: "#친화력 갑",
    desc1: "#센스만점",
    desc2: "#사교왕",
    detail:
      "✦ 그곳이 어디든 전부 나의 무대! \n ✦ 좀비도 팬이 될 정도로 친화력이 좋음  \n ✦ 낙천적이고 순수함 \n ✦ 말괄량이같지만 사실 남 걱정하느라 정작 본인을 잘 못 챙김 \n ✦ 수다쟁이. 같은 말 반복 잘함 \n ✦ 은은하게 돌아있는 눈으로 항상 웃고 있음 \n ✦ 침묵 흐르는 거 견디기 힘들어 함 \n ✦ 솔직하고 밝음 \n ✦ 감정 캐치 잘 함 \n ✦ 자존감 높고 자격지심 없음 \n ✦ 개그욕심 있음ㅋㅋ \n ✦ 잡다하게 아는 거 많음 \n ✦ 항상 해맑아보이지만 진지할 땐 아주 진지함 \n ✦ 순간의 행복을 즐길 줄 앎 \n ✦ 걱정, 고민이 있어도 거기에 잠식되지 않음",
    detail1:
      "- 친구 만나는 거!!!! \n - 즉 흥 조 아 💃🏻 \n - 상대방 리액션 큰 거 환장함 \n - 티키타카 잘 되는 것",
    detail2:
      "- 뜬구름 잡는 얘기 \n - 나 귀찮아 하는 거... ㅠ(상처) \n - 무심하고 무뚝뚝한 것",
    image: `${ESFP}`,
  },
  {
    id: 3,
    name: "좀비세상에서도 성공할",
    subname: "n잡러 사장님",
    best: "ENTJ",
    desc: "#생산적",
    desc1: "#완벽주의",
    desc2: "#일벌리기 장인",
    detail:
      "✦ 무의미한 시간 싫어함. 하루라도 뭘 안 하면 가시 돋음 \n ✦ 새로운 것에 도전하고 노력하는 걸 좋아함 \n ✦ 도전 자체에 매력을 느낌 \n ✦ 세상이 망해가는 중에도 귀신같이 사업아이템 찾아서 성공할 사람임 \n ✦ 어떻게 하면 좀비도 아이템 or 직원으로 굴릴 수 있을지 고민할 사람 \n ✦ 일처리 확실히 해야 함 \n ✦ 완벽주의자라 인생이 피곤함 \n ✦ 자기애 개쩜 \n ✦ 무언가에 열중하고 해내고야 마는 내 모습에 취함... 치얼스🍷 \n ✦ 사람 많을 땐 프로페셔널, 혼자 있을 땐 몽상가 \n ✦ 세상에 믿을 건 나 하나뿐임 \n ✦ 감정을 책으로 배움 \n ✦ 내 탓과 남 탓을 번갈아서 함. 난 왜 이러지? 쟨 왜 저러지? \n ✦ 책임감 있음 \n ✦ 이해타산적임. 뭘 하기 전에 자신한테 이득이 되는 일인지 따져봄",
    detail1:
      "- 일 잘하는 사람 \n - 최고의 효율 \n - 자 기 계 발 좋 아🧑🏻‍💻 \n - 깊고 의미있는 대화 \n - 토론",
    detail2:
      "- 무능력한데 나보고 이래라저래라 하는 사람 \n - 비논리, 비효율적인 것 \n - 시간 낭비하는 것",
    image: `${ENTJ}`,
  },
  {
    id: 4,
    name: "가장 따뜻하고 창의적인",
    subname: "인간형 로봇",
    best: "INTP",
    desc: "#자발적 아싸",
    desc1: "#창의력 갑",
    desc2: "#귀차니즘",
    detail:
      "✦ 데이터 수집 후 사회화 한 감정을 표현함 \n ✦ 따뜻한 로봇이라고 생각하면 이들이 이해됨 \n ✦ 귀차니즘 있음. 그러나 이유가 있는 귀차니즘임 \n ✦ 평소에 연락 잘 안함 \n ✦ 감정기복 별로 없음 \n ✦ 뭐 하나 시작하면 끝을 봐야 함 \n ✦ 서론 긴 거 싫어함. 바로 본론으로 넘어가주라... \n ✦ 팩폭 하지말라는 소리 자주 들음 \n ✦ 좋아하는 분야는 겁나 열심히 함 \n ✦ 남들 신경 안 쓰는 오타쿠 기질이 있음 \n ✦ 천재 소리 들으면 입꼬리 올라감 \n ✦ 신박한 말장난 잘 침 \n ✦ 갑자기 혼자 빵 터짐 \n ✦ 의식의 흐름 따라잡을 수 없음 \n ✦ 아무것도 안 하면서 일단 바쁘다고 함 \n ✦ 모르는 척 잘 함 (못 들은 척... 못 본 척...) \n ✦ 통찰력 좋아서 촌철살인 잘함 \n ✦ 평판에 휘둘리지 않음",
    detail1:
      "- 내 관심 분야 \n - 나에 대한 이야기 \n - 밤새 덕질하기 \n - 망상하기 \n - 책",
    detail2:
      "- 내가 모자란 거 티날 때 \n - 쓸데없는 말 대잔치 사이에 껴있는 것 \n - 무식한데 무논리한 말로 큰 소리치는 사람 \n - 여러 번 물어보는 것 \n - 사랑범벅 영화",
    image: `${INTP}`,
  },
  {
    id: 5,
    name: "가장 차갑고 시니컬한",
    subname: "로봇형 인간",
    best: "INTJ",
    desc: "#개인주의",
    desc1: "#팩트폭격기",
    desc2: "#계획덕후",
    detail:
      "✦ 고철로 만든 인간이라고 생각하면 이들이 이해됨 \n ✦ mbti 유형 중에서 가장 독립적임 \n ✦ 합리와 논리, 효율을 중시함 \n ✦ 완벽주의가 있어서 사는 게 피곤함 \n ✦ 사회 통념에 얽매이지 않음 \n ✦ 무슨 생각을 하고 있는지 파악이 어려움 \n ✦ 무표정으로 팩트폭격 잘함. 싸가지없다는 소리 들음 \n ✦ 당일 약속 싫어함. 휴식도 계획의 일부임 \n ✦ 표정이 디폴트로 화나있지만 사실 딴 생각중 \n ✦ 기본적으로 인간을 싫어함. 동물 앞에서는 혀 짧아짐 \n ✦ 스스로 성격 이상하다는 생각 자주 함 \n ✦ 계획 덕후. 계획을 짜기 위한 계획을 세움 \n ✦ 남들이 관심없어하는 주제에 흥미 폭발함 \n ✦ 남한테 진짜 관심 없음 \n ✦ 찔러도 피 한 방울 안 나올 것 같다는 소리 자주 들음 \n ✦ 해결책 잘 냄 (aka.마법의 소라고동 \n ✦ 허무주의 성향 있음 \n ✦ 분석 잘함 \n ✦ 현실적이면서도 몽상가임 \n ✦ 진리를 갈구함",
    detail1:
      "- 계 획 좋 아 \n - 동물 친구친구들 \n - 무채색 \n - 다큐멘터리 \n - 영화 보고 다양한 해석 읽기 \n - 인문, 철학, 예술을 주제로 토론하기",
    detail2:
      "- 약속 안 지키는 것 \n - 계획 어그러지는 것 \n - 시끄럽고 사람 많은 장소 \n - 잘못된 정보 \n - 보수적이고 비효율적인 시스템",
    image: `${INTJ}`,
  },
  {
    id: 6,
    name: "좀비세상을 왕따 시키는",
    subname: "동굴 속 현자",
    best: "ISTJ",
    desc: "#고독한늑대",
    desc1: "#책임감",
    desc2: "#원리원칙",
    detail:
      "✦ 좀비대책팀이 회의할 때 뒤에서 가만히 있다가 \n 한마디 툭 던지는데 촌철살인일st \n ✦ 차갑고 과묵해 보이지만 의외로 호탕하고 재밌음 \n ✦ 좁고 깊은 인간관계 선호함 \n ✦ 세상을 왕따 시킴 \n ✦ 자기 바운더리 안에 있는 사람한테 책임감이 크고 헌신적임 \n ✦ tmi 듣기 힘들어 함. 뭐라고 반응해야 할지 모르겠음 \n ✦ 원리원칙 중요하게 생각함 \n ✦ 딴 길로 새는 거 싫어함 \n ✦ 인간인지 기계인지 모를 정도로 성실함 \n ✦ 스스로 발전하려고 노력하고, 스스로에게 엄격함 \n ✦ 본인만의 루틴이 있음 \n ✦ 좋아하는 것에 돈을 아낌없이 씀 \n ✦ 칭찬 잘 못함. 뚝딱거림 \n ✦ 책임감이 강해서 혼자 스트레스 받음 \n ✦ 인간관계에 대한 벽이 높지만 친해지면 말 많아짐 \n ✦ 인간관계 손절 잘 함 \n ✦ 가는 사람 안 붙잡고 오는 사람 안 막음 \n ✦ 절대 선 넘지 않음. 뒷말도 안 함 \n ✦ 내 얘기 하는 것도 싫고 남 얘기 들어주는 것도 힘들어 함 \n ✦ 무언가를 꾸준히 하는 것에 스트레스 잘 안 받음 \n ✦ 주변 사람들에게 안정감을 주는 사람 ",
    detail1:
      "- 배울 점 많은 사람 \n - 나한테 시간을 쓰는 사람 \n - 예의 바른 사람 \n - 실용적이고 마침 딱 필요했던 물건 \n - 상대가 진심을 솔직하게 내보이는 것",
    detail2:
      "- 내가 실수하는 것 \n - 잘못한 것에 정확한 사과없이 구렁이 담 넘듯 넘어가는 사람 \n - 약속 안 지키는 사람 \n - 준법정신 없는 사람",
    image: `${ISTJ}`,
  },
  {
    id: 7,
    name: "좀비도 눈 깔고\n지나갈 거 같은",
    subname: "기존쎄 두목님",
    best: "ESTP",
    desc: "#쿨워터향",
    desc1: "#노는게제일좋아",
    desc2: "#모험가",
    detail:
      '✦ 좀비도 말빨로 울릴 st 기존쎄 그 자체 \n ✦ 스트레스 잘 안 받고, 눈치 안 봄 \n ✦ 넘쳐나는 쿨내... 누가 뭐래도 ㅇㅋ~하고 신경 안 씀 \n ✦ 리더십 있어서 모임에서 정신차려보면 내가 주도하고 있음 \n ✦ 어떤 환경이든 적응 잘함 \n ✦ 삶을 즐길 줄 앎 \n ✦ 승부욕 강함 \n ✦ 말투 직설적임 \n ✦ 틀에 박힌 일 싫어함 \n ✦ 상황 판단 빠름 \n ✦ 자기애, 자신감 넘침 \n ✦ 자기 주관이 뚜렷함. 할말 다 해야 함 \n ✦ 안정보다 모험, 도전 \n ✦ 활력 넘쳐서 스릴과 재미를 추구함 \n ✦ 꾸미는 거 좋아함 \n ✦ 사실, 팩트에 중점을 둠 \n ✦ 편견 없는 편 \n ✦ 성취욕 강함 \n ✦ 말빨이 센데 "내가 맞으니까 당연히 내가 이겨야 한다"는 생각이 기본 탑재 \n ✦ 남한테 관심없음ㅎ \n ✦ 관심있는 거에는 집중력 수직상승 \n ✦ "이해는 되는데 공감은 안 가" 말버릇 \n ✦ 드라마 끈덕지게 정주행? 절대 못함 \n ✦ 플레이리스트에 신나는 노래밖에 없음',
    detail1:
      '- "못하는 게 뭐야?"같은 칭찬 \n - 깜짝 이벤트 \n - 기념일 \n - 프로페셔널한 사람 \n - 노는 거 \n - 관심받는 거 ',
    detail2:
      "- 답답한 거 \n - 우물쭈물 거리는 거 \n - 돌려 말하는 거 \n - 지는 거 \n - 앞뒤 다른 사람 \n - 집착하는 거",
    image: `${ESTP}`,
  },
  {
    id: 8,
    name: "좀비세상에도 낭만이 있다",
    subname: "마지막 로맨티스트",
    best: "INFP",
    desc: "#낭만파",
    desc1: "#내머릿속에우주",
    desc2: "#평화주의자",
    detail:
      "✦ 낭만을 즐길 줄 아는 사람들임 \n ✦ 자신만의 감성이 있음 \n ✦ 음악, 미술, 영화, 사진처럼 보여지는 것에 관심있음 \n ✦ 속앓이 자주 함 \n ✦ 조용한 관종이라 사람들 앞에 서면 기 빨리는데 내심 좋음 \n ✦ 인간관계 맺는 것 자체가 피곤함 \n ✦ 극단적으로 잠수탈 때 있음 \n ✦ 별 거 아닌 거에 의미부여 잘 함 \n ✦ 타인에게나 자신에게나 순수하게, 진심으로 대함 \n ✦ 멀티 안 됨 \n ✦ 가끔 우울한 나에게 심취함 \n ✦ 개인적인 이야기 남들한테 하는 거 싫어함 \n ✦ 화나거나 속상할 때 혼자 있어야 함 \n ✦ 혼자 있는 건 좋아하는데 외로운 건 싫음 \n ✦ 폐 끼치는 거 싫어함 \n ✦ 사람 사귈 때 따지는 거 많음  \n ✦ 감성글 보면 기분 몽글거림 \n ✦ 생각 진~~~~짜 많음 \n ✦ 기본으로 망상 모드. 머릿속에 우주 펼쳐짐 \n ✦ 낯 많이 가림 \n ✦ 평화주의자 \n ✦ 작은 것에도 행복을 느낌 \n ✦ 반복되는 일에 인내심 없음 \n ✦ 남 눈치 많이 보는 편 \n ✦ 자아성찰 오지게 함 \n ✦ 생각은 많은데 실행에 옮기는 게 어려움 \n ✦ 분위기에 취하는 편",
    detail1:
      "- 귀여운 거 \n - 집에서 혼자 취미생활 하기 \n - 좋아하는 사람들한테 선물해주기 \n - 공감해주고 안아주고 웃겨주는 거 세상 좋아함 \n - 과도한 관심X 지속적이고 은근한 관심O \n - 부둥부둥 해주는 거",
    detail2:
      "- 싫다는데 계속 강요하는 거 \n - 싸움, 다툼, 갈등상황 \n -  간섭받는 거 \n - 지나친 팩트폭행",
    image: `${INFP}`,
  },
  {
    id: 9,
    name: "좀비가 눈치도 못 채,",
    subname: "누워있기 장인",
    best: "ISFP",
    desc: "#귀차니즘",
    desc1: "#모순덩어리",
    desc2: "#침대는내반쪽",
    detail:
      '✦ 하루종일 누워있음. 신생아 수준임 (응애 \n ✦ 좀비한테 물려도 "결국 이렇게 되는군ㅎ"하고 초연해 할 st \n ✦ 귀여운 모순덩어리. 연락하는 거 귀찮은데 안 오면 섭섭하고... \n 흥이 많은데 조용하고... 소극적이지만 관종임 \n ✦ 조용히 눈에 띄고 싶어함 \n ✦ 갈등, 불화 싫어함 \n ✦ 남한테 속마음 얘기 잘 안 함 \n ✦ 이 정도면 잘했다~하고 뿌듯해서 누워있음 \n ✦ 인간미있어서 보고 있으면 귀여움 \n ✦ 일을 미룰 수 있을 때까지 미룸. 근데 신기하게 기간까지 다 끝냄 \n ✦ 구속, 제약 받는 거 싫어함 \n ✦ 다들 착하다고 하는데 나는 아니라고 생각함 \n ✦ 싫은 소리, 거절 잘 못함 \n ✦ 소리 소문없이 손절함 \n ✦ 좁고 깊은 인간관계 선호함 \n ✦ 자기만의 영역이 존중받기를 원함 \n ✦ 안읽씹 장인 \n ✦ 신중한 성격이라 사소한 것도 고민함 \n ✦ 약속 취소되면 기뻐함 (막상 나가면 잘 놀면서...) \n ✦ 어지간하면 그러려니 하고 사는 편',
    detail1:
      "- 디테일한 칭찬 \n - 리액션 잘 해주는 사람 \n - 귀여운 거 \n - 혼자만의 시간",
    detail2: "- 무례한 사람, 예의 없는 사람 \n - 간섭, 구속, 제약",
    image: `${ISFP}`,
  },
  {
    id: 10,
    name: "두뇌풀가동! \n 시뮬레이션 돌리기 장인",
    subname: "게으른 제갈공명",
    best: "ISFJ",
    desc: "#겉따속차",
    desc1: "#소확행",
    desc2: "#눈치백단",
    detail:
      "✦ 생각 엄~~~청 많음 \n ✦ 사람 싫어하지만 안 싫어함 \n ✦ 남이 볼 땐 인생 노잼처럼 보이지만 본인은 만족 \n ✦ 인간관계에 스트레스 많이 받음 \n ✦ 인내심, 끈기 쩖 \n ✦ 세심하고 꼼꼼함 \n ✦ 기억력이 좋음 \n ✦ 그래서 혼자 기억하고 있다가 누가 얘기 꺼내면 자연스럽게 언급함 \n ✦ 잔잔바리로 웃김 \n ✦ 효율적이고 합리적인 거 좋아함 \n ✦ 소확행 좋아함 \n ✦ 말 한 마디, 행동 하나에 의미부여 잘함 \n ✦ 그래서 다같이 있을 때 사람들 표정 보면서 이런저런 생각함 \n ✦ 사람 파악을 잘 함 \n ✦ 눈치가 빨라서 일부러 눈치 없는 척 함 \n ✦ 실수했던 게 자꾸 떠오름 \n ✦ 모험하고 도전하는 거 별로 안 좋아함 \n ✦ 친한 사람들 앞에서는 그야말로 애교쟁이 \n ✦ 친해지면 또라이같다는 소리 들음 \n ✦ 추억으로 살아감 \n ✦ 인싸 중에 아싸, 아싸 중에 인싸 \n ✦ 나도 나를 모르겠음... \n ✦ 머릿속으로 시뮬레이션 돌리는 게 취미 \n ✦ 상대 성격에 따라 맞춰서 행동함 \n ✦ 기분에 맞는 분위기 있는 노래 듣는 거 좋아함",
    detail1:
      "- 배울 점이 많은 사람 \n - 유머러스한 사람 \n - 나만의 시간 \n - 작은 것도 기억해줄 때",
    detail2:
      "- 예의 밥말아먹은 사람 \n - 기분이 태도가 되는 사람 \n - 거짓말하는 거",
    image: `${ISFJ}`,
  },
  {
    id: 11,
    name: "좀비 무찌르러 갔다가 \n 짱친 먹고 오는",
    subname: "톡톡 튀는 슈팅스타",
    best: "ENFP",
    desc: "#분위기메이커",
    desc1: "#금사빠",
    desc2: "#즐겁게살자",
    detail:
      '✦ 공감능력 만렙임. "팔 없는 좀비가?" "슬퍼..." \n ✦ 분위기 메이커 \n ✦ 인싸 중의 아싸, 아싸 중의 인싸 \n ✦ 감정 기복 심함 \n ✦ 낯 좀 가리지만 금방 친해짐 \n ✦ 사람에 대한 기대 <-> 실망 무한 루프 \n ✦ 어떤 자리든, 누구와 함께든 에너지 폭발 가능 \n ✦ 일상에서 자주 벅차오름 \n ✦ 즐거움과 행복이 우선! \n ✦ 호기심이 많아서 도전 잘 하는데 마무리가 엉성한 편 \n ✦ 눈물이 많음 \n ✦ 공상 잘 함 \n ✦ 관심 받는 거 엄청 좋아함 \n ✦ 고민 하나도 없어보이는데 알고보면 내면이 복잡함 \n ✦ 무계획이 계획이다 \n ✦ 금사빠, 금사식 \n ✦ 일어나지 않을 일 걱정하는 게 취미 \n ✦ 충동구매 잘함 \n ✦ 이론보다 실전 \n ✦ 주변에 사람 진짜 많아보이지만 찐친이라고 생각하는 사람 몇 명 없음',
    detail1:
      "- 사진 찍고 추억 남기기 \n - 사랑받고 있다고 느끼는 것 \n - 심도 깊은 얘기하는 것 \n - 주목받는 것",
    detail2:
      "- 다른 사람 잘못인데 친구가 내 편 안 들어주는 것 \n - 드립쳤는데 갑분싸되는 거",
    image: `${ENFP}`,
  },
  {
    id: 12,
    name: "내 안에 내가 몇 갠지 \n 나도 몰라",
    subname: "50가지 얼굴 다중이",
    best: "INFJ",
    desc: "#다중인격",
    desc1: "#감수성",
    desc2: "#완벽주의",
    detail:
      "✦ 자아가 여러개임. 친구따라 성격 바꿈 \n ✦ 내면의 자아들이랑 토론함 \n ✦ 남을 꿰뚫어 보는 능력이 뛰어남 \n ✦ 사람들 관계성이 눈에 다 보이는 게 피곤함 \n ✦ 겉모습과 속이 매우 다름 \n ✦ 까도 까도 새로운 모습이 나오는 양파같은 사람 \n ✦ 상황에 따라 천사가 되거나 악마가 될 수도 있음 \n ✦ 감정도 계획함. 그에 따라 맞는 페르소나 장착 \n ✦ 눈치가 빠름 \n ✦ 감수성이 풍부함 \n ✦ 폐 끼치는 걸 극도로 싫어함 \n ✦ 이상향과 도덕적 관념이 높음 \n ✦ 인류애 없음 \n ✦ 인류애 없는 자신의 모습이 싫어서 사랑하려고 노력함 \n ✦ 갑자기 자아성찰함 \n ✦ 섬세하고 감성적인데 지적이고 냉철한 면도 있음 \n ✦ 소소한 취미를 즐김 \n ✦ 책, 영양가 있는 대화 좋아함 \n ✦ 집에 몰카 있을 거 같다는 망상함 \n ✦ 의미없는 스몰토크 싫어함. 기빨림 \n ✦ 집가면 성격 바뀜. 방구석 여포st \n ✦ 나도 나를 모르겠음ㄹㅇ \n ✦ 영혼 없다는 말 많이 들음 \n ✦ 음악, 예술, 미술 같은 문화에 관심 많음 \n ✦ 약속 전부터 동선 짜고 가게 메뉴판 정독하고 입을 옷도 미리 정해둠",
    detail1:
      "- 자연 속에서 산책하기 \n - 동물 \n - 분위기 좋은 카페에서 책 읽는 거 \n - 빈티지 소품",
    detail2:
      "- 거짓말 하는 거 \n - 무슨 생각을 그렇게 많이 하냐고 꼽주는 거 \n - 비도덕적인 사람 보면 정 떨어짐",
    image: `${INFJ}`,
  },
  {
    id: 13,
    name: "세상이 좀비로 들끓어도 \n 멈출 수 없어",
    subname: "취미재벌",
    best: "ESFJ",
    desc: "#분위기주도형",
    desc1: "#취미만백개",
    desc2: "#리액션봇",
    detail:
      "✦ 술에 술 탄듯, 물에 물 탄듯 사는 편 \n ✦ 세상에 재밌는 게 너무 많음 \n ✦ 취미 재벌임. 뭐 만들어서 나눠주는 거 좋아함 \n ✦ 한번 열리면 닫힐 줄 모르는 입 \n ✦ 리액션이 멈추지 않음 \n ✦ 다른 사람 신경 많이 씀 \n ✦ 말 많은데 나보다 더 말 많은 사람 있으면 조용해짐 \n ✦ 인간관계 틀어지면 스트레스 오지게 받음 \n ✦ 새로운 사람 만나는 거 좋아함 \n ✦ 눈치가 빨라서 사람 감정변화 잘 파악함 \n ✦ 친한 사람들 잘 챙기고 배려하지만, 정작 자기한테 무슨 일 있으면 속앓이 함 \n ✦ 남한테 피해주는 거 엄청 싫어함 \n ✦ 역할분담 장인 \n ✦ 유행하는 거 다 알아야 함 \n ✦ 중재 잘함. 친구들 사이에서 상담사 역할",
    detail1:
      '- 정성이 담긴 선물! (손편지 받으면 눈물ㅠ \n - 내가 배려한 만큼 날 배려해주는 사람 \n - "그때 주신 거 잘 쓰고 있어요~", "그거 정말 맛있었어요!" 소리 들으면 기절함 \n - 조화로운 분위기',
    detail2:
      "- 인간관계 수틀리는 것 \n - 소외감 느끼는 것 \n - 오해, 억울한 일",
    image: `${ESFJ}`,
  },
  {
    id: 14,
    name: "말빨로 좀비 울리는",
    subname: "본투비 파이터",
    best: "ENTP",
    desc: "#아가리파이터",
    desc1: "#그건우리의잘못이아니라",
    desc2: "#니잘못일거야",
    detail:
      '✦ "넌 내 생각 못 바꿔. 네가 생각을 바꿔 와."라는 생각이 기본 탑재 \n ✦ 어휘력이 좋음 \n ✦ 두뇌회전이 빨라서 사고 전환이 빠름 \n ✦ 착하다는 말보다 또라이라는 말을 더 좋아함 \n ✦ 자기애, 자존감 높음 \n ✦ 나르시시즘 있음ㅎ \n ✦ 자기 중심적임 \n ✦ 상대방이 울 때 드라이기로 눈물 말려줄 인간임 \n ✦ 토론이나 논쟁할 때 내 의견으로 끝나야 속시원함 \n ✦ 지나간 일에 후회 안 함 \n ✦ 과정보다 결과가 중요함 \n ✦ 잘못된 건 짚고 넘어가야 함 \n ✦ 뒷심이 좀 부족함 \n ✦ 뭐 하나에 꽂히면 그것만 함. 질리면 아예 안 함 \n ✦ 꼰대 퇴치 잘함 \n ✦ 호불호 확실함 \n ✦ 사회성 좋음 \n ✦ 거짓말 잘 하는 편임 \n ✦ 논리적으로 납득 안 되면 공감 못 함 \n ✦ 잡학다식함 \n ✦ 장난 잘 치는 편임 \n ✦ 남들한테 조언도 잘 안 구함. 어차피 내 마음대로 할 거니까ㅎ \n ✦ 야망, 목표의식 있음 \n ✦ 본인 mbti에 백퍼센트 만족함',
    detail1:
      "- 좋아하는 친구한테 장난치기 \n - 리액션 좋은 사람 \n - 운동 \n - 티키타카 잘 되는 대화",
    detail2:
      '- 애인 이야기로 징징대는 거 \n - 무시당하는 거 \n - 선 넘는 거 \n - "떼잉~ 어디서 말 대꾸야?" \n - 주입식 교육',
    image: `${ENTP}`,
  },
  {
    id: 15,
    name: "쿨내가 진동하는",
    subname: "인간 이클립스",
    best: "ISTP",
    desc: "#청개구리",
    desc1: "#영혼리스",
    desc2: "#COOL",
    detail:
      "✦ 뒤끝 없음. 쿨함 \n ✦ 만사 귀찮아 함 \n ✦ 귀찮음은 많은데 뒤쳐지는 건 싫어함 \n ✦ 흠... 글쿤... 근데? 그래서? 굳이? 없으면 대화 불가 \n ✦ 영혼 없다는 소리 자주 들음 \n ✦ 독립적임 \n ✦ 가만히 있으면 주변에서 기분 안 좋냐고 물어봄 \n ✦ 혼자만의 문화생활 즐기는 거 좋아함 \n ✦ 관종이라 관심받는 건 좋은데 시끄러운 건 싫음 \n ✦ 청개구리 같은 면이 있음 \n ✦ 누가 잔소리 하면 하려던 것도 다 때려침 \n ✦ 죽기 살기로 무언가를 하는 것보다 적당히 살고 싶음 \n ✦ 누가 나 싫어해도 별 생각 없음. 어쩔티비 \n ✦ 늘 은은하게 빡쳐있음 \n ✦ 화나면 차게 식음 \n ✦ 효율성 추구함 \n ✦ 밥이랑 반찬 비율 생각해서 먹음 \n ✦ 감정을 공감하는 게 아니라 머리로 이해함 \n ✦ 집순이, 집돌이들이 많음 \n ✦ 활발한 척 하다가 갑자기 현타옴 \n ✦ 오는 사람 안 막고 가는 사람 안 잡음 \n ✦ 슬픔을 나누면 반이 아니라 슬픈 사람이 두 명된다고 생각함 \n ✦ 그래서 본인에게 힘든 일이 있어도 굳이 말 안 함 \n ✦ 내심 자기가 웃기다고 생각함 \n ✦ 먼저 연락 잘 안 함 \n ✦ 태어난 김에 사는 사람 같음",
    detail1:
      "- 혼자서 할 수 있는 취미 \n - 받은만큼 돌려주는 사람 \n - 귀여운 동물칭구들",
    detail2:
      "- 카톡 재촉하는 거 \n - 누가 간섭하는 거 \n - 시끄러운 사람 \n - 억지로 리액션 해야 하는 상황",
    image: `${ISTP}`,
  },
  {
    id: 16,
    name: "겉은 강아지, 속은 고양이같은",
    subname: "매력쟁이",
    best: "ENFJ",
    desc: "#사랑둥이",
    desc1: "#오지라퍼",
    desc2: "#인류애만렙",
    detail:
      "✦ 대놓고 인싸는 아닌데 어딜가나 존재함 \n ✦ 무리에 속해있는 것도 좋아하는데, 마이웨이 기질도 있음 \n ✦ 주위에 사람은 많은데 찐친으로 생각하는 사람 몇 없음 \n ✦ 생각이 깊고 다방면으로 입장을 이해할 줄 앎 \n ✦ 인류애 넉넉함. 가끔 바닥나도 사소한 걸로 차오름 \n ✦ 남 잘 돕고 따뜻한 말 잘 해줌 \n ✦ 상대의 말투, 뉘앙스에 민감한 편 \n ✦ 행복회로 잘 돌림 \n ✦ 겉은 강아지, 속은 고양이 같음 \n ✦ 다정하고 관대함 \n ✦ 성숙한 면이 있음 \n ✦ 공감능력이 뛰어나서 오지랖 넘침 \n ✦ 사려깊고 믿음직스러움 \n ✦ 내 문제보다 친구 문제에 더 진심임 \n ✦ 남들 신경쓰고 잘 해주는데 그만큼 상처도 잘 받음 \n ✦ 따뜻한 카리스마 \n ✦ 사랑받기 위해 노력함 \n ✦ 의리있고 우직함 \n ✦ 언변이 화려함 \n ✦ 정이 많아서 관계 맺고 끊는 걸 어려워 함 \n ✦ 진실되고 우직함",
    detail1:
      "- 얼굴 마주보고 소통하는 것 \n - 직접 만든 선물 \n - 진심 가득한 애정표현",
    detail2:
      "- 소외되는 것 \n - 이기적이고 무관심한 사람 \n - 강약약강 \n - 배려를 당연하게 여기는 것",
    image: `${ENFJ}`,
  },
];
