export const QuestionData = [
  {
    id: 1,
    title: "What's my role among friends?",
    answera: "I'm the chatty one.",
    answerb: "I mainly listen.",
    type: "EI",
  },
  {
    id: 2,
    title:
      "You're hanging out with a friend, and your friend says, 'Can I invite my friend too?' Your response?",
    answera: "Sure, your friend is my friend too! Invite them!",
    answerb: "Haha, let's hang out another time...!",
    type: "EI",
  },
  {
    id: 3,
    title:
      "You're heading home, and you realize that you're going in the same direction as an acquaintance you don't know well. What do you do?",
    answera: "It's okay to go together.",
    answerb: "Honestly, I'd prefer to go alone.",
    type: "EI",
  },
  {
    id: 4,
    title: "What kind of person am I?",
    answera:
      "I'm good at explaining things, but I find it difficult to come up with ideas in my head.",
    answerb:
      "I know things well in my head, but it's challenging to explain them verbally.",
    type: "SN",
  },
  {
    id: 5,
    title: "When assessing people, I...",
    answera: "Judge based on first impressions and feelings.",
    answerb: "Have my own criteria for evaluating people.",
    type: "SN",
  },
  {
    id: 6,
    title: `If a friend says, 'Why do you think we were born?' I would...`,
    answera:
      "Well, our parents gave birth to us. Why? Is there something going on?",
    answerb: "Let's discuss the meaning of human existence.",
    type: "SN",
  },
  {
    id: 7,
    title: `If a friend asks, "Do I look a bit bloated today?" I would...`,
    answera: `Yeah, you look a bit bloated. What did you eat yesterday?`,
    answerb: `Bloated? You look the same!!`,
    type: "TF",
  },
  {
    id: 8,
    title:
      "When a friend complains about something annoying, my inner thoughts are...",
    answera: `Oh... I see. I guess that's what annoys you about them.`,
    answerb: `(I'm too busy listening and reacting to have any inner thoughts)`,
    type: "TF",
  },
  {
    id: 9,
    title: `If a friend says, "I used dry shampoo \nbecause I overslept this morning lol," I would...`,
    answera: "Oh, which brand did you use? Did it make your hair all fluffy?",
    answerb:
      "Well, you did a good job using it lol. What were you doing that made you oversleep?",
    type: "TF",
  },
  {
    id: 10,
    title:
      "If a friend suddenly calls and says, 'Come out right now!' I would...",
    answera: "Suddenly? (Not a fan of impromptu plans)",
    answerb: "Haha, where are you? (Impromptu plans sound fun!)",
    type: "JP",
  },
  {
    id: 11,
    title:
      "A friend is coming over to my house tomorrow. In this situation, I...",
    answera: "I'll clean in advance and think about what to eat.",
    answerb: "I'll decide everything when my friend arrives.",
    type: "JP",
  },
  {
    id: 12,
    title: "What kind of person am I?",
    answera: "I don't judge hastily.",
    answerb: "I want to make quick judgments.",
    type: "JP",
  },
];
