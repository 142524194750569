import catQ1 from "../img/catQ1.png";
import catQ2 from "../img/catQ2.png";
import catQ3 from "../img/catQ3.png";
import catQ4 from "../img/catQ4.png";
import catQ5 from "../img/catQ5.png";
import catQ6 from "../img/catQ6.png";
import catQ7 from "../img/catQ7.png";
import catQ8 from "../img/catQ8.png";
import catQ9 from "../img/catQ9.png";
import catQ10 from "../img/catQ10.png";
import catQ11 from "../img/catQ11.png";
import catQ12 from "../img/catQ12.png";
import catQ13 from "../img/catQ13.png";

export const QuestionData = [
  {
    question: "どんな猫ですか?",
    answers: [
      { option: "家猫", score: 1 },
      { option: "庭猫", score: 2 },
      { option: "野良猫", score: 3 },
    ],
    image: catQ1,
  },
  {
    question: "ふみふみをよくしますか?",
    answers: [
      { option: "はい、よくします", score: 3 },
      { option: "たまにします", score: 2 },
      { option: "いいえ、ほとんどしません", score: 1 },
    ],
    image: catQ2,
  },
  {
    question: "あなたのそばでよく\nゴロゴロいいますか?",
    answers: [
      { option: "はい、よくいいます", score: 3 },
      { option: "たまにいいます", score: 2 },
      { option: "いいえ、ほとんどいません", score: 1 },
    ],
    image: catQ3,
  },
  {
    question: "あなたの顔や体に \n顔をこすりつけますか?",
    answers: [
      { option: "はい、よくこすります", score: 3 },
      { option: "たまにこすります", score: 2 },
      { option: "いいえ、ほとんどこすりません", score: 1 },
    ],
    image: catQ4,
  },
  {
    question: "ヘッドバットをよくしますか?",
    answers: [
      { option: "はい、よくします", score: 3 },
      { option: "たまにします", score: 2 },
      { option: "いいえ、ほとんどしません", score: 1 },
    ],
    image: catQ5,
  },
  {
    question: "昆虫や虫など 小さなプレゼントを\n持ってきたことがありますか?",
    answers: [
      { option: "はい、よく持ってきます", score: 3 },
      { option: "たまに持ってきます", score: 2 },
      { option: "いいえ、ほとんどありません", score: 1 },
    ],
    image: catQ6,
  },
  {
    question: "お腹を見せて寝転ぶことが\nよくありますか?",
    answers: [
      { option: "はい、よく見せます", score: 3 },
      { option: "たまに見せます", score: 2 },
      { option: "いいえ、ほとんど見せません", score: 1 },
    ],
    image: catQ7,
  },
  {
    question: "ゆっくりとまばたきをして\nあなたを見つめますか?",
    answers: [
      { option: "はい、よく見つめます", score: 3 },
      { option: "たまに見つめます", score: 2 },
      { option: "いいえ、ほとんど見つめません", score: 1 },
    ],
    image: catQ8,
  },
  {
    question: "あなたのそばで \n寝るのが好きですか?",
    answers: [
      { option: "はい、よくそばで寝ます", score: 3 },
      { option: "たまにそばで寝ます", score: 2 },
      { option: "いいえ、一人で寝るのが好きです", score: 1 },
    ],
    image: catQ9,
  },
  {
    question: "あなたをあちこちついてきますか?",
    answers: [
      { option: "はい、よくついてきます", score: 3 },
      { option: "たまについてきます", score: 2 },
      { option: "いいえ、ほとんどついてきません", score: 1 },
    ],
    image: catQ10,
  },
  {
    question: "あなたを優しく噛むことがありますか?",
    answers: [
      { option: "はい、よく優しく噛みます", score: 3 },
      { option: "たまに優しく噛みます", score: 2 },
      { option: "いいえ、ほとんど噛みません", score: 1 },
    ],
    image: catQ11,
  },
  {
    question: "あなたの膝や体の上に\n座るのが好きですか?",
    answers: [
      { option: "はい、よく座ります", score: 3 },
      { option: "たまに座ります", score: 2 },
      { option: "いいえ、ほとんど座りません", score: 1 },
    ],
    image: catQ12,
  },
  {
    question: "あなたの手や顔を舐めますか?",
    answers: [
      { option: "はい、よく舐めます", score: 3 },
      { option: "たまに舐めます", score: 2 },
      { option: "いいえ、ほとんど舐めません", score: 1 },
    ],
    image: catQ13,
  },
];
