import React, { useEffect } from "react";
import "./ZombieMain.css";
import { useNavigate } from "react-router-dom";
import mainImg from "./img/main.png";
import Take5 from "../../../../components/Take5/Take5";
import SEO from "../../SEOko";
import { zombieAttributeko } from "../../seoAttributesko";

const ZombieMain = () => {
  const navigate = useNavigate();
  const handleClickButton = () => {
    navigate("/test/zombie/question");
  };

  const id = "zombie";

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="Zombie_Main_Wrapper">
      <SEO attribute={zombieAttributeko} id={id} />
      <Take5 />
      <div className="Zombie_contents">
        <div className="Zombie_Main_Header">좀비월드 MBTI</div>
        <div className="Zombie_Main_Title">🚨긴급! 좀비사태 발령!</div>
        <div className="Zombie_Main_Subtitle">
          좀비사태에 대처하는 내 성격은?
        </div>
        <div className="Zombie_Main_Desc">
          생존본능이 발동했을 때는 MBTI가 달라져요! <br /> 과몰입 준비됐나요?
        </div>
        <div className="Zombie_Main_Img">
          <img src={mainImg} width={250} alt="" />
        </div>
        <div className="Zombie_Main_BtnContainer">
          <button className="Zombie_Main_StartBtn" onClick={handleClickButton}>
            시작하기
          </button>
        </div>
      </div>
    </div>
  );
};

export default ZombieMain;
