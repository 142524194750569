import React from "react";
import "./InsomniaMainen.css";
import { useNavigate } from "react-router-dom";
import Take5 from "../../../../components/Take5/Take5";
import SEO from "../../SEOen";
import main from "./img/insomnia_Main.png";
import { insomniaAttributeen } from "../../seoAttributesen";
import AdSenseComponent from "../../../../AdSenseComponent";

const InsomniaMainen = () => {
  const navigate = useNavigate();
  const QPage = () => {
    navigate("/test/en/insomniaen/question");
  };

  const id = "insomniaen";

  return (
    <div className="insomniaen_wrapper">
      <div className="insomniaen_contents">
        <SEO attribute={insomniaAttributeen} id={id} />
        <Take5 />
        <div className="insomniaen_main_container">
          <img src={main} alt="main" />

          <button onClick={QPage} className="insomniaen_start_btn">
            GET START!
          </button>
        </div>
      </div>
      <AdSenseComponent />
    </div>
  );
};

export default InsomniaMainen;
