export const homeAttributeen = {
  name: "TAKEFIVE TEST",
  imageUrl:
    "https://testlist-img.s3.ap-northeast-2.amazonaws.com/%E1%84%90%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%8F%E1%85%B3%E1%84%91%E1%85%A1%E1%84%8B%E1%85%B5%E1%84%87%E1%85%B3_%E1%84%86%E1%85%A6%E1%84%90%E1%85%A1.png",
  desc: "psychological test, mbti test, personality test, Type test,love test ETC.",
};

export const thehouseAttributeen = {
  name: "Home of the Heart Test",
  imageUrl:
    "https://snackcat.click/static/media/en_TheHouse_Sample.c0d9792c5c26c05201b3.png",
  desc: "What does the home in my heart look like?",
};

export const olymposAttributeen = {
  name: "Olympus MBTI",
  imageUrl:
    "https://snackcat.click/static/media/en_Olympos_Sample.59cfcbffcaabb23740ab.png",
  desc: "Which Olympian god is most like me?",
};

export const bigfiveAttributeen = {
  name: "Big Five Personality Test",
  imageUrl:
    "https://snackcat.click/static/media/en_Bigfive_Sample.51e5ad05f1ac77471cc0.png",
  desc: "What is my Big Five personality type?",
};

export const wordtypeAttributeen = {
  name: "Word Personality Test",
  imageUrl:
    "https://snackcat.click/static/media/en_Wordtype_Sample.602c6bf91bfb41920554.png",
  desc: "What does my personality look like with the three standout words?",
};

export const catlovesmeAttributeen = {
  name: "Cat Affection Test",
  imageUrl:
    "https://snackcat.click/static/media/CatlovesMe_Sample.088a3695d4b60b9cb4ee.png",
  desc: "How much does my cat like me?",
};

export const blackcattarotAttributeen = {
  name: "Advice from the Black Cat",
  imageUrl:
    "https://snackcat.click/static/media/en_Blackcat_Sample.992de5b0b8d4e2ddb223.png",
  desc: "In moments of worry, concern, and decision-making, listen to the advice of the black cat!",
};

export const animalAttributeen = {
  name: "Animal Tarot MBTI",
  imageUrl:
    "https://snackcat.click/static/media/en_Animal_Sample2.781f188c977361c75567.png",
  desc: "What animal is \nsimilar to my image?",
};

export const keywordMbtiAttributeen = {
  name: "Keyword MBTI",
  imageUrl:
    "https://snackcat.click/static/media/en_Keyword_Sample.dd9a0084220cffbf2398.png",
  desc: "What are the keywords \ndescribing my personality?",
};

export const realloveAttributeen = {
  name: "True Love MBTI",
  imageUrl:
    "https://snackcat.click/static/media/en_Reallove_Sample.fa18a79b02ca30f5fc27.png",
  desc: "When I am truly in love, \nwhat is my true self like?💗",
};
export const insomniaAttributeen = {
  name: "Corndog Insomnia MBTI",
  imageUrl:
    "https://snackcat.click/static/media/en_Insomnia_Sample.ae40827d12ae866dcbe6.png",
  desc: "In these sleepless nights,\nCould it be due to my personality?",
};

export const playlistAttributeen = {
  name: "MBTI Playlist",
  imageUrl:
    "hhttps://snackcat.click/static/media/en_Playlist_Sample.7674b7720165b4f6d845.png",
  desc: "What are the perfect songs that match your personality?",
};

export const rollingPaper2Attributeen = {
  name: "Rolling Paper MBTI - Love Edition",
  imageUrl:
    "https://snackcat.click/static/media/en_Rollingpaper2_Sample.8ab8363925dfb0142dee.png",
  desc: "What is my dating style according to Rolling Paper?",
};

export const RollingpaperAttributeen = {
  name: "Rolling Paper MBTI - Friendship Ver",
  imageUrl:
    "https://snackcat.click/static/media/Rollingpaper_Sample.b0e1f223f80547b1578e.png",
  desc: "What would my rolling paper say?",
};

export const yourmoodAttributeen = {
  name: "Your Mood TEST",
  imageUrl:
    "https://snackcat.click/static/media/en_Mood_Sample.8526474d98b2e5db6fab.png",
  desc: "What is my atmosphere that others see?",
};

export const zombieAttributeen = {
  name: "Zombie World MBTI",
  imageUrl:
    "https://snackcat.click/static/media/en_Zombie_Sample.1a48e5bf3639b737bc39.png",
  desc: "What type of survivor am I?",
};
