import "./MoodMainjp.css";
import { useNavigate } from "react-router-dom";
import homeimg from "./img/homeimg.png";
import Take5 from "../../../../components/Take5/Take5";
import SEO from "../../SEOjp";
import { yourmoodAttributejp } from "../../seoAttributesjp";
import AdSenseComponent from "../../../../AdSenseComponent";

const MoodMainjp = () => {
  const navigate = useNavigate();

  const handleClickStart = () => {
    navigate("/test/jp/yourmoodjp/question");
  };

  const id = "yourmoodjp";

  return (
    <div className="moodjp_wrapper">
      <div className="moodjp_contents">
        <SEO attribute={yourmoodAttributejp} id={id} />
        <Take5 />
        <div className="moodjp_container">
          <div className="moodjp_home_title">あなたの気分 テスト</div>
          <div className="moodjp_sub_title">
            他人が見る私の雰囲気は何でしょうか？
          </div>
          <div className="moodjp_homeimg_container">
            <img src={homeimg} alt="homeimg" />

            <div className="moodjp_button_contanier">
              <button onClick={handleClickStart} className="moodjp_start_btn">
                始める
              </button>
            </div>
          </div>
          <AdSenseComponent />
        </div>
      </div>
    </div>
  );
};

export default MoodMainjp;
