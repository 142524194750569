import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Card from "./Cardjp";
import "./Homejp.css";
import TestList from "./TestListjp.json";
import Logo from "../../img/logo.png";
import { useNavigate } from "react-router-dom";
import SEO from "./SEOjp";
import { homeAttributejp } from "./seoAttributesjp";
import Footer from "../../components/Footer/Footer";
import LanguageSelector from "../../LanguageSelector";

function Homejp() {
  const navigate = useNavigate();
  const [testList, setTestList] = useState([]);
  const gotoHome = () => {
    navigate("/test/jp");
  };

  useEffect(() => {
    setTestList(TestList);
  }, []);

  const url = "https://zombiembti.net/test/jp";

  return (
    <div className="Homejp">
      <div className="Homejp_contents">
        <SEO attribute={homeAttributejp} url={url} />
        <header className="Homejp-header">
          <div onClick={gotoHome} className="logo-container">
            <img src={Logo} alt="logo" />
            <h2 className="logo-title">TAKEFIVE TEST</h2>
          </div>
          <p className="sub-title">
            心理テスト、MBTIテスト、性格テスト、
            <br />
            タイプテスト、恋愛テストなど
          </p>
        </header>
        <LanguageSelector />
        <div className="TestListjp">
          {testList.map((test) => (
            <Link key={test.id} to={`/test/jp/${test.id}`}>
              <Card test={test} />
            </Link>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Homejp;
