import React from "react";
import ReactGA from "react-ga";
import "./CoupangPromotionCP.css";
import CoupangPromotionImg from "./CoupangPromotion.png";

const _eventSenderGA = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};

const CoupangPromotion = () => {
  window.open("https://link.coupang.com/a/bwc7S9", "_blank");
  _eventSenderGA("Clicking", "Click Coupang Promotion");
};

const CoupangPromotionCP = () => {
  return (
    <div className="Coupang_Promotion_Container">
      <div className="Coupang_Promotion" onClick={CoupangPromotion}>
        <img src={CoupangPromotionImg} alt="CoupangPromotion-banner" />
      </div>
      <p className="Coupang_Promotion_p">
        - 쿠팡 파트너스 활동의 일환으로 이에 따른 일정액의 수수료를 제공받습니다
        -
      </p>
    </div>
  );
};

export default CoupangPromotionCP;
