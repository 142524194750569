import "./RollingPaperen.css";
import { useNavigate } from "react-router-dom";
import Take5 from "../../../../components/Take5/Take5";
import main from "./img/rp_main2.png";
import startBtn from "./img/rp_start_btn.png";
import SEO from "../../SEOen";
import { RollingpaperAttributeen } from "../../seoAttributesen";
import AdSenseComponent from "../../../../AdSenseComponent";

const RollingPaperen = () => {
  const navigate = useNavigate();
  const rpGotoQ = () => {
    navigate("/test/en/rollingpaperen/question");
  };

  const id = "rollingpaperen";

  return (
    <div className="rollingpaper_wrapper">
      <div className="rollingpaper_contents">
        <SEO attribute={RollingpaperAttributeen} id={id} />
        <Take5 />
        <div className="rollingpaper_main_container">
          <img src={main} alt="main" />

          <div onClick={rpGotoQ} className="rollingpaper_start_btn_container">
            <img src={startBtn} alt="startBtn" />
          </div>
        </div>
      </div>
      <AdSenseComponent />
    </div>
  );
};

export default RollingPaperen;
