export const QuestionData = [
  {
    id: 1,
    title:
      "One day, \na zombie virus suddenly spread. \nOh my God... what's happening?\n This is the time to stick\ntogether to survive!",
    answera: "Let's go to the shelter\n where survivors gather!",
    answerb: "Let's form a base with people we know!",
    type: "EI",
  },
  {
    id: 2,
    title:
      "In a situation where\n everyone is gathered,\n opinions about dividing living \nspaces are exchanged...",
    answera:
      "Isn't it dangerous to separate? \nIt's scary, so let's stay together",
    answerb:
      "Still, don't we need our own space?\n It's better to be moderately apart",
    type: "EI",
  },
  {
    id: 3,
    title:
      " Welcome, newcomers!\n New survivors have arrived. \nWhat would I do at this moment?",
    answera:
      "I have so many questions! Where are you from? Were there many zombies on the way here? Ask everything",
    answerb:
      "We'll find out everything anyway with time. Sleep and do my own tasks",
    type: "EI",
  },
  {
    id: 4,
    title: "Found a place full of weapons! \nWhat weapon would I choose?",
    answera: "Practicality is the best.\n A sturdy-looking baseball bat!",
    answerb:
      "There are enough materials. \nI'll take various things and make a new weapon",
    type: "SN",
  },
  {
    id: 5,
    title: `It's a late night with no sleep. \nOne survivor comes up to me and asks,\n"What are you thinking?"`,
    answera: "I was thinking about \nwhat I need to do tomorrow",
    answerb: "Just... when will the zombie apocalypse end?",
    type: "SN",
  },
  {
    id: 6,
    title:
      "Our shelter has been \noccupied by zombies! Fortunately,\n another shelter accepted us,\n but the rules are a bit strict.\n What would I do?",
    answera: "Familiarize myself with the rules and try my best to follow them",
    answerb: "Refer to the rules but live my own way",
    type: "SN",
  },
  {
    id: 7,
    title: "A colleague is injured. \nWhat would I say?",
    answera: `"Did you get bitten by a zombie? Or fortunately not. Get treatment quickly and come back!"`,
    answerb: `"Are you okay? Does it hurt a lot? (Continues to worry)"`,
    type: "TF",
  },
  {
    id: 8,
    title:
      " It turns out that person\n was bitten by a zombie! \nThe decision is made by majority vote. \nWhat should I do?",
    answera: `The rest must survive. \n"Will you leave, please?"`,
    answerb: `This friend has endured with us; \nwe can't kick him out immediately. \n"Can we observe a little longer?"`,
    type: "TF",
  },
  {
    id: 9,
    title:
      "Late at night, \nI overheard survivors talking about me. \nWhat would be a more \npleasant thing to hear?",
    answera:
      " Isn't he really competent? He knows a lot and is capable. Having him makes me feel secure!",
    answerb:
      "That person is so nice. I want to stick with him until the end. I can trust him!",
    type: "TF",
  },
  {
    id: 10,
    title:
      "Our shelter is exposed to zombies.\n Now we need to move! \nBut where should we go?",
    answera:
      "Let's go to A-Mart, \nwhere we went to get food last time! We'll plan more thoroughly for zombie countermeasures and move",
    answerb:
      "B-Mart is bigger, \nso let's go there! It's worth the risk; we'll deal with unexpected situations when they arise",
    type: "JP",
  },
  {
    id: 11,
    title:
      "Got information that my \nfamily is still alive. \nIt's not far away... What would I do?",
    answera:
      "We must rescue them without delay, so let's plan first. \nPlan the movement, escape route, Plan B, and check the supplies",
    answerb:
      " We need to rescue them right now! \nOutline the plan loosely and leave immediately!",
    type: "JP",
  },
  {
    id: 12,
    title:
      "Successful rescue! \nNow, only the return journey is left. \nHurrying back to the shelter... \nis that the military? \nThey're safely evacuating survivors.\n What would I do at this moment?",
    answera:
      "Since I promised my comrades that I would come back, returning is a priority. I ask for company to the shelter",
    answerb:
      " First, take shelter. I've provided the shelter's location, so my comrades should be able to join soon, right?",
    type: "JP",
  },
];
