import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import "./SistersR.css";
import Take5 from "../../../../components/Take5/Take5";
import Footer from "../../../../components/Footer/Footer";
import ShareButton from "./ShareButton";
import { SyncLoader } from "react-spinners";
import CoupangBanner from "../../CoupangBanner/CoupangBanner";
import Card from "../../Cardko";
import TestList from "../../TestListko.json";
import CoupangPromotionCP from "../../CoupangPromotionCP/CoupangPromotionCP";
import InnerCoupang from "../../InnerCoupang/InnerCoupang";
import resultData from "./data/ResultData"; // 수정된 ResultData.js 파일 import

const SistersR = () => {
  const { type } = useParams(); // URL에서 type 파라미터 받아옴
  const location = useLocation();
  const { olderSister, youngerSister } = location.state || {}; // 메인에서 전달된 이름 받아옴
  const navigate = useNavigate();
  const [showResult, setShowResult] = useState(false);
  const [hasCookie, setHasCookie] = useState(false);
  const [testList, setTestList] = useState([]);

  const excludedTestId = "sisters";
  const filteredTestList = testList.filter(
    (test) => test.id !== excludedTestId
  );

  const handleResultShow = () => {
    const resultContainer = document.querySelector(".All_Test_no_cookie");
    resultContainer.style.overflow = "visible";
    resultContainer.style.height = "100%";
  };

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0); // 페이지 로드 시 스크롤을 최상단으로 이동
  }, []);

  useEffect(() => {
    setTestList(TestList);
  }, []);

  useEffect(() => {
    setHasCookie(document.cookie.indexOf("coupang=true") !== -1);
  }, []);

  const handleClick = () => {
    setShowResult(true);
    handleResultShow();
  };

  const renderResult = () => {
    const result = resultData[type]; // type에 따른 결과 데이터 가져오기

    if (result) {
      return (
        <>
          <img src={result.image} alt={type} className="result-image" />
        </>
      );
    } else {
      return <p>유형을 찾을 수 없습니다.</p>;
    }
  };

  return (
    <>
      <div className="sisters_R_Wrapper">
        {loading ? (
          <div className="sisters_loading_container">
            <p className="sisters_loading_text">분석중...👩🏻‍❤️‍👩🏼</p>
            <SyncLoader
              color={"#d31caf"}
              loading={loading}
              size={9}
              speedMultiplier={0.5}
              cssOverride={{
                marginTop: "3rem",
                backgroundColor: "transparent",
              }}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
            <CoupangPromotionCP />
          </div>
        ) : (
          <>
            <Take5 />
            {hasCookie ? (
              <>
                <div className="All_Test_has_cookie">
                  <div className="sisters_R_Contents">
                    <div className="sisters_R_title">
                      <h3>{`${olderSister}+${youngerSister}의 자매  유형은...`}</h3>
                    </div>
                    <div
                      className="sisters_R_ResultImg_Container"
                      id="coupangDiv"
                    >
                      {renderResult()}
                    </div>
                    <InnerCoupang />
                    <div className="sisters_R_Restart_Btn_Container">
                      <button
                        className="sisters_R_Restart_Btn"
                        style={{
                          minWidth: "210px",
                          maxWidth: "210px",
                          minHeight: "50px",
                          boxShadow: "none",
                        }}
                        onClick={() => navigate("/test/sisters")}
                      >
                        테스트 다시하기
                      </button>
                    </div>

                    <ShareButton />
                    <div className="TestList_container">
                      {filteredTestList.map((test) => (
                        <div key={test.id} className="TestList_container2">
                          <Link to={`/test/${test.id}`}>
                            <Card test={test} />
                          </Link>
                        </div>
                      ))}
                    </div>
                    <Footer />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="All_Test_no_cookie_container">
                  <div className="All_Test_no_cookie">
                    <div className="sisters_R_Contents">
                      <div className="sisters_R_title">
                        <h3>{`${olderSister}+${youngerSister}의 자매 유형은...`}</h3>{" "}
                      </div>

                      <div
                        className="sisters_R_ResultImg_Container"
                        id="coupangDiv"
                      >
                        {renderResult()}
                      </div>
                      <InnerCoupang />
                      <div className="sisters_Coupang_div">
                        <CoupangBanner handleClick={handleClick} />
                      </div>

                      {showResult && (
                        <>
                          <div className="sisters_R_Restart_Btn_Container">
                            <button
                              className="sisters_R_Restart_Btn"
                              style={{
                                minWidth: "210px",
                                maxWidth: "210px",
                                minHeight: "50px",
                                boxShadow: "none",
                              }}
                              onClick={() => navigate("/test/sisters")}
                            >
                              테스트 다시하기
                            </button>
                          </div>

                          <ShareButton />
                          <div className="TestList_container">
                            {filteredTestList.map((test) => (
                              <div
                                key={test.id}
                                className="TestList_container2"
                              >
                                <Link to={`/test/${test.id}`}>
                                  <Card test={test} />
                                </Link>
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default SistersR;
