import React, { useState, useEffect } from "react";
import ReactGA from "react-ga";
import { useCookies } from "react-cookie";
import "./AliComponent.css";

const AliComponent = ({ handleClick }) => {
  const [showBanner, setShowBanner] = useState(true);
  const [cookies, setCookie] = useCookies(["Ali"]);

  const _eventSenderGA = (category, action, label) => {
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    });
  };

  const handleIframeClick = () => {
    window.open(
      "https://s.click.aliexpress.com/e/_DmRfOIh?bz=300*250",
      "_blank"
    );
    setShowBanner(false);
    setCookie("Ali", true, {
      path: "/",
      maxAge: 60 * 5,
      secure: true,
    });
    _eventSenderGA("Clicking", "Click Ali Button");
    handleClick();
  };

  useEffect(() => {
    if (cookies.Ali) {
      setShowBanner(false);
    }
  }, [cookies]);

  return (
    <div className="Ali_Wrapper">
      {showBanner && (
        <div className="Ali_ct1">
          <div className="Ali_container">
            <button className="Ali_go_to_btn" onClick={handleIframeClick}>
              <p>
                Visit AliExpress &
                <br /> View Results
              </p>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AliComponent;
