import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import testListjp from "./TestListjp.json";

const SEO = ({ attribute, id }) => {
  const test = testListjp.find((test) => test.id === id);
  if (!test) {
    return null;
  }

  const { name, imageUrl, desc } = attribute;
  // const { title, desc, image } = test;
  const fullTitle = `TAKEFIVE TEST | ${name}`;
  const description = `${desc}`;
  const url = `https://zombiembti.net/test/jp/${id}`;

  return (
    <HelmetProvider>
      <Helmet>
        <title>
          {fullTitle} - {description}
        </title>
        <meta name="description" content={description} />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={url} />
        <meta property="og:title" content={fullTitle} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={imageUrl} />
        <meta property="og:url" content={url} />

        {/* <!-- For Facebook --> */}
        <meta property="og:title" content={fullTitle} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={imageUrl} />
        <meta property="og:url" content={url} />
        <meta property="og:type" content="article" />

        {/* <!-- For Twitter --> */}
        <meta name="twitter:title" content={fullTitle} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={imageUrl} />
        <meta name="twitter:card" content="summary" />
      </Helmet>
    </HelmetProvider>
  );
};

export default SEO;
