import ESTJ from "../img/ESTJ.png";
import ESFP from "../img/ESFP.png";
import ENTJ from "../img/ENTJ.png";
import INTP from "../img/INTP.png";
import INTJ from "../img/INTJ.png";
import ISTJ from "../img/ISTJ.png";
import ESTP from "../img/ESTP.png";
import INFP from "../img/INFP.png";
import ISFP from "../img/ISFP.png";
import ISFJ from "../img/ISFJ.png";
import ENFP from "../img/ENFP.png";
import INFJ from "../img/INFJ.png";
import ESFJ from "../img/ESFJ.png";
import ENTP from "../img/ENTP.png";
import ISTP from "../img/ISTP.png";
import ENFJ from "../img/ENFJ.png";

export const ResultData = [
  {
    id: 1,
    best: "ESTJ",
    image: `${ESTJ}`,
    desc: "책임감이 강하고 일처리 능력이 뛰어난 당신은 냉철해보입니다. 한겨울의 새벽같아요. 리더십이 강하고 유능한 인상을 주죠. \n \n 계획을 잘 세우고 체계적으로 일을 처리하는 것을 좋아해서 꼼꼼하고 세심한 사람으로 보이기도 합니다. 당신은 감정적인 것보다 사실적인 정보에 더 관심이 많습니다. \n \n 현실적이고 직설적인 편이므로 때로는 강압적이고 고집스러워 보이기도 해요. 그치만 어느 곳에서나 안정적인 분위기를 유지하는 역할을 하기 때문에 없어서는 안 될 사람이죠.",
  },
  {
    id: 2,
    best: "ESFP",
    image: `${ESFP}`,
  },
  {
    id: 3,
    best: "ENTJ",
    image: `${ENTJ}`,
  },
  {
    id: 4,
    best: "INTP",
    image: `${INTP}`,
  },
  {
    id: 5,
    best: "INTJ",
    image: `${INTJ}`,
  },
  {
    id: 6,
    best: "ISTJ",
    image: `${ISTJ}`,
  },
  {
    id: 7,
    best: "ESTP",
    image: `${ESTP}`,
  },
  {
    id: 8,
    best: "INFP",
    image: `${INFP}`,
  },
  {
    id: 9,
    best: "ISFP",
    image: `${ISFP}`,
  },
  {
    id: 10,
    best: "ISFJ",
    image: `${ISFJ}`,
  },
  {
    id: 11,
    best: "ENFP",
    image: `${ENFP}`,
  },
  {
    id: 12,
    best: "INFJ",
    image: `${INFJ}`,
  },
  {
    id: 13,
    best: "ESFJ",
    image: `${ESFJ}`,
  },
  {
    id: 14,
    best: "ENTP",
    image: `${ENTP}`,
  },
  {
    id: 15,
    best: "ISTP",
    image: `${ISTP}`,
  },
  {
    id: 16,
    best: "ENFJ",
    image: `${ENFJ}`,
  },
];
