export const QuestionData = [
  {
    id: 1,
    title: "내가 만약 올림포스 신이라면?",
    answera: "지도하고 이끄는 신",
    answerb: "수호하고 보호하는 신",
    type: "EI",
  },
  {
    id: 2,
    title: "다른 신들과 모인다면?",
    answera: "여러 신들과 축제를 즐긴다",
    answerb: "소수의 신들과 교류한다",
    type: "EI",
  },
  {
    id: 3,
    title: "인간들과는 어떻게 지낼까?",
    answera: "자주 개입하며 도와준다",
    answerb: "주로 지켜본다",
    type: "EI",
  },
  {
    id: 4,
    title: "내가 신이라면\n어떤 걸 더 중요하게 생각할까?",
    answera: "현재의 평화와 안정",
    answerb: "미래의 가능성과 변화",
    type: "SN",
  },
  {
    id: 5,
    title: "인간들에게 전할 신탁은?",
    answera: "즉각적인 도움을 주는\n현실적인 조언",
    answerb: "미래를 암시하는\n예언적인 메시지",
    type: "SN",
  },
  {
    id: 6,
    title: "인간들에게 \n어떤 가르침을 전할까?",
    answera: "지혜와 지식",
    answerb: "창조와 예술",
    type: "SN",
  },
  {
    id: 7,
    title: "인간들이 서로 갈등을 일으킬 때,\n어떻게 개입할까?",
    answera: "논리와 사실로 해결책을 제시한다",
    answerb: "감정적으로 공감하고 위로한다",
    type: "TF",
  },
  {
    id: 8,
    title: "다른 신과 의견이 충돌했을 때, \n나는?",
    answera: "논리적 근거를 들어 설득한다",
    answerb: "상대의 감정을 고려해 접근한다",
    type: "TF",
  },
  {
    id: 9,
    title: "어떤 신으로 기억되고 싶을까?",
    answera: "지혜롭고 공정한 신",
    answerb: "따뜻하고 자애로운 신",
    type: "TF",
  },
  {
    id: 10,
    title: "어떤 방식으로 전투에 임할까?",
    answera: "신중하게 전략을 세워 \n전투를 준비한다",
    answerb: "즉각적으로 공격하며 \n전투를 즐긴다",
    type: "JP",
  },
  {
    id: 11,
    title: "일정을 어떻게 관리할까?",
    answera: "철저하고 체계적으로 계획을 세운다",
    answerb: "유연하고 즉흥적으로 대응한다",
    type: "JP",
  },
  {
    id: 12,
    title: "계획이 틀어졌을 때 \n어떻게 할까?",
    answera: "계획을 다시 세우고 \n상황을 통제한다",
    answerb: "상황에 맞게 \n즉흥적으로 대처한다",
    type: "JP",
  },
];
