export const QuestionData = [
  {
    id: 1,
    title: "デートのない週末、私は何をするのか?",
    answera: "一人または友達と遊ぶ",
    answerb: "家で一人で過ごす",
    type: "EI",
  },
  {
    id: 2,
    title:
      "恋人の友達と2泊3日の\n旅行に行かなければならない場合、\nあなたはどう言いますか?",
    answera: "素晴らしい！楽しいだろうね！",
    answerb: "(少し疲れるかもしれない)\n行きたいなら行こう。",
    type: "EI",
  },
  {
    id: 3,
    title: "パートナーが週中にデートしたいと言うと、\n私は...",
    answera: "それはいいよ。",
    answerb: "一人の時間が必要だ...",
    type: "EI",
  },
  {
    id: 4,
    title: "お見合いデートに行った。\n相手を見ると、私は...",
    answera: "第一印象を基に多少の判断を下す。",
    answerb: "判断する前に相手の\nどんな人かを理解しようとする。",
    type: "SN",
  },
  {
    id: 5,
    title: "紹介された人と仲が良い。\nこの時点で私の気持ちは...",
    answera: "うわー、こんなに仲が良いなんて信じられない！",
    answerb: "結婚を想像する。",
    type: "SN",
  },
  {
    id: 6,
    title: "もしパートナーが『別れるなら、\nどんな理由だと思う?』と聞いたら...",
    answera: "なんでそんなことを考えているの?",
    answerb: "まあ...（真剣に考える）",
    type: "SN",
  },
  {
    id: 7,
    title:
      "気になる相手が『月が美しいね』と\nメッセージをくれたら、私はどう返す?",
    answera: "本当に?（月を確認する）",
    answerb:
      "私のことを思い出してくれたの?\n私に連絡する理由が必要だったの?\nとにかく、可愛いね。",
    type: "TF",
  },
  {
    id: 8,
    title: "パートナーが私のメッセージを\n読んで返信しない場合...",
    answera: "忙しいんだろう。",
    answerb: "うーん... なんで連絡がないんだろう...?",
    type: "TF",
  },
  {
    id: 9,
    title: "パートナーの悩みを聞くとき、\n私は重点を置くのは...",
    answera: "解決策を見つけること。",
    answerb: "彼らの感情に共感すること。",
    type: "TF",
  },
  {
    id: 10,
    title: "計画されたデートの日、私は...",
    answera:
      "準備時間、移動時間、\n余裕のある時間を考慮して計算し、\n準備する。",
    answerb: "大体この時間に出発しよう~",
    type: "JP",
  },
  {
    id: 11,
    title: "明日、パートナーが私の家に来る。\nその時、私は...",
    answera: "事前に掃除をして\n必要な生活用品を確認する。",
    answerb: "必要なものはその時に買う。",
    type: "JP",
  },
  {
    id: 12,
    title: "もしパートナーが突然旅行を提案したら、\n私は...",
    answera: "飛行機のチケットはどうなってる?\n車のレンタル?宿泊施設?",

    answerb: "ああそうだね〜！行ってみよう！！！",
    type: "JP",
  },
];
