import React, { useEffect } from "react";
import "./Song2000Main.css";
import { useNavigate } from "react-router-dom";
import main from "./img/Song_Main.png";
import Take5 from "../../../../components/Take5/Take5";
import SEO from "../../SEOko";
import { song2000Attributeko } from "../../seoAttributesko";
import AdSenseComponent from "../../../../AdSenseComponent";

const Song2000Main = () => {
  const navigate = useNavigate();
  const QPage = () => {
    navigate("/test/song2000/question");
  };

  const id = "song2000";

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="song2000_wrapper">
      <div className="song2000_contents">
        <SEO attribute={song2000Attributeko} id={id} />
        <Take5 />
        <div className="song2000_main_container">
          <img src={main} alt="main" />
          <button onClick={QPage} className="song2000_start_btn">
            시작하기
          </button>
        </div>
      </div>
      <AdSenseComponent />
    </div>
  );
};

export default Song2000Main;
