export const QuestionData = [
  {
    id: 1,
    title: "내 생각에 나는...",
    answera: "밖순이",
    answerb: "집순이",
    type: "EI",
  },
  {
    id: 2,
    title: "오랜만에 술이 먹고 싶다! \n이때 나는?",
    answera: "오늘 나랑 술 마실 사람~? \n (친구를 불러서 논다)",
    answerb: "혼술 가보자고 \n (혼자 마신다)",
    type: "EI",
  },
  {
    id: 3,
    title: "내가 선호하는 모임은?",
    answera: "왁자지껄파\n(많은 사람들을 만나는 모임)",
    answerb: "소수정예파\n(편한 사람들과 만나는 모임)",
    type: "EI",
  },
  {
    id: 4,
    title: "심오해서 이해하기 어려운\n영화를 본 나는?",
    answera: "뭔가 메세지가 있겠거니 \n생각하고 만다",
    answerb: "리뷰와 해석을 \n찾아서 읽어본다",
    type: "SN",
  },
  {
    id: 5,
    title: "내가 더 중요하게 생각하는 것은?",
    answera: "미래보다 현재",
    answerb: "현재보다 미래",
    type: "SN",
  },
  {
    id: 6,
    title: "미술관에 간 나. \n작품을 보며 하는 생각은?",
    answera: "오, 색감 좋다. 붓터치도 예술이네",
    answerb: "어떤 심정과 상황이었길래 \n이런 작품을 그렸을까?",
    type: "SN",
  },
  {
    id: 7,
    title: `내가 상대에게 배신감을 느낄 때는?`,
    answera: `나에게 거짓말 했다는 걸 알았을 때`,
    answerb: `나의 힘듦, 고통, 슬픔을 \n함께 해주지 않을 때`,
    type: "TF",
  },
  {
    id: 8,
    title:
      "나를 힘들게 하는 문제를 \n상대에게 털어놨을 때,\n내가 원하는 반응은?",
    answera: `문제 해결에 도움되는 \n현실적이고 실질적인 말`,
    answerb: `현재 내 감정에 감정적으로 \n공감해주는 말`,
    type: "TF",
  },
  {
    id: 9,
    title: `내가 누군가에게 \n엄청난 리액션을 하고 있다면?`,
    answera: "별 관심없어서 \n 리액션으로 떼우는 중",
    answerb: "관심이 많아서 \n 리액션으로 표현하는 중",
    type: "TF",
  },
  {
    id: 10,
    title: "동선을 정해뒀는데 \n약간 꼬이게 됐다. 이때 나는?",
    answera: "조금 짜증난다",
    answerb: "그냥 그런가 보다 한다",
    type: "JP",
  },
  {
    id: 11,
    title: "지금 내 방은 어떤 상태?",
    answera: "각 사물들의 위치가 정해져 있음",
    answerb: "어디에 뭐가 있는지 나도 잘 모름",
    type: "JP",
  },
  {
    id: 12,
    title: "나는 어떤 사람?",
    answera: "섣부르게 판단하지 않음",
    answerb: "빠르게 판단을 내리고 싶어함",
    type: "JP",
  },
];
