import ESTJ from "../img/ESTJ.png";
import ESFP from "../img/ESFP.png";
import ENTJ from "../img/ENTJ.png";
import INTP from "../img/INTP.png";
import INTJ from "../img/INTJ.png";
import ISTJ from "../img/ISTJ.png";
import ESTP from "../img/ESTP.png";
import INFP from "../img/INFP.png";
import ISFP from "../img/ISFP.png";
import ISFJ from "../img/ISFJ.png";
import ENFP from "../img/ENFP.png";
import INFJ from "../img/INFJ.png";
import ESFJ from "../img/ESFJ.png";
import ENTP from "../img/ENTP.png";
import ISTP from "../img/ISTP.png";
import ENFJ from "../img/ENFJ.png";

export const resultData = [
  {
    id: 1,
    best: "ESTJ",
    image: `${ESTJ}`,
    title: "On a winter morning",
    desc: "With a strong sense of responsibility and excellent task-handling abilities, you come across as pragmatic. It feels like a winter dawn. You exude strong leadership and competence.\n\n Preferring to plan well and systematically handle tasks, you appear meticulous and detail-oriented. You prioritize factual information over emotional aspects. \n\nBeing realistic and straightforward, you may sometimes come off as assertive and stubborn. However, as someone who plays a role in maintaining a stable atmosphere wherever you are, you are indispensable.",
  },
  {
    id: 2,
    best: "ESFP",
    image: `${ESFP}`,
    title: "Noon in Spring",
    desc: "You, vibrant and full of energy, always seem to radiate a bright and warm light. It's like the noon of spring. You enjoy socializing with others and possess excellent sociability. \n\nYour spontaneous and present-oriented approach, embracing and enjoying every experience, is charming. While you find repetitive tasks boring, your willingness to seek freshness and embrace new things is evident. Expressing your emotions and situations candidly is also one of your attractive qualities. \n\nThough at times, you may appear to have strong biases and prejudices, you don't seem afraid to actively approach new people, environments, and experiences. Given your fondness for public attention, it's no wonder that many people appreciate you.",
  },
  {
    id: 3,
    best: "ENTJ",
    image: `${ENTJ}`,
    title: "Crimson Silk",
    desc: "With innate leadership, you always appear confident and poised. The charisma and high self-esteem that seem fitting for crimson silk evoke a sense of certainty in those who observe you. \n\nYou are a pragmatic and goal-oriented individual. While your natural leadership may make you seem occasionally assertive, you are adept at collaborating with others to achieve your objectives. Efficiency and achievement are paramount to you, portraying you as someone who works diligently toward goals.\n\n Your clear communication style may lead others to perceive you as overly strict and direct, but there are those who find it charming. People often seek wisdom from your precise and concrete approach to understanding situations.",
  },
  {
    id: 4,
    best: "INTP",
    image: `${INTP}`,
    title: "Enigmatic Aurora",
    desc: "You generally emit a calm and serene atmosphere, opting for a careful and quiet demeanor. Driven by a desire to explore facts and principles, others perceive you as highly focused and possessing exceptional analytical skills. \n\nYou contemplate profound answers to questions, crafting your own imaginative theories. While you exhibit rich imagination and creativity, you may show minimal interest in mundane affairs, preferring to keep a friendly yet reserved distance. \n\nAlthough you might appear indifferent to those around you, you are fervently passionate about the fields that captivate your interest. Your inner world is complex and beautifully entwined. Many find the contrast between these aspects intriguing and charming.",
  },
  {
    id: 5,
    best: "INTJ",
    image: `${INTJ}`,
    title: "Beneath the Moonlit Sea",
    desc: "You are calm and composed, enjoying deep conversations while maintaining a reasonable distance from others. Observing you is like witnessing the moonlight cascading beneath the sea on a pitch-black night. \n\nDespite appearing somewhat detached due to your independence and logical nature, you often assist others with your unique problem-solving skills. Valuing efficiency and promptness, you tirelessly contemplate and plan for your vision. \n\nYour passion and perseverance are impressive to others. You consistently delve into profound self-exploration, seeking the meaning and direction of life, providing inspiration to those around you.",
  },
  {
    id: 6,
    best: "ISTJ",
    image: `${ISTJ}`,
    title: "Lush Forest",
    desc: "With strong patience and careful consideration, you evoke the image of a lush forest. You radiate a stable and trustworthy atmosphere. \n\nBefore making decisions, you thoroughly collect and evaluate information. With a high sense of responsibility, concentration, and meticulous nature, you approach your tasks with confidence.\n\n Your proactive and responsible attitude instills a sense of stability and trust in others. Despite appearing reserved, you also exhibit a side that holds onto even the smallest, easily overlooked memories.",
  },
  {
    id: 7,
    best: "ESTP",
    image: `${ESTP}`,
    title: "Rising Sun",
    desc: "You resemble the rising sun, exuding enthusiasm and boundless energy. You enjoy challenging and thrilling experiences, fearlessly moving forward in any situation. \n\nAdapting quickly to new environments or circumstances, you demonstrate exceptional coping abilities, handling situations with flexibility. People around you often admire your ability to adapt and your adept problem-solving skills. Your cheerful disposition uplifts the mood of those around you, as you radiate positive energy. \n\nWith confident and clear communication, you express yourself assertively while also demonstrating a skillful understanding and respect for others' perspectives. Your ability to consider and appreciate different viewpoints showcases your proficiency in understanding and respecting others' positions.",
  },
  {
    id: 8,
    best: "INFP",
    image: `${INFP}`,
    title: "Harmonious Palette",
    desc: "With abundant imagination and creativity, you resemble a palette where various colors come together in harmony. \n\nYour ability to feel and express a wide range of emotions is exceptional. Moreover, your heightened empathy makes you a great friend to many. It's hard not to appreciate someone who respects the needs and values of others. Your warmth, coupled with flexibility, allows you to embrace and apply new ideas, methods, and perspectives. \n\nYou consistently strive to become a better person, placing importance on authenticity and self-trust. You are the kind of person one would want to keep by their side for a long time",
  },
  {
    id: 9,
    best: "ISFP",
    image: `${ISFP}`,
    title: "Geometric Pattern",
    desc: "With innate sensibility, you resemble an unpredictable geometric pattern. Your creative and artistic expression is outstanding. \n\nYou excel in creating something uniquely original using your innate sensibility and imagination. Your keen interest in detail allows you to notice things that others might overlook. Observing this, people admire your intuition and perception. \n\nYou possess a tolerance for respecting others' opinions and thoughts, and your emotional sensitivity allows you to feel and empathize with the emotions of others. Your unpredictability adds an element of mystery to your personality.",
  },
  {
    id: 10,
    best: "ISFJ",
    image: `${ISFJ}`,
    title: "Well-woven Fabric",
    desc: "Your cautious and meticulous nature brings to mind a tightly woven fabric without any gaps. Your strong sense of responsibility towards your work and commitment to completing promised tasks demonstrate diligence, earning you the reputation of being a 'trustworthy person.' \n\nThere's a high likelihood that you are well-regarded and trusted by those around you. Your attention to detail shines in even the smallest things, and you exhibit a delicate touch that others appreciate. \n\nYou have a tendency to accurately perceive changes in others' emotions, and you often think of and assist others with their needs. While you may not have an overly outgoing personality, you seamlessly blend into any environment, creating a stable and reassuring atmosphere.",
  },
  {
    id: 11,
    best: "ENFP",
    image: `${ENFP}`,
    title: "Space of Possibilities",
    desc: "You, with your proactive nature, seem to navigate through a space of possibilities. You have a lot of things you want to do, and you consistently try new endeavors. \n\nYour passionate and spontaneous approach to tasks is motivating to those around you. Energetic and curious, you often initiate tasks with enthusiasm, and your actions inspire others. Your vibrant and inquisitive nature leads you to strive with determination and passion to achieve your goals. \n\nBeing sociable, you have no hesitation in meeting new people, effortlessly blending in and enjoying social interactions. Relationships hold significance for you. With an optimistic outlook, you can approach any situation positively, radiating positive energy to those around you.",
  },
  {
    id: 12,
    best: "INFJ",
    image: `${INFJ}`,
    title: "Blossomed \nUnder the Sun",
    desc: "Your calm and warm demeanor brings to mind a flower quietly blooming under the sunlight. Your considerate nature and empathy are evident, and there's a delicacy in your words and actions. \n\nValuing human relationships, you enjoy engaging in deep conversations with others. Even in challenging situations, you exhibit patience, endurance, and a determined effort to solve problems. \n\nThose around you silently applaud your perseverance. With a sense of ethics and a commitment to principles, you are portrayed as upright, serene, and resolute.",
  },
  {
    id: 13,
    best: "ESFJ",
    image: `${ESFJ}`,
    title: "By the Sunlit Window",
    desc: "You emanate a harmonious and tranquil atmosphere, much like a spot by a sunny window—gentle and warm. \n\nYou excel in interpersonal relationships, often taking on the role of a mediator or counselor, adept at resolving conflicts wherever you go. Finding joy in interacting with people, you possess a high level of sensitivity and emotional perception.\n\n Consequently, you easily understand the emotions of others and empathize with what they feel. People see you as kind and considerate, recognizing your ability to prioritize tasks effectively and solve problems from various perspectives.",
  },
  {
    id: 14,
    best: "ENTP",
    image: `${ENTP}`,
    title: "A Dazzling Firework",
    desc: "With a curious mind and quick mental agility, you're like a vibrant firework bursting forth on a dark night. \n\nYour spontaneous ideas, clear perspectives, and exceptional intuition allow you to simplify complex problems effortlessly. You enjoy expanding your own and others' worlds through debates and discussions. Armed with rich language skills, you can engage in hours-long conversations on a single topic. \n\nOthers can't help but acknowledge and admire your eloquence. While you thrive on extroverted and social interactions, your individualistic and independent nature keeps people intrigued.",
  },
  {
    id: 15,
    best: "ISTP",
    image: `${ISTP}`,
    title: "A Star in the Night Sky",
    desc: "Requiring your own time and space, you resemble the stars in the night sky. \n\nYou shine quietly in your own place, devoid of unnecessary stories, pretense, or hypocrisy. Your ability to grasp the essence of problems and provide practical solutions sets you apart. \n\nWith hands-on problem-solving skills and adaptability, those around you often see you as highly talented. Your preference for spending time alone may make you appear mysterious to others.",
  },
  {
    id: 16,
    best: "ENFJ",
    image: `${ENFJ}`,
    title: "An Autumn Reed Field",
    desc: "Kind-hearted and deeply caring, you evoke a warm atmosphere, much like an autumn reed field. \n\nYou are adept at listening to others' stories, communicating with a gentle and soft demeanor. You enjoy sharing your thoughts and emotions, valuing relationships and making efforts to sustain them. Wherever you are, there is always a lively and positive energy. \n\nAs someone skilled in communication, you express your emotions effectively and cherish your relationships. While you may sometimes feel taken for granted or taken advantage of due to your generous nature, it's okay. Remember that there are many who appreciate and are grateful for your warmth.",
  },
];
