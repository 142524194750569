import React from "react";
import "./WordtypeMainen.css";
import { useNavigate } from "react-router-dom";
import main from "./img/wordtype_Main.png";
import Take5 from "../../../../components/Take5/Take5";
import SEO from "../../SEOen";
import { wordtypeAttributeen } from "../../seoAttributesen";
import AdSenseComponent from "../../../../AdSenseComponent";

const WordtypeMainen = () => {
  const navigate = useNavigate();

  const QPage = () => {
    navigate("/test/en/wordtypeen/question");
  };

  const id = "wordtypeen";

  return (
    <div className="en_wt_wrapper">
      <div className="en_wt_contents">
        <SEO attribute={wordtypeAttributeen} id={id} />
        <Take5 />
        <div className="en_wt_main_container">
          <img src={main} alt="main" />

          <button onClick={QPage} className="en_wt_start_btn">
            GET START!
          </button>
        </div>
      </div>
      <AdSenseComponent />
    </div>
  );
};

export default WordtypeMainen;
