export const QuestionData = [
  {
    id: 1,
    title: "How do I recharge my energy?",
    answera: "I recharge while having fun with others.",
    answerb: "I recharge on my own.",
    type: "EI",
  },
  {
    id: 2,
    title: "Which place do I prefer more?",
    answera: "Trending hotspots on social media.",
    answerb: "Quiet places where I can relax.",
    type: "EI",
  },
  {
    id: 3,
    title:
      "I'm planning a 3-day, 4-night domestic trip. Who do I want to go with?",
    answera: "Friends, partner, or family.",
    answerb: "Alone.",
    type: "EI",
  },
  {
    id: 4,
    title:
      "On a day when I'm traveling by train, what kind of music do I want to listen to?",
    answera: "I play my usual playlist!",
    answerb:
      "A playlist that I can immerse in the current mood and atmosphere!",
    type: "SN",
  },
  {
    id: 5,
    title:
      "While looking outside the train 🚃 and listening to music, I think...",
    answera: "The weather is nice, I feel great~",
    answerb: "(I'm already the protagonist of a movie)",
    type: "SN",
  },
  {
    id: 6,
    title: "Wow! I've found an amazing craftwork. At this moment, I...",
    answera: "Wow, this is incredible... (I admire it)",
    answerb: "Wow, what materials were used? How was it made?",
    type: "SN",
  },
  {
    id: 7,
    title: `While I'm traveling, my friend says,\n"I envy you... I'm working." At this moment, I...`,
    answera: `Haha, it's almost the weekend, cheer up~!`,
    answerb: `Are you very busy?\nLet's plan to hang out together next time!!`,
    type: "TF",
  },
  {
    id: 8,
    title:
      "I've arrived at my accommodation, but there's a bit of an issue. My preferred way of handling this is...",
    answera: `Explain the reason for the issue, then apologize and quickly resolve it.`,
    answerb: `Show empathy for the inconvenience I'm experiencing, then apologize, resolve the issue, and provide a small service.`,
    type: "TF",
  },
  {
    id: 9,
    title: `The accommodation staff gave me a bottle of wine as a gesture of apology. My inner thoughts are...`,
    answera: "Oh, I'll just save it for later to enjoy.",
    answerb: "The issue was handled well, and they gave me wine! Feeling good!",
    type: "TF",
  },
  {
    id: 10,
    title:
      "The restaurant I really wanted to visit has closed its doors. In this situation, I...",
    answera: "(Suddenly stressed out) Ugh, where should I go now...",
    answerb:
      "(A bit taken aback, but it can't be helped) Well, are there any decent alternatives nearby?",
    type: "JP",
  },
  {
    id: 11,
    title: "It's the last day of the trip! My plan for today is...",
    answera: "It's already pre-planned.",
    answerb: "I'll decide it on the spot.",
    type: "JP",
  },
  {
    id: 12,
    title: "After arriving home, I...",
    answera: "At least tidying up my stuff a bit makes me feel more at ease!",
    answerb: "Ugh, organizing my things? I'll do it later.",
    type: "JP",
  },
];
