import A from "../img/A.png";
import B from "../img/B.png";
import C from "../img/C.png";
import D from "../img/D.png";
import E from "../img/E.png";
import F from "../img/F.png";
import G from "../img/G.png";
import H from "../img/H.png";
import I from "../img/I.png";

export const ResultData = [
  {
    resultStage: "강철멘탈형",
    image: A,
  },
  {
    resultStage: "평온한바다형",
    image: B,
  },
  {
    resultStage: "잔잔한호수형",
    image: C,
  },
  {
    resultStage: "균형잡힌중립형",
    image: D,
  },
  {
    resultStage: "흔들리는중립형",
    image: E,
  },
  {
    resultStage: "위태로운중립형",
    image: F,
  },
  {
    resultStage: "조마조마형",
    image: G,
  },
  {
    resultStage: "안절부절형",
    image: H,
  },
  {
    resultStage: "불안폭발형",
    image: I,
  },
];
