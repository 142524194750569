import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Card from "./Carden";
import "./Homeen.css";
import TestList from "./TestListen.json";
import Logo from "../../img/logo.png";
import { useNavigate } from "react-router-dom";
import SEO from "./SEOen";
import { homeAttributeen } from "./seoAttributesen";
import Footer from "../../components/Footer/Footer";
import LanguageSelector from "../../LanguageSelector";

function Homeen() {
  const navigate = useNavigate();
  const [testList, setTestList] = useState([]);
  const gotoHome = () => {
    navigate("/test/en");
  };

  useEffect(() => {
    setTestList(TestList);
  }, []);

  const url = "https://zombiembti.net/test/en";

  return (
    <div className="Homeen">
      <div className="Homeen_contents">
        <SEO attribute={homeAttributeen} url={url} />
        <header className="Homeen-header">
          <div onClick={gotoHome} className="logo-container">
            <img src={Logo} alt="logo" />
            <h2 className="logo-title">TAKEFIVE TEST</h2>
          </div>
          <p className="sub-title">
            psychological test, mbti test, <br /> personality test, Type test,
            love test ETC.
          </p>
        </header>
        <LanguageSelector />
        <div className="TestListen">
          {testList.map((test) => (
            <Link key={test.id} to={`/test/en/${test.id}`}>
              <Card test={test} />
            </Link>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Homeen;
