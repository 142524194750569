import React, { useEffect } from "react";
import "./SchoolMain.css";
import School_Sample from "./img/main.png";
import { useNavigate } from "react-router-dom";
import homeBackImg from "./img/homeBackImg.png";
import ReactGA from "react-ga";
import Take5 from "../../../../components/Take5/Take5";
import SEO from "../../SEOko";
import { schoolAttributeko } from "../../seoAttributesko";

const SchoolMain = () => {
  ReactGA.pageview(window.location.pathname);
  const navigate = useNavigate();
  const startHandler = () => {
    navigate("/test/school/question");
  };

  const id = "school";

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <div className="School_Main_Wrapper">
        <SEO attribute={schoolAttributeko} id={id} />
        <Take5 />
        <div className="School_Main_Contents">
          <div className="School_Main_BackImg">
            <img src={homeBackImg} className="backimg" alt="" />
          </div>
          <div className="School_Main_Title">초딩 MBTI</div>
          <div className="School_Main_SubTitle">나는 어떤 초딩이었을까?</div>
          <div className="School_Main_SampleImg">
            <img src={School_Sample} alt="School_Sample" />
          </div>
          <div className="School_Main_Desc">
            옛 기억을 떠올리며 테스트해보세요! 👀
          </div>
          <button className="School_Main_Start_Btn" onClick={startHandler}>
            시작하기
          </button>
        </div>
      </div>
    </>
  );
};

export default SchoolMain;
