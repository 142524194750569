import React, { useState } from "react";
import "./TheHouseMainen.css";
import { useNavigate } from "react-router-dom";
import main from "./img/lovethink_Main.png";
import Take5 from "../../../../components/Take5/Take5";
import SEO from "../../SEOen";
import { thehouseAttributeen } from "../../seoAttributesen";
import AdSenseComponent from "../../../../AdSenseComponent";

const TheHouseMainen = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");

  const handleChange = (e) => {
    setName(e.target.value);
  };

  const QPage = () => {
    if (name.trim() === "") {
      alert("이름을 입력해주세요");
    } else {
      navigate("/test/en/thehouseen/question", { state: { name } });
    }
  };

  const id = "thehouseen";

  return (
    <div className="thehouseen_wrapper">
      <div className="thehouseen_contents">
        <SEO attribute={thehouseAttributeen} id={id} />
        <Take5 />
        <div className="thehouseen_main_container">
          <img src={main} alt="main" />
          <div className="thehouseen_input_container">
            <input
              type="text"
              placeholder="Please Enter Your Name"
              value={name}
              onChange={handleChange}
              className="thehouseen_name_input"
            />
          </div>
          <div className="thehouseen_btn_container">
            <button onClick={QPage} className="thehouseen_start_btn">
              Get Start!
            </button>
          </div>
        </div>
      </div>
      <AdSenseComponent />
    </div>
  );
};

export default TheHouseMainen;
