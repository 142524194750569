export const QuestionData = [
  {
    id: 1,
    title:
      "어느날 갑자기 좀비 바이러스가 퍼졌다. \n 오마이갓... 이게 무슨 일이야? \n 이럴 때는 뭉쳐야 산다!",
    answera: "생존자들이 모여있는 대피소로 가자!",
    answerb: "아는 사람들과 거점을 꾸리자!",
    type: "EI",
  },
  {
    id: 2,
    title: "다같이 모여있는 상황. \n 생활공간을 나누자는 의견이 오가는데...",
    answera: "따로 다니면 위험하지 않을까? \n 무서우니까 모여있자ㅠㅠ",
    answerb:
      "그래도 각자 공간은 필요하지 않을까? \n 적당히 떨어져있는 게 좋겠어.",
    type: "EI",
  },
  {
    id: 3,
    title: "신입 받아라! \n 새로운 생존자들이 들어왔다. \n 이때 내 행동은?",
    answera:
      "궁금한 게 너무 많아! \n 어디서 왔어요? 오는 길에 좀비 많았나요? \n 이것저것 다 물어본다.",
    answerb:
      "어차피 시간이 지나면 다 알게 될 것들... \n 잠자코 내 할 일이나 한다.",
    type: "EI",
  },
  {
    id: 4,
    title: "무기가 가득한 곳을 찾았다! \n 내가 선택할 무기는?",
    answera: "실용적인 게 최고지. \n 아주 튼튼해 보이는 야구방망이!",
    answerb: "재료가 충분한데? \n 이것저것 들고가서 신무기를 만들어 낸다.",
    type: "SN",
  },
  {
    id: 5,
    title: `잠이 안 오는 늦은 밤. \n 생존자 한 명이 말을 걸어온다. \n "무슨 생각해?"`,
    answera: "내일 해야 할 일을 좀 생각하고 있었어.",
    answerb: "그냥.. 언제쯤이면 좀비사태가 끝날까?",
    type: "SN",
  },
  {
    id: 6,
    title:
      "우리 거점이 좀비한테 점령 당했어! \n 다행히 다른 대피소에서 우리를 받아줬는데... \n 규칙이 좀 엄격하네. \n 이때 나는?",
    answera: "규칙을 숙지하고 \n최대한 잘 지키려고 노력한다.",
    answerb: "규칙을 참고하되 내 나름대로 생활한다.",
    type: "SN",
  },
  {
    id: 7,
    title: "동료가 다쳤어. \n 이때 내가 할 말은?",
    answera: `"좀비한테 물린 거야? 아니면 다행이고. \n얼른 치료 받고 와!"`,
    answerb: `"괜찮아? 많이 아파? (계속 걱정한다)"`,
    type: "TF",
  },
  {
    id: 8,
    title:
      "알고보니 그 사람이 좀비한테 물린 거였어. \n 처분을 다수결로 정한대. \n어떻게 하지?",
    answera: `나머지는 살아야지. \n "나가주시겠어요?"`,
    answerb: `함께 버텨 온 동료인데 바로 내보낼 순 없어... \n "조금만 더 지켜보면 안 될까?"`,
    type: "TF",
  },
  {
    id: 9,
    title:
      "늦은 밤, \n 생존자들이 내 얘기를 하는 걸 들었어. \n 이때 더 듣기 좋은 말은?",
    answera:
      "걔 진짜 똑부러지지 않냐. \n 할 줄 아는 것도 많고 능력자야. \n 걔가 있어서 든든해.",
    answerb:
      "걔 사람이 참 좋더라. \n 나는 얘랑 끝까지 함께 하고 싶어. \n 얘는 믿을 수 있어.",
    type: "TF",
  },
  {
    id: 10,
    title:
      "거점이 좀비들에게 노출됐어. \n 이제 장소를 옮겨야 해! \n 근데... 어디로 가지?",
    answera:
      "저번에 식량 구하러 갔던 A마트로 가자! \n 그때 써먹었던 좀비 대비책을 \n더 꼼꼼하게 짜서 옮기는 거야.",
    answerb:
      "B마트가 더 크니까 거기로 가자! \n 위험을 감수하고서라도 갈 가치가 있잖아? \n 돌발 상황은 그때 대처하는 거야.",
    type: "JP",
  },
  {
    id: 11,
    title:
      "가족들이 살아있다는 정보를 입수했다. \n 그리 멀지 않은 곳인데... \n이때 내 행동은?",
    answera:
      "차질없이 구출해야 하니까 계획을 먼저 짜자. \n 이동과 탈출 경로, 플랜B를 짜고 \n준비물도 체크하자.",
    answerb:
      "지금 당장 구하러 가야 하는데! \n 계획은 큰 틀만 잡고 곧장 떠나자!",
    type: "JP",
  },
  {
    id: 12,
    title:
      "구출 성공! 이제 귀환만 남겨둔 상황. \n 서둘러 거점으로 향하는데... 저건 군대? \n 생존자들을 안전하게 피신 시키고 있대. \n 이때 내 행동은?",
    answera:
      "동료들에게 꼭 돌아오겠다고 했으니 \n 귀환이 우선이다. \n 거점까지 동행을 부탁한다.",
    answerb:
      "우선 피신한다. \n 거점 위치를 알려줬으니 \n동료들도 곧 만날 수 있겠지?",
    type: "JP",
  },
];
