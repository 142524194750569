import React, { useState, useEffect } from "react";
import Footer from "../../../../components/Footer/Footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import { resultData } from "./data/resultData";
import "./MoodResulten.css";
import ReactGA from "react-ga";
import { MoonLoader } from "react-spinners";
import EnShareButton from "./EnShareButton.js";
import Take5 from "../../../../components/Take5/Take5";
import Card from "../../Carden";
import TestList from "../../TestListen.json";
import AliComponent from "../../../AliComponent";

const MoodResulten = () => {
  const navigate = useNavigate();
  const { mbti } = useParams();
  const [data, setData] = useState({});
  const [testList, setTestList] = useState([]);
  const [showResult, setShowResult] = useState(false);
  const [hasCookie, setHasCookie] = useState(false);

  const excludedTestId = "yourmooden";
  const filteredTestList = testList.filter(
    (test) => test.id !== excludedTestId
  );

  useEffect(() => {
    // 결과를 찾을 때 동적으로 읽어온 MBTI 유형을 사용
    const result = resultData.find((s) => s.best === mbti);
    setData(result);
    ReactGA.pageview(window.location.pathname);
  }, [mbti]);

  useEffect(() => {
    setTestList(TestList);
  }, []);

  const reloadBtn = () => {
    navigate("/test/en/yourmooden");
  };

  useEffect(() => {
    const result = resultData.find((s) => s.best === mbti);
    setData(result);
    ReactGA.pageview(window.location.pathname);
  }, [mbti]);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3500);
  }, []);

  useEffect(() => {
    setHasCookie(document.cookie.indexOf("Ali=true") !== -1);
  }, []);

  const handleClick = () => {
    setShowResult(true);
    handleResultShow();
  };

  const handleResultShow = () => {
    const resultContainer = document.querySelector(".Ali_Test_no_cookie");
    resultContainer.style.overflow = "visible";
    resultContainer.style.height = "100%";
  };

  return (
    <>
      <div className="mooden_result_container">
        <div className="mooden_R_Wrapper">
          {loading ? (
            <MoonLoader
              color={"#754826"}
              loading={loading}
              size={90}
              cssOverride={{
                marginTop: "15rem",
                marginBottom: "20rem",
                backgroundColor: "transparent",
              }}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          ) : (
            <>
              <Take5 />
              {hasCookie ? (
                <>
                  <div className="All_Test_has_cookie">
                    <h1 className="mooden_result_main_text">Your Mood TEST</h1>

                    <h1 className="mooden_result_sub_text">
                      What is my atmosphere
                      <br /> that others see...
                    </h1>
                    <div className="mooden_result_image">
                      <img src={data.image} alt={data.title} />
                    </div>
                    <div className="mooden_result_sub_title">{data.title}</div>
                    <div className="mooden_desc_container">
                      <div className="mooden_result_desc">{data.desc}</div>
                    </div>
                    <div className="mooden_result_btn_container">
                      <button
                        className="mooden_restart_btn"
                        onClick={reloadBtn}
                      >
                        DO IT AGAIN!
                      </button>

                      <EnShareButton />
                      <div className="TestList_container">
                        {filteredTestList.map((test) => (
                          <div key={test.id} className="TestList_container2">
                            <Link to={`/test/en/${test.id}`}>
                              <Card test={test} />
                            </Link>
                          </div>
                        ))}
                      </div>
                      <Footer />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="Ali_Test_no_cookie_container">
                    <div className="Ali_Test_no_cookie">
                      <h1 className="mooden_result_main_text">
                        Your Mood TEST
                      </h1>
                      <h1 className="mooden_result_sub_text">
                        What is my atmosphere
                        <br /> that others see...
                      </h1>
                      <div className="mooden_result_image">
                        <img src={data.image} alt={data.title} />
                      </div>
                      <div className="mooden_Ali_div">
                        <AliComponent handleClick={handleClick} />
                      </div>
                      {showResult && (
                        <div className="mooden_showResult">
                          <div className="mooden_result_sub_title">
                            {data.title}
                          </div>
                          <div className="mooden_result_desc">{data.desc}</div>
                          <div className="mooden_result_btn_container">
                            <button
                              className="mooden_restart_btn"
                              onClick={reloadBtn}
                            >
                              Do it again
                            </button>

                            <EnShareButton />
                            <div className="TestList_container">
                              {filteredTestList.map((test) => (
                                <div
                                  key={test.id}
                                  className="TestList_container2"
                                >
                                  <Link to={`/test/en/${test.id}`}>
                                    <Card test={test} />
                                  </Link>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default MoodResulten;
