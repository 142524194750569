import React from "react";
import { useNavigate } from "react-router-dom";
import "./Cardko.css";
import AdSenseComponent from "../../AdSenseComponent";

function Cardko(props) {
  const navigate = useNavigate();

  function handleCardClick() {
    if (props.test && props.test.id) {
      navigate(`/test/${props.test.id}`);
    }
  }

  if (!props.test) {
    return null;
  }

  return (
    <div className="cardko_container">
      <div className="cardko" onClick={handleCardClick}>
        {props.test.image && (
          <img src={props.test.image} alt={props.test.title} />
        )}
        <div className="cardko-text">
          <h2 className="cardko-title">{props.test.title}</h2>
          <p className="cardko-desc">{props.test.desc}</p>
        </div>
      </div>
      <AdSenseComponent />
    </div>
  );
}

export default Cardko;
