export const questionData = [
  {
    id: 1,
    title: "첫인상에 대해 주로 들었던 말은?",
    answera: "활발하다",
    answerb: "차분하다",
    type: "EI",
  },
  {
    id: 2,
    title: "어떤 하루가 더 편안한가요?",
    answera: "사람들과 소통하며 \n 바쁘게 지내는 하루",
    answerb: "혼자 조용히 내 할 일하며 \n 지내는 하루",
    type: "EI",
  },
  {
    id: 3,
    title: "새로운 사람을 만날 때 \n 어떤 태도를 가지고 있나요?",
    answera: "대화를 이끌어가는 역할을 \n 하려고 한다",
    answerb: "어느정도 거리를 두고 지켜본다",
    type: "EI",
  },
  {
    id: 4,
    title: "당신이 주로 받는 스트레스는 \n 어떤 종류인가요?",
    answera: "빠른 시일 내에 선택해야 하는 일들",
    answerb: "아직 일어나지 않았지만 \n 곧 닥칠 것 같은 일들",
    type: "SN",
  },
  {
    id: 5,
    title: "당신은 상상이나 공상을 \n 자주 하는 편인가요?",
    answera: "아니요",
    answerb: "네",
    type: "SN",
  },
  {
    id: 6,
    title: "이야기를 남에게 말할 때 \n 주로 어떤 편인가요?",
    answera: "일의 순서만 일목요연하게 \n 차근차근 얘기한다",
    answerb:
      "일의 순서를 말하다가 중간중간 \n 내 생각과 느낌을 비유해서 말한다",
    type: "SN",
  },
  {
    id: 7,
    title:
      "친구가 실수로 당신의 물건을 \n 망가뜨렸습니다.\n  어떤 사과에 마음이 더 풀리나요?",
    answera: "일의 경위에 대해 설명하는 것",
    answerb: "진심을 담아 사과하는 것",
    type: "TF",
  },
  {
    id: 8,
    title: "어떤 것을 더 추구하나요?",
    answera: "논리, 냉철한 분석",
    answerb: "감정, 감성적인 표현",
    type: "TF",
  },
  {
    id: 9,
    title:
      "지인에게 오랜만에 카톡을 보냈는데 \n 어쩐지 답장이 오질 않네요. \n 이때 드는 생각은?",
    answera: "핸드폰 번호 바뀌었나?",
    answerb: "나한테 화난 거 있나?",
    type: "TF",
  },
  {
    id: 10,
    title: "당신의 뜻대로 흘러가지 않은 하루. \n 어떤 생각을 할까요?",
    answera: "(...너무 짜증나서\n 빨리 하루를 끝내고 싶음)",
    answerb: "(에잇 오늘 좀 안 풀리네. \n 이런 날도 있는 거지, 뭐)",
    type: "JP",
  },
  {
    id: 11,
    title: "계획을 짤 때 플랜B, C까지 \n 생각하는 편인가요?",
    answera: "네",
    answerb: "아니오",
    type: "JP",
  },
  {
    id: 12,
    title: "불확실한 가능성에 자신을 \n 거리낌없이 내던질 수 있나요?",
    answera: "꼭 해야 한다면 하겠지만... 굳이?",
    answerb: "재미있어 보인다면 당장 한다",
    type: "JP",
  },
];
