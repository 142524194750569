import React, { useEffect } from "react";
import "./RP2Main.css";
import { useNavigate } from "react-router-dom";
import main from "./img/rp_main2.png";
import startBtn from "./img/rp_start_btn.png";
import Take5 from "../../../../components/Take5/Take5";
import SEO from "../../SEOko";
import { rollingPaper2Attributeko } from "../../seoAttributesko";
import AdSenseComponent from "../../../../AdSenseComponent";

const RP2Main = () => {
  const navigate = useNavigate();
  const rpGotoQ = () => {
    navigate("/test/rollingpaper2/question");
  };

  const id = "rollingpaper2";

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="rollingpaper2_wrapper">
      <div className="rollingpaper2_contents">
        <SEO attribute={rollingPaper2Attributeko} id={id} />
        <Take5 />
        <div className="rollingpaper2_main_container">
          <img src={main} alt="main" />
          <div onClick={rpGotoQ} className="rollingpaper2_start_btn_container">
            <img src={startBtn} alt="startBtn" />
          </div>
        </div>
      </div>
      <AdSenseComponent />
    </div>
  );
};

export default RP2Main;
