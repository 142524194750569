import React, { useState } from "react";
import questionsData from "./data/QuestionData.json";
import { useNavigate } from "react-router-dom";
import "./NojamQuestion.css";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import template from "./img/template.png";
import Take5 from "../../../../components/Take5/Take5";

function NojamQuestion() {
  const navigate = useNavigate();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [userAnswers, setUserAnswers] = useState([]);
  const questions = questionsData.questions;

  const handleSubmit = (e) => {
    if (e) e.preventDefault();
    const totalScore = userAnswers.reduce((acc, curr) => {
      const selectedOption = questions[curr.questionId].options.find(
        (option) => option.id === curr.optionId
      );
      return acc + selectedOption.score;
    }, 0);

    const percentage = Math.floor((totalScore / (5 * questions.length)) * 100);
    navigate("/test/nojamlife/result", { state: { percentage: percentage } });
  };

  const handleAnswerSelect = (optionId) => {
    setUserAnswers([...userAnswers, { questionId: currentQuestion, optionId }]);
    if (currentQuestion + 1 === questions.length) {
      handleSubmit();
    } else {
      setCurrentQuestion(currentQuestion + 1);
    }
  };

  return (
    <div className="Nojam_Q_Wrapper">
      <Take5 />
      <Progress
        percent={(currentQuestion / questions.length) * 100}
        status="error"
        theme={{
          error: {
            symbol: "🫠",
            color: "#ffe06e",
          },
        }}
      />
      <div>
        <div className="Nojam_Q_Contents">
          <img src={template} alt="template" />
          <div className="Nojam_Q_Container">
            <h2 className="Nojam_Q_title">
              {questions[currentQuestion].question}
            </h2>
            <div className="Nojam_Q_Options">
              {questions[currentQuestion].options.map((option) => (
                <div
                  key={option.id}
                  className="Nojam_Q_option"
                  onClick={() => handleAnswerSelect(option.id)}
                >
                  <p>{option.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NojamQuestion;
