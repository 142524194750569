export const homeAttributejp = {
  name: "TAKEFIVE TEST",
  imageUrl:
    "https://testlist-img.s3.ap-northeast-2.amazonaws.com/%E1%84%90%E1%85%A6%E1%84%8B%E1%85%B5%E1%84%8F%E1%85%B3%E1%84%91%E1%85%A1%E1%84%8B%E1%85%B5%E1%84%87%E1%85%B3_%E1%84%86%E1%85%A6%E1%84%90%E1%85%A1.png",
  desc: "心理テスト, MBTIテスト, 性格診断, タイプテスト, 愛情テスト, その他",
};

export const catlovesmeAttributejp = {
  name: "猫の愛情度テスト",
  imageUrl:
    "https://snackcat.click/static/media/CatlovesMejp_Sample.ff6883a376335b2864a6.png",
  desc: "うちの猫は私のことをどれくらい好きなんだろう?",
};

export const animalAttributejp = {
  name: "動物タロットMBTI",
  imageUrl:
    "https://snackcat.click/static/media/animaltarotjp_Sample.9063423ee41944de77c0.png",
  desc: "私のイメージに似ている動物は何でしょうか？",
};

export const keywordMbtiAttributejp = {
  name: "キーワード MBTI",
  imageUrl:
    "https://snackcat.click/static/media/KeywordMbtijp_Sample.919c23f6cc9562f447cd.png",
  desc: "私の性格を表すキーワードは何ですか?",
};

export const realloveAttributejp = {
  name: "真実の愛 MBTI",
  imageUrl:
    "https://snackcat.click/static/media/Reallovejp_Sample.73b365cf2c69c755f67d.png",
  desc: "本当に愛しているとき、私の本当の姿はどんな感じですか?💗",
};

export const Rollingpaper2Attributejp = {
  name: "ローリングペーパー MBTI - Love",
  imageUrl:
    "https://snackcat.click/static/media/Rollingpaper2jp_Sample.712078fcc7b6c54029aa.png",
  desc: "ローリングペーパーによると、私のデートスタイルはどのようになるのでしょうか？",
};

export const RollingpaperAttributejp = {
  name: "ローリングペーパー MBTI - 友情編",
  imageUrl:
    "https://snackcat.click/static/media/Rollingpaperjp_Sample.960d20cbd12e884885e2.png",
  desc: "私のローリングペーパーにはどんな言葉が書かれているでしょうか?",
};

export const yourmoodAttributejp = {
  name: "あなたの気分 テスト",
  imageUrl:
    "https://snackcat.click/static/media/Yourmoodjp_Sample.23ffe9389a7ea96c4329.png",
  desc: "他人が見る私の雰囲気は何でしょうか？",
};

export const zombieAttributejp = {
  name: "ゾンビワールド MBTI",
  imageUrl:
    "https://snackcat.click/static/media/Zombiejp_Sample.1853123f02c75322b39f.png",
  desc: "ゾンビ危機に対処する私の性格は？🧟",
};
