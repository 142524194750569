import React from "react";
import "./KeywordMbtiR.css";
import Footer from "../../../../components/Footer/Footer";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { ResultData } from "./data/ResultData";
import ShareButton from "./ShareButton";
import { SyncLoader } from "react-spinners";
import ReactGA from "react-ga";
import Take5 from "../../../../components/Take5/Take5";
import CoupangBanner from "../../CoupangBanner/CoupangBanner";
import Card from "../../Cardko";
import TestList from "../../TestListko.json";
import CoupangPromotionCP from "../../CoupangPromotionCP/CoupangPromotionCP";
// import AdSenseComponent from "../../../../AdSenseComponent";
import InnerCoupang from "../../InnerCoupang/InnerCoupang";

const KeywordMbtiR = () => {
  const { mbti } = useParams();
  const [resultData, setResultData] = useState({});
  const navigate = useNavigate();
  const [showResult, setShowResult] = useState(false);
  const [hasCookie, setHasCookie] = useState(false);
  const [testList, setTestList] = useState([]);

  const excludedTestId = "keywordMbti";
  const filteredTestList = testList.filter(
    (test) => test.id !== excludedTestId
  );

  useEffect(() => {
    setTestList(TestList);
  }, []);

  useEffect(() => {
    setHasCookie(document.cookie.indexOf("coupang=true") !== -1);
  }, []);

  const handleClick = () => {
    setShowResult(true);
    handleResultShow();
  };

  const handleResultShow = () => {
    const resultContainer = document.querySelector(".All_Test_no_cookie");
    resultContainer.style.overflow = "visible";
    resultContainer.style.height = "100%";
  };

  useEffect(() => {
    // 결과를 찾을 때 동적으로 읽어온 MBTI 유형을 사용
    const result = ResultData.find((s) => s.best === mbti);
    setResultData(result);
    ReactGA.pageview(window.location.pathname);
  }, [mbti]);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, []);

  return (
    <>
      <div className="keywordMbti_R_Wrapper">
        {loading ? (
          <div className="keywordMbti_loading_container">
            <p className="keywordMbti_loading_text">
              🔎
              <br />
              키워드 검색중...
            </p>
            <SyncLoader
              color={"#003fee"}
              loading={loading}
              size={9}
              speedMultiplier={0.5}
              cssOverride={{
                marginTop: "3rem",
                backgroundColor: "transparent",
              }}
              aria-label="Loading Spinner"
              data-testid="loader"
            />

            <CoupangPromotionCP />
            {/* <AdSenseComponent /> */}
          </div>
        ) : (
          <>
            <Take5 />
            {hasCookie ? (
              <>
                <div className="All_Test_has_cookie">
                  <div className="keywordMbti_R_Contents">
                    <div
                      className="keywordMbti_R_ResultImg_Container"
                      id="coupangDiv"
                    >
                      <img src={resultData.image} alt="mbti" />
                    </div>
                    <div className="keywordMbti_IC_container">
                      <InnerCoupang />
                    </div>
                    <div className="keywordMbti_R_Restart_Btn_Container">
                      <button
                        className="keywordMbti_R_Restart_Btn"
                        style={{
                          minWidth: "210px",
                          maxWidth: "210px",
                          minHeight: "50px",
                          boxShadow: "none",
                          border: "1.5px solid #003fee",
                        }}
                        onClick={() => navigate("/test/keywordMbti")}
                      >
                        테스트 다시하기
                      </button>
                    </div>
                    <ShareButton />
                    <div className="TestList_container">
                      {filteredTestList.map((test) => (
                        <div key={test.id} className="TestList_container2">
                          <Link to={`/test/${test.id}`}>
                            <Card test={test} />
                          </Link>
                        </div>
                      ))}
                    </div>
                    <Footer />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="All_Test_no_cookie_container">
                  <div className="All_Test_no_cookie">
                    <div className="keywordMbti_R_Contents">
                      <div
                        className="keywordMbti_R_ResultImg_Container"
                        id="coupangDiv"
                      >
                        <img src={resultData.image} alt="mbti" />
                      </div>
                      <div className="keywordMbti_Coupang_div">
                        <CoupangBanner handleClick={handleClick} />
                        {/* <AdSenseComponent /> */}
                      </div>
                      {showResult && (
                        <>
                          <div className="keywordMbti_IC_container">
                            <InnerCoupang />
                          </div>
                          <div className="keywordMbti_R_Restart_Btn_Container">
                            <button
                              className="keywordMbti_R_Restart_Btn"
                              style={{
                                minWidth: "210px",
                                maxWidth: "210px",
                                minHeight: "50px",
                                boxShadow: "none",
                                border: "1.5px solid #003fee",
                              }}
                              onClick={() => navigate("/test/keywordMbti")}
                            >
                              테스트 다시하기
                            </button>
                          </div>
                          <ShareButton />
                          <div className="TestList_container">
                            {filteredTestList.map((test) => (
                              <div
                                key={test.id}
                                className="TestList_container2"
                              >
                                <Link to={`/test/${test.id}`}>
                                  <Card test={test} />
                                </Link>
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default KeywordMbtiR;
