import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BiRightArrowAlt } from "react-icons/bi";
import Take5 from "../../../../components/Take5/Take5";
import SEO from "../../SEOko";
import "./Pick2024Main.css";
import { pick2024Attributeko } from "../../seoAttributesko";
import pick2024_main from "./img/pick2024_main.png";
import characters from "./data/characters.json";
import AdSenseComponent from "../../../../AdSenseComponent";

function Pick2024Main() {
  const [name, setName] = useState("");
  const [birthdate, setBirthdate] = useState("");
  const [gender, setGender] = useState("female");
  const navigate = useNavigate();

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleBirthdateChange = (event) => {
    const inputText = event.target.value;
    const numericValue = inputText.replace(/\D/g, "");
    const formattedValue = numericValue.substring(0, 8);
    const formattedDate = formattedValue.replace(
      /^(\d{4})(\d{2})(\d{2})$/,
      "$1.$2.$3"
    );
    setBirthdate(formattedDate);
    event.target.value = formattedDate;
  };

  const handleGenderChange = (event) => {
    const selectedGender = event.target.value;
    setGender(selectedGender);
  };

  const handleScoreCalculation = () => {
    // Validate inputs
    if (!name.trim()) {
      alert("이름을 입력해주세요");
      return;
    }

    if (/[ㄱ-ㅎㅏ-ㅣ]/.test(name)) {
      alert("이름을 정확히 입력해주세요");
      return; // 함수 종료
    } else if (name.length === 0) {
      alert("이름을 입력해주세요");
      return;
    } else if (!/[^a-zA-Z]/.test(name)) {
      alert("한글 이름을 입력해주세요");
      return;
    }

    if (!birthdate) {
      alert("생년월일을 입력해주세요");
      return;
    }

    const nameCharacters = [...name];
    const calculatedScores = nameCharacters.map((char) => {
      return {
        character: char,
        score: characters[char]?.score || 0,
      };
    });

    const totalScore = calculatedScores.reduce(
      (acc, cur) => acc + cur.score,
      0
    );

    const targetScores = [
      ((totalScore + 3) % 70) + 1,
      ((totalScore + 10) % 70) + 1,
      ((totalScore - 5) % 70) + 1,
    ];

    navigate("/test/pick2024/result", {
      state: { targetScores, name, gender },
    });
  };

  return (
    <div className="pick2024_wrapper">
      <div className="pick2024_contents">
        <SEO attribute={pick2024Attributeko} id="pick2024" />
        <Take5 />
        <div className="pick2024_mainImg_container">
          <img
            src={pick2024_main}
            className="pick2024_main_img"
            alt="angel_main"
          />
        </div>

        <div className="pick2024_main_container">
          <input
            className="pick2024_input"
            type="text"
            placeholder="생년월일을 입력해주세요"
            value={birthdate}
            onChange={handleBirthdateChange}
          />

          <input
            className="pick2024_input"
            type="text"
            placeholder="이름을 입력해주세요"
            value={name}
            onChange={handleNameChange}
          />

          <div className="pick2024_gender_container">
            <label style={{ display: "block", lineHeight: "40px" }}>
              <input
                type="checkbox"
                className="gender_input"
                name="gender"
                value="female"
                checked={gender === "female"}
                onChange={handleGenderChange}
              />
              여자
              <br />
              <input
                type="checkbox"
                className="gender_input"
                name="gender"
                value="male"
                checked={gender === "male"}
                onChange={handleGenderChange}
              />
              남자
            </label>
          </div>
          <p className="pick2024_alert">
            개인정보는 해당 테스트 이외의 용도로 사용되지 않습니다
          </p>
          <button className="pick2024_btn" onClick={handleScoreCalculation}>
            <BiRightArrowAlt style={{ fontSize: "35px" }} />
          </button>
        </div>
        <AdSenseComponent />
      </div>
    </div>
  );
}

export default Pick2024Main;
