export const QuestionData = [
  {
    id: 1,
    title: "平日は忙しかった。\n週末が近づくと...",
    answera: "ストレス発散が必要だ！外出して楽しむ。",
    answerb: "家が最高...一人でいる。",
    type: "EI",
  },
  {
    id: 2,
    title: "友達と遊んでいて、\n友達が『友達も誘ってもいい？』と聞いたら",
    answera: "君の友達は僕の友達！誘って！",
    answerb: "今度遊ぼう！",
    type: "EI",
  },
  {
    id: 3,
    title: "友達の中で僕の役割は...",
    answera: "よくしゃべる方だ。",
    answerb: "主にリスナー。",
    type: "EI",
  },
  {
    id: 4,
    title: "友達が\n『なぜ僕たちは生まれてきたのか？』と\n言ったら",
    answera: "? まあ、ただ生まれてきただけだよ... \n何かあったのかな？",
    answerb: "人間の存在意義についての論争に参加する。",
    type: "SN",
  },
  {
    id: 5,
    title: "好きな歌詞のスタイルは...",
    answera: "日常生活と現実に共鳴する歌詞。",
    answerb:
      "どうやってそのような歌詞を思いつくのか？想像力と感嘆を喚起する歌詞。",
    type: "SN",
  },
  {
    id: 6,
    title: "何かを説明するとき、僕は...",
    answera: "端的な事実で表現する、飾り気はない。",
    answerb: "さまざまな比喩を使って豊かに説明する。",
    type: "SN",
  },
  {
    id: 7,
    title: "『本当に好きだよ！』と言われたら",
    answera:
      "なぜ？君は僕のどの部分が好きなのか気になるなあ（本当に好奇心が強い）。",
    answerb: "まさか... 本当に？（それは感動的だね）",
    type: "TF",
  },
  {
    id: 8,
    title: "『ちょうど車の事故にあった』と言ったら",
    answera: "それは気をつけておくべきだ、\nまず対処しなければならないから。",
    answerb: "今の状態について聞いてくれますか？",
    type: "TF",
  },
  {
    id: 9,
    title:
      "誰かが『適当にやっているように見えるけど、\nなぜそんなにうまいのか？』と言ったら",
    answera: "（それは褒め言葉として受け取る）。",
    answerb: "（一生懸命やったのに、\n適当にやっていると言われると傷つく）。",
    type: "TF",
  },
  {
    id: 10,
    title: "旅行を計画するとき...",
    answera: "時間と日程の効率が最優先。",
    answerb: "足が向くままにどこへでも行く。",
    type: "JP",
  },
  {
    id: 11,
    title: "僕の理想の日常生活は...",
    answera: "安定した日常生活、ルーティン。",
    answerb: "常に新しく多様な日常生活。",
    type: "JP",
  },
  {
    id: 12,
    title: "食事の後、僕は...",
    answera: "すぐに洗い物をする。",
    answerb: "後でやる。",
    type: "JP",
  },
];
