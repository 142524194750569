export const QuestionData = [
  {
    id: 1,
    title: "좋아하는 사람과 \n함께 하고 싶은 것은?",
    answera: "많은 곳을 다니면서 추억 남기기",
    answerb: "둘만의 오붓한 시간 보내기",
    type: "EI",
  },
  {
    id: 2,
    title: "내가 선호하는 모임은?",
    answera: "새로운 사람들과 만날 수 있는 모임",
    answerb: "편한 사람들과 만나는 모임",
    type: "EI",
  },
  {
    id: 3,
    title: "내가 에너지를 충전하는 법은?",
    answera: "사람들과 어울리면서 충전하는 편",
    answerb: "혼자 알아서 충전하는 편",
    type: "EI",
  },
  {
    id: 4,
    title: "그 사람이 신기하게 생긴 구름을 \n찍어서 보냈어. 이때 나는?",
    answera: "와~ 구름 예쁘다",
    answerb: "오! 저 구름 공룡 닮았다",
    type: "SN",
  },
  {
    id: 5,
    title: `그 사람이 "나 데스노트 가지는 \n상상해본 적 있어"라고 한다면?`,
    answera: "진짜? (상상력이 풍부하네)",
    answerb: "나도! 어떻게 죽일지도 \n다 생각해놨음ㅋㅋㅋ",
    type: "SN",
  },
  {
    id: 6,
    title: "나는 어떤 사람?",
    answera: "설명은 잘 하는데 \n머릿속으로 구상이 어려움",
    answerb: "머리로는 잘 아는데 \n말로 설명하는 게 어려움",
    type: "SN",
  },
  {
    id: 7,
    title: `나에게 '생각해 볼게'라는 의미는?`,
    answera: `진짜 생각해봄 (고심)`,
    answerb: `별론데... (에둘러 거절)`,
    type: "TF",
  },
  {
    id: 8,
    title: "어떤 문제로 힘들 때 나는...",
    answera: `문제 해결에 도움되는 말이 필요해`,
    answerb: `지금 내 상황과 감정에 공감이 필요해`,
    type: "TF",
  },
  {
    id: 9,
    title: `그 사람이 아파서 병원 다녀왔대.\n이때 내가 궁금한건?`,
    answera: "어디가 아프지?\n병원에서는 뭐라고 했을까?",
    answerb: "얼마나 힘들지? \n내가 옆에 있어야 하나?",
    type: "TF",
  },
  {
    id: 10,
    title: "함께 카페를 갔는데\n문이 닫혀있어! 이때 나는?",
    answera: "혹시 이럴까봐 다른 카페도 알아뒀음",
    answerb: "그 자리에서 다른 카페 찾아봄",
    type: "JP",
  },
  {
    id: 11,
    title: "그 사람이 손톱깎이를 찾네. \n이때 나는?",
    answera: "정확한 위치를 콕 집어 알려줌",
    answerb: "아마 이쯤에 있을텐데~ 하고 찾아봄",
    type: "JP",
  },
  {
    id: 12,
    title: "새로운 요리에 도전한 나!\n어떻게 만들까?",
    answera: "레시피를 보면서 \n그대로 따라함",
    answerb: "레시피 훑어보고 \n느낌적 느낌으로 만듦",
    type: "JP",
  },
];
