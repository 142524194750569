import React, { useEffect } from "react";
import "./RollingPaper.css";
import { useNavigate } from "react-router-dom";
import Take5 from "../../../../components/Take5/Take5";
import main from "./img/rp_main2.png";
import startBtn from "./img/rp_start_btn.png";
import SEO from "../../SEOko";
import { rollingPaperAttributeko } from "../../seoAttributesko";
import AdSenseComponent from "../../../../AdSenseComponent";

const RollingPaper = () => {
  const navigate = useNavigate();
  const rpGotoQ = () => {
    navigate("/test/rollingpaper/question");
  };

  const id = "rollingpaper";

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="rollingpaper_wrapper">
      <div className="rollingpaper_contents">
        <SEO attribute={rollingPaperAttributeko} id={id} />
        <Take5 />
        <div className="rollingpaper_main_container">
          <img src={main} alt="main" />

          <div onClick={rpGotoQ} className="rollingpaper_start_btn_container">
            <img src={startBtn} alt="startBtn" />
          </div>
        </div>
      </div>
      <AdSenseComponent />
    </div>
  );
};

export default RollingPaper;
