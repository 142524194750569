import React, { useState, createContext, useContext } from "react";

const LanguageContext = createContext();

export const useLanguage = () => {
  return useContext(LanguageContext);
};

export function LanguageProvider({ children }) {
  const [language, setLanguage] = useState("ko"); // 기본 언어 설정

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
}

export { LanguageContext };
