import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { LanguageContext } from "./LanguageContext";
import "./LanguageSelector.css";

function LanguageSelector() {
  const { language, setLanguage } = useContext(LanguageContext);
  const navigate = useNavigate();

  const handleLanguageChange = (selectedLanguage) => {
    setLanguage(selectedLanguage);
    // 언어 변경 시 해당 언어의 홈 페이지로 이동
    if (selectedLanguage === "ko") {
      // 한국어인 경우 바로 홈 페이지로 이동
      navigate("/");
    } else {
      // 다른 언어인 경우 해당 언어로 이동
      navigate(`/test/${selectedLanguage}`);
    }
  };

  return (
    <div className="language_selector_container">
      <button
        className={language === "ko" ? "active" : ""}
        onClick={() => handleLanguageChange("ko")}
      >
        <img src="/icons/ko.png" alt="한국어" />
      </button>
      <button
        className={language === "en" ? "active" : ""}
        onClick={() => handleLanguageChange("en")}
      >
        <img src="/icons/en.png" alt="English" />
      </button>
      {/* <button
        className={language === "cn" ? "active" : ""}
        onClick={() => handleLanguageChange("cn")}
      >
        <img src="/icons/cn.png" alt="中文" />
      </button> */}
      <button
        className={language === "jp" ? "active" : ""}
        onClick={() => handleLanguageChange("jp")}
      >
        <img src="/icons/ja.png" alt="日本語" />
      </button>
    </div>
  );
}

export default LanguageSelector;
