import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Take5 from "../../../../components/Take5/Take5";
import "./BigfiveQen.css";
import questions from "./data/questions.json";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import b5Q from "./img/bigfive_q.png";

const BigfiveQen = () => {
  const navigate = useNavigate();
  const [answers, setAnswers] = useState({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const handleAnswerChange = (questionId, score) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: score,
    }));
  };

  const handleNext = () => {
    const currentQuestions = questions.slice(
      currentQuestionIndex,
      currentQuestionIndex + 5
    );

    const allAnswered = currentQuestions.every(
      (q) => answers[q.id] !== undefined
    );

    if (!allAnswered) {
      alert("Please select an answer.");
      return;
    }

    if (currentQuestionIndex < questions.length - 5) {
      setCurrentQuestionIndex(currentQuestionIndex + 5);
      window.scrollTo(0, 0);
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = () => {
    if (Object.keys(answers).length === questions.length) {
      navigate("/test/en/bigfiveen/result", { state: { answers } });
    } else {
      alert("Please answer all the questions.");
    }
  };

  const currentQuestions = questions.slice(
    currentQuestionIndex,
    currentQuestionIndex + 5
  );

  const calculateProgress = () => {
    const answeredQuestions = Object.keys(answers).length;
    return Math.round((answeredQuestions / questions.length) * 100);
  };

  return (
    <div className="en_b5_Q_wrapper">
      <Take5 />
      <div className="en_b5_Q_title">
        <img src={b5Q} alt="big5" />
      </div>
      <Progress
        percent={calculateProgress()}
        theme={{
          active: {
            symbol: `🕵🏻`,
            color: "#21bfdf7a",
          },
        }}
      />
      <div className="en_b5_Q_contents">
        {currentQuestions.map((question) => (
          <div key={question.id} className="en_b5_Q_question">
            <h3>{question.question}</h3>
            <div className="en_b5_Q_options">
              {question.options.map((option, index) => (
                <label
                  key={index}
                  className={`en_b5_Q_option_label ${
                    answers[question.id] === option.score ? "selected" : ""
                  }`}
                >
                  <input
                    type="radio"
                    name={`question-${question.id}`}
                    value={option.score}
                    onChange={() =>
                      handleAnswerChange(question.id, option.score)
                    }
                    checked={answers[question.id] === option.score}
                  />
                  {option.text}
                </label>
              ))}
            </div>
          </div>
        ))}
        <button onClick={handleNext} className="en_b5_Q_next_btn">
          {currentQuestionIndex < questions.length - 5 ? "Next" : "Submit"}
        </button>
      </div>
    </div>
  );
};

export default BigfiveQen;
