import React from "react";
import "./CatlovesMeMainen.css";
import { useNavigate } from "react-router-dom";
import main from "./img/catloveme_Main.png";
import Take5 from "../../../../components/Take5/Take5";
import SEO from "../../SEOen";
import { catlovesmeAttributeen } from "../../seoAttributesen";
import AdSenseComponent from "../../../../AdSenseComponent";

const CatlovesMeMainen = () => {
  const navigate = useNavigate();

  const QPage = () => {
    navigate("/test/en/catlovesmeen/question");
  };

  const id = "catlovesmeen";

  return (
    <div className="en_clm_wrapper">
      <div className="en_clm_contents">
        <SEO attribute={catlovesmeAttributeen} id={id} />
        <Take5 />
        <div className="en_clm_main_container">
          <img src={main} alt="main" />
          <button onClick={QPage} className="en_clm_start_btn">
            Let's Go!
          </button>
        </div>
      </div>
      <AdSenseComponent />
    </div>
  );
};

export default CatlovesMeMainen;
