import React from "react";
import { useNavigate } from "react-router-dom";
import "./Carden.css";
import AdSenseComponent from "../../AdSenseComponent";

function Carden(props) {
  const navigate = useNavigate();

  function handleCardClick() {
    if (props.test && props.test.id) {
      navigate(`/test/en/${props.test.id}`);
    }
  }

  if (!props.test) {
    return null;
  }

  return (
    <div className="carden_container">
      <div className="carden" onClick={handleCardClick}>
        {props.test.image && (
          <img src={props.test.image} alt={props.test.title} />
        )}
        <div className="carden-text">
          <h2 className="carden-title">{props.test.title}</h2>
          <p className="carden-desc">{props.test.desc}</p>
        </div>
      </div>
      <AdSenseComponent />
    </div>
  );
}

export default Carden;
