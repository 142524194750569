export const QuestionData = [
  {
    id: 1,
    title: "친구들 사이에서 \n내 포지션은?",
    answera: "조잘조잘 말하는 편",
    answerb: "주로 듣는 편",
    type: "EI",
  },
  {
    id: 2,
    title: "친구랑 노는 중인데, 친구가 \n“내 친구 불러도 돼?”라고 한다면?",
    answera: "니 친구가 내 친구지! 불러!",
    answerb: "ㅎ담에 놀자...!",
    type: "EI",
  },
  {
    id: 3,
    title: "이제 집에 가려는데 \n잘 모르는 친구랑 같은 방향이야. \n이때 나는?",
    answera: "같이 가도 상관없음",
    answerb: "사실 혼자 가고 싶음",
    type: "EI",
  },
  {
    id: 4,
    title: "나는 어떤 사람?",
    answera: "설명은 잘 하는데 \n머릿속으로 구상이 어려움",
    answerb: "머리로는 잘 아는데 \n말로 설명하는 게 어려움",
    type: "SN",
  },
  {
    id: 5,
    title: "사람을 볼 때 나는?",
    answera: "첫인상, 느낌에 따라 판단함",
    answerb: "사람을 판단하는 \n나만의 기준이 있음",
    type: "SN",
  },
  {
    id: 6,
    title: `“우리는 왜 태어난 걸까?”\n친구가 이렇게 말하면 나는?`,
    answera: "? 그냥 뭐 태어났으니까...\n무슨 일 있음?",
    answerb: "인간의 존재의의에 대해 토론함",
    type: "SN",
  },
  {
    id: 7,
    title: `“나 오늘 좀 부었지?”라고 \n친구가 물으면?`,
    answera: `응 좀 부었네. 어제 뭐 먹었어?`,
    answerb: `뭐가 부어!! 똑같아!!`,
    type: "TF",
  },
  {
    id: 8,
    title: "친구가 짜증났던 일을 토로하는데, \n이때 내 속마음은? ",
    answera: `오… 그랬군. \n얘는 이런 부분에서 \n 짜증이 나는구나`,
    answerb: `(들어주고 리액션하느라 \n속으로 생각 못 함)`,
    type: "TF",
  },
  {
    id: 9,
    title: `친구가 \n“나 늦잠자서 드라이샴푸 \n쓰고 왔다ㅋㅋㅋ”라고 한다면?`,
    answera: "오 어디꺼 쓰는데? \n진짜 뽀송뽀송해져?",
    answerb: "그래도 잘했네ㅋㅋㅋ\n어제 뭐하느라 늦게 잤어?",
    type: "TF",
  },
  {
    id: 10,
    title: "친구가 갑자기 전화와서 \n“지금 나와!”라고 한다면?",
    answera: "갑자기? (급약속 싫음)",
    answerb: "ㅋㅋㅋ어딘데? (급약속 재밌어!)",
    type: "JP",
  },
  {
    id: 11,
    title: "내일 친구가 집에 놀러오기로 했어. \n이때 나는?",
    answera: "미리 청소도 하고 \n뭐 먹을지 생각도 해둔다",
    answerb: "친구 오면 그때그때 정한다",
    type: "JP",
  },
  {
    id: 12,
    title: "나는 어떤 사람?",
    answera: "섣부르게 판단하지 않음",
    answerb: "빠르게 판단을 내리고 싶어함",
    type: "JP",
  },
];
