export const QuestionData = [
  {
    title: "연인이 연락이 잘 안 된다면?",
    options: [
      { text: "(조금 걱정되지만)\n기다리면 오겠지ㅎㅎ", type: "중립형" },
      {
        text: "(별로 신경 쓰지 않는다)\n바쁜가 보네~",
        type: "안정형",
      },
      {
        text: "(얘 뭐하지..?)\n 불안해서 계속 연락을 시도한다",
        type: "불안형",
      },
    ],
  },
  {
    title: "연인이 친구들과 놀러 간다고 한다면?",
    options: [
      {
        text: "친구들이랑..? \n(나를 두고 친구들과 노는 게 서운함)",
        type: "불안형",
      },
      { text: "웅~ 연락만 잘 해줘~", type: "안정형" },
      { text: "(약간 서운하지만)\n재밌게 놀아!", type: "중립형" },
    ],
  },
  {
    title: "연인이 자신의 감정을 솔직하게 \n표현할 때 내 반응은?",
    options: [
      { text: "상대방의 감정 표현이 조금 부담스러움", type: "불안형" },
      { text: "종종 당황스럽지만 공감하려 노력함", type: "중립형" },
      { text: "잘 들어주고 공감해줌", type: "안정형" },
    ],
  },
  {
    title: "연인이 혼자만의 시간을 원한다면?",
    options: [
      { text: "아~ 오키오키ㅋㅎ\n이해하고 그 시간을 존중해줌", type: "안정형" },
      { text: "힝.. 약간 서운하지만 기다림", type: "중립형" },
      {
        text: "(..왜 그러지?)\n불안해지고 그 이유를 계속 묻게됨",
        type: "불안형",
      },
    ],
  },
  {
    title: "연인과 다툰 후 내 반응은?",
    options: [
      { text: "다툼 후 연인이 나를 떠날까 봐 불안함", type: "불안형" },
      { text: "침착하게 문제를 해결하려고 노력함", type: "안정형" },
      { text: "시간이 지나면 괜찮아질 거라고 생각함", type: "중립형" },
    ],
  },
  {
    title: "연인이 바쁘다고 만남을 취소한다면?",
    options: [
      { text: "바쁘다니까 이해함. \n다음에 만나면 되지~", type: "안정형" },
      { text: "불안하고 화남", type: "불안형" },
      { text: "좀 서운하지만.. 괜찮아!", type: "중립형" },
    ],
  },
  {
    title: "연인이 다른 이성과 \n친구처럼 지낼 때 내 반응은?",
    options: [
      { text: "별로 신경 안 씀", type: "안정형" },
      { text: "조금 불편하지만 이해함", type: "중립형" },
      { text: "불안하고 질투를 느낌", type: "불안형" },
    ],
  },
  {
    title: "서로의 미래에 대해 이야기할 때 \n나는?",
    options: [
      { text: "미래에 대해 이야기하는게 부담됨", type: "불안형" },
      { text: "약간의 불안감을 느끼지만 대화를 이어감", type: "중립형" },
      { text: "미래를 계획하는게 즐거움", type: "안정형" },
    ],
  },
  {
    title: "연인이 나에게 \n비밀이 있다고 한다면?",
    options: [
      {
        text: "언제 말해줄건데?ㅋㅋㅋ\n(연인을 믿고 말할 때까지 기다림)",
        type: "안정형",
      },
      {
        text: "뭔데... ㅡㅡ \n(조금 불안하지만 연인을 믿으려고 함)",
        type: "중립형",
      },
      {
        text: "지금 말 해주면 안돼? \n(불안해지고 계속 물어보게 됨)",
        type: "불안형",
      },
    ],
  },
  {
    title: "연인이 내 제안을 거절한다면?",
    options: [
      { text: "우쒸 그럼 어쩌고 싶은데? \n(다른 방안 찾기)", type: "안정형" },
      {
        text: "그래? 싫다면 어쩔 수 없지\n(서운하지만 받아들임)",
        type: "중립형",
      },
      { text: "왜???? \n(불안하고 화가 남)", type: "불안형" },
    ],
  },
  {
    title: "연인이 여행을 \n혼자 가고 싶다고 한다면?",
    options: [
      { text: "불안해서 반대함", type: "불안형" },
      { text: "조금 서운하지만 알겠다고 함", type: "중립형" },
      { text: "이해하고 지지해줌", type: "안정형" },
    ],
  },
  {
    title: "연인이 내 취미를 이해하지 못한다면?",
    options: [
      {
        text: "니가 이해를 하든 말든ㅋ\n(내 취미는 내 취미일 뿐)",
        type: "안정형",
      },
      {
        text: "조금 실망이지만 알겠다고 함\n(관두진 않지만 굳이 티 안냄)",
        type: "중립형",
      },
      { text: "불안하고 서운해짐\n(이게 왜 싫지...)", type: "불안형" },
    ],
  },
];
