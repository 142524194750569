import React from "react";
import "./AnimalMainjp.css";
import { useNavigate } from "react-router-dom";
import main from "./img/animal_Main.png";
import Take5 from "../../../../components/Take5/Take5";
import SEO from "../../SEOjp";
import { animalAttributejp } from "../../seoAttributesjp";
import AdSenseComponent from "../../../../AdSenseComponent";

const AnimalMainjp = () => {
  const navigate = useNavigate();
  const QPage = () => {
    navigate("/test/jp/animaljp/question");
  };

  const id = "animaljp";

  return (
    <div className="animaljp_wrapper">
      <div className="animaljp_contents">
        <SEO attribute={animalAttributejp} id={id} />
        <Take5 />
        <div className="animaljp_main_container">
          <img src={main} alt="main" />
          <button onClick={QPage} className="animaljp_start_btn">
            始める
          </button>
        </div>
      </div>
      <AdSenseComponent />
    </div>
  );
};

export default AnimalMainjp;
