export const QuestionData = [
  {
    id: 1,
    title: "여가 시간을 보낼 때 나는?",
    answera: "여러 사람과 함께 어울림",
    answerb: "혼자서 조용히 쉼",
    type: "EI",
  },
  {
    id: 2,
    title: "새로운 환경에서 나는?",
    answera: "먼저 다가감",
    answerb: "조용히 주변을 관찰함",
    type: "EI",
  },
  {
    id: 3,
    title: "나는 주로...",
    answera: "중심에서 이야기를 주도함",
    answerb: "주로 듣고 필요할 때만 말함",
    type: "EI",
  },

  {
    id: 4,
    title: "친구에게 이야기를 전할 때 나는?",
    answera: "사실 그대로 정확하게 전달함",
    answerb: "느낌과 의미를 더해 전달함",
    type: "SN",
  },
  {
    id: 5,
    title: "사람을 볼 때 나는?",
    answera: "첫인상, 느낌에 따라 판단함",
    answerb: "사람을 판단하는 \n나만의 기준이 있음",
    type: "SN",
  },
  {
    id: 6,
    title: "작업을 시작하기 전에 나는?",
    answera: "필요한 자료와 도구를 미리 준비함",
    answerb: "전체적으로 구상을 먼저 시작함",
    type: "SN",
  },

  {
    id: 7,
    title: "결정을 내릴 때 중요한 것은?",
    answera: "논리적이고 객관적인 사실",
    answerb: "사람들의 감정과 관계",
    type: "TF",
  },
  {
    id: 8,
    title: "갈등이 생겼을 때 나는?",
    answera: "상황을 분석하고 해결책을 찾음",
    answerb: "서로의 감정을 이해하고\n타협점을 찾음",
    type: "TF",
  },
  {
    id: 9,
    title: "더 좋은 사람은?",
    answera: "논리적이고 일관성 있는 사람",
    answerb: "따뜻하고 이해심 많은 사람",
    type: "TF",
  },

  {
    id: 10,
    title: "휴가 계획을 세울 때 나는?",
    answera: "세부 일정까지 계획함",
    answerb: "대략적인 계획만 세움",
    type: "JP",
  },
  {
    id: 11,
    title: "마감일이 있을 때 나는?",
    answera: "마감일 전에 미리미리 끝냄",
    answerb: "마감일에 맞춰 유동적으로 끝냄",
    type: "JP",
  },
  {
    id: 12,
    title: "일정을 관리할 때 나는?",
    answera: "세부적인 계획을 세우고\n그대로 진행함",
    answerb: "큰 틀만 정하고\n자유롭게 진행함",
    type: "JP",
  },
];
