import { useEffect } from "react";
import "./TwinklingStars.css";

const TwinklingStars = () => {
  useEffect(() => {
    const createStar = () => {
      const windowWidth = window.screen.width;
      const windowHeight = window.screen.height;
      const getRandomValue = (max) => Math.floor(Math.random() * max);

      const style = ["style1", "style2", "style3"];
      const opacity = [
        "opacity1",
        "opacity1",
        "opacity1",
        "opacity2",
        "opacity3",
      ];
      const twinkle = [
        "twinkle1",
        "twinkle1",
        "twinkle1",
        "twinkle2",
        "twinkle2",
        "twinkle3",
        "twinkle4",
      ];

      const _s = getRandomValue(3);
      const _o = getRandomValue(5);
      const _t = getRandomValue(7);
      const x = getRandomValue(windowWidth);
      const y = getRandomValue(windowHeight);

      const className =
        "star " + style[_s] + " " + opacity[_o] + " " + twinkle[_t];

      const star = document.createElement("div");
      star.className = className;
      star.style.left = `${x}px`;
      star.style.top = `${y}px`;

      document.body.appendChild(star);

      setTimeout(() => {
        document.body.removeChild(star);
      }, 3000); // Remove the star after 3 seconds
    };

    // Create stars more frequently
    const interval = setInterval(() => {
      for (let i = 0; i < 5; i++) {
        createStar();
      }
    }, 500); // Create stars every 500 milliseconds

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return null;
};

export default TwinklingStars;
