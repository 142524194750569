export const QuestionData = [
  {
    num: "1 / 25",
    titlequestion: "이어지는 가사는?",
    question: "처음이라 그래\n며칠 뒤엔 괜찮아져",
    answers: [
      {
        option: "그 생각만으로도 벌써 일년이",
        score: 4,
      },
      { option: "그 생각만으로도 벌써 이렇게", score: 0 },
    ],
  },
  {
    num: "2 / 25",
    titlequestion: "이어지는 가사는?",
    question: " 그대 기억이 지난 사랑이 \n내 안을 파고 드는",
    answers: [
      { option: "가시가 되어", score: 4 },
      { option: "상처가 되어", score: 0 },
    ],
  },
  {
    num: "3 / 25",
    titlequestion: "이어지는 가사는?",
    question: "참 모질었던 삶이었지만\n늘 황폐했던 맘이지만",
    answers: [
      { option: "그래도 너 있어 눈 부셨어", score: 4 },
      { option: "그래도 너 있어 행복했어", score: 0 },
    ],
  },
  {
    num: "4 / 25",
    titlequestion: "이어지는 가사는?",
    question: "저 먼 바다 끝에 뭐가 있을까",
    answers: [
      { option: "하늘과 맞닿은 수평선 날 불러", score: 0 },
      { option: "다른 무언가 세상과는 먼 얘기", score: 4 },
    ],
  },
  {
    num: "5 / 25",
    titlequestion: "빈 칸에 들어가는 단어는?",
    question: "하루만 너의 ( ? )가 되고 싶어\nOh baby~",
    answers: [
      { option: "남자친구", score: 0 },
      { option: "고양이", score: 4 },
    ],
  },
  {
    num: "6 / 25",
    titlequestion: "이어지는 가사는?",
    question: "울지마 바보야 난 정말 괜찮아",
    answers: [
      {
        option:
          "어떻게든 살아 행복할게 \n만남 사랑 추억 이별\n참 고마웠었어 그동안",
        score: 4,
      },
      {
        option:
          "어떻게든 살아 행복하게\n만남 사랑 이별 추억\n참 고마웠었어 그동안",
        score: 0,
      },
    ],
  },
  {
    num: "7 / 25",
    titlequestion: "이 노래의 제목은?",
    question:
      "원하는 좋은 사람 나타날 때까지 \n난 잠시 그녈 지켜줄 뿐야 아무것도 \n바라는 것 없기에 그걸로도 감사해",
    answers: [
      { option: "사랑의 바보", score: 4 },
      { option: "바보의 사랑", score: 0 },
    ],
  },
  {
    num: "8 / 25",
    titlequestion: "이 노래의 제목은?",
    question:
      "이러다 미쳐 내가\n여리여리 착하던 그런 내가\n너 때문에 돌아 내가\n독한 나로 변해 내가",
    answers: [
      {
        option: "Sign",
        score: 0,
      },
      {
        option: "Abracadabra",
        score: 4,
      },
    ],
  },
  {
    num: "9 / 25",
    titlequestion: "빈 칸에 들어가는 단어는?",
    question:
      " Just one 10 MINUTES\n내 것이 되는 시간\n 모든 게 끝난 후 그녀가 오고 있어\n Baby ( ? )촌스럽기도 하지",
    answers: [
      {
        option: "붉은 립스틱",

        score: 4,
      },
      {
        option: "높은 하이힐",
        score: 0,
      },
    ],
  },
  {
    num: "10 / 25",
    titlequestion: "이어지는 가사는?",
    question: "온종일 정신없이 바쁘다가도\n틈만 나면 니가 생각나",
    answers: [
      {
        option: "언제부터 내 맘에 살았니",
        score: 0,
      },
      {
        option: "언제부터 내 안에 살았니",
        score: 4,
      },
    ],
  },
  {
    num: "11 / 25",
    titlequestion: "이어지는 가사는?",
    question: "머리부터 발끝까지 다",
    answers: [
      {
        option: "사랑스러워",
        score: 4,
      },
      {
        option: "자랑스러워",
        score: 0,
      },
    ],
  },
  {
    num: "12 / 25",
    titlequestion: "이 노래의 제목은?",
    question:
      "난 니가 싫어 졌어\n우리 이만 헤어져\n다른 여자가 생겼어\n너보다 훨씬 좋은",
    answers: [
      {
        option: "보통날",
        score: 0,
      },
      {
        option: "거짓말",
        score: 4,
      },
    ],
  },
  {
    num: "13 / 25",
    titlequestion: "빈 칸에 들어가는 단어는?",
    question: "파란 하늘 위로 훨훨 날아가겠죠\n어려서 꿈꾸었던 (?) 타고\n",
    answers: [
      {
        option: "비행기",
        score: 4,
      },
      {
        option: "열기구",
        score: 0,
      },
    ],
  },
  {
    num: "14 / 25",
    titlequestion: "이어지는 가사는?",
    question:
      "니가 날 혹시 안 좋아할까봐\n혼자 얼마나 애 태운지 몰라\n그런데 니가 날 사랑한다니",
    answers: [
      {
        option: "세상에, 너무 놀랐지 뭐야",
        score: 0,
      },
      {
        option: "어머나, 다시 한 번 말해봐",
        score: 4,
      },
    ],
  },
  {
    num: "15 / 25",
    titlequestion: "이어지는 가사는?",
    question:
      "마음 여미고 한껏 단장을 하고\n그대와 갔던 거리에도 혼자서\n씩씩한 걸음 살짝 붉어진 얼굴",
    answers: [
      {
        option: "너를 잊기 좋은 날",
        score: 4,
      },
      {
        option: "너를 보내기 좋은 날",
        score: 0,
      },
    ],
  },
  {
    num: "16 / 25",
    titlequestion: "이 노래의 제목은?",
    question:
      "기억은 계절따라 흩어져 가겠지\n차갑기만 한 사람\n빈 가슴 애태우며 난 기다리겠지\n어설픈 내 사랑은",
    answers: [
      {
        option: "사랑했었지",
        score: 0,
      },
      {
        option: "사랑했나봐",
        score: 4,
      },
    ],
  },
  {
    num: "17 / 25",
    titlequestion: "이 노래의 제목은?",
    question:
      "너를 잊을 순 없지만 붙잡고 싶지만\n이별 앞에서 할 수 있는건\n좋은 기억이라도 남도록\n편히 보내 주는 일",
    answers: [
      {
        option: "Timeless",
        score: 4,
      },
      {
        option: "Tenderness",
        score: 0,
      },
    ],
  },
  {
    num: "18 / 25",
    titlequestion: "이 노래의 제목은?",
    question:
      "사랑한단 말은 못해도\n안녕이란말은 해야지\n아무 말도없이 떠나간\n그대가 정말 미워요",
    answers: [
      {
        option: "위로",
        score: 0,
      },
      {
        option: "귀로",
        score: 4,
      },
    ],
  },
  {
    num: "19 / 25",
    titlequestion: "빈 칸에 들어가는 단어는?",
    question:
      "( ? ) 영혼을 바칠게요 \n ( ? ) 제발 날 받아 줘요\n ( ? ) 달콤히 좀 더 달콤하게",
    answers: [
      {
        option: "Juliette",
        score: 4,
      },
      {
        option: "Senorita",
        score: 0,
      },
    ],
  },
  {
    num: "20 / 25",
    titlequestion: "빈 칸에 들어가는 단어는?",
    question:
      "내 이름은 ( ? )\n내 직업은 수많은 관객\n그 앞에 웃음을 파는 일\n슬퍼도 웃으며\n내 모습을 감추는게 철칙",
    answers: [
      {
        option: "광대",
        score: 4,
      },
      {
        option: "삐에로",
        score: 0,
      },
    ],
  },
  {
    num: "21 / 25",
    titlequestion: "이어지는 가사는?",
    question: "상처를 치료해줄 사람 어디 없나\n가만히 놔두다간 끊임 없이 덧나",
    answers: [
      {
        option: "혼자가 되는게 너무나도 겁나",
        score: 0,
      },
      {
        option: "사랑도 사람도 너무나도 겁나",
        score: 4,
      },
    ],
  },
  {
    num: "22 / 25",
    titlequestion: "이 노래의 제목은?",
    question:
      "이 바보야 진짜 아니야\n아직도 나를 그렇게 몰라\n너를 가진 사람 나밖에 없는데\n제발 나를 떠나가지마",
    answers: [
      {
        option: "응급실",
        score: 4,
      },
      {
        option: "이미 그댄",
        score: 0,
      },
    ],
  },
  {
    num: "23 / 25",
    titlequestion: "이어지는 가사는?",
    question: "좋은 사람 꼭 만날 거라 했는데\n그 약속 지키지 못할 것 같아",
    answers: [
      {
        option: "내겐 그대가 하나뿐이라 \n다른 사랑 못할 것 같아요",
        score: 4,
      },
      {
        option: "좋았던 기억만 다시 찾아와 \n나를 더 아프게하죠",
        score: 0,
      },
    ],
  },
  {
    num: "24 / 25",
    titlequestion: "이 노래의 제목은?",
    question:
      "너는 내 남자이니까\n니가 내 사랑이니까\n다 미안해 다 이해해\n헤어지지 말자",
    answers: [
      {
        option: "그래도 사랑하니까",
        score: 0,
      },
      {
        option: "미워도 사랑하니까",
        score: 4,
      },
    ],
  },
  {
    num: "25 / 25",
    titlequestion: "이어지는 가사는?",
    question: "하루에 네번 사랑을 말하고",
    answers: [
      {
        option: "여덟번 뽀뽀 여섯번의 \n키스를 해줘",
        score: 0,
      },
      {
        option: "여덟번 웃고 여섯번의 \n키스를 해줘",
        score: 4,
      },
    ],
  },
];
