const tarotData = [
  { id: 1, image: require("../img/tarot1.png") },
  { id: 2, image: require("../img/tarot2.png") },
  { id: 3, image: require("../img/tarot3.png") },
  { id: 4, image: require("../img/tarot4.png") },
  { id: 5, image: require("../img/tarot5.png") },
  { id: 6, image: require("../img/tarot6.png") },
  { id: 7, image: require("../img/tarot7.png") },
  { id: 8, image: require("../img/tarot8.png") },
  { id: 9, image: require("../img/tarot9.png") },
  { id: 10, image: require("../img/tarot10.png") },
  { id: 11, image: require("../img/tarot11.png") },
  { id: 12, image: require("../img/tarot12.png") },
  { id: 13, image: require("../img/tarot13.png") },
  { id: 14, image: require("../img/tarot14.png") },
  { id: 15, image: require("../img/tarot15.png") },
  { id: 16, image: require("../img/tarot16.png") },
  { id: 17, image: require("../img/tarot17.png") },
  { id: 18, image: require("../img/tarot18.png") },
  { id: 19, image: require("../img/tarot19.png") },
  { id: 20, image: require("../img/tarot20.png") },
  { id: 21, image: require("../img/tarot21.png") },
  { id: 22, image: require("../img/tarot22.png") },
  { id: 23, image: require("../img/tarot23.png") },
  { id: 24, image: require("../img/tarot24.png") },
  { id: 25, image: require("../img/tarot25.png") },
  { id: 26, image: require("../img/tarot26.png") },
  { id: 27, image: require("../img/tarot27.png") },
  { id: 28, image: require("../img/tarot28.png") },
  { id: 29, image: require("../img/tarot29.png") },
  { id: 30, image: require("../img/tarot30.png") },
  { id: 31, image: require("../img/tarot31.png") },
  { id: 32, image: require("../img/tarot32.png") },
  { id: 33, image: require("../img/tarot33.png") },
  { id: 34, image: require("../img/tarot34.png") },
  { id: 35, image: require("../img/tarot35.png") },
  { id: 36, image: require("../img/tarot36.png") },
  { id: 37, image: require("../img/tarot37.png") },
  { id: 38, image: require("../img/tarot38.png") },
  { id: 39, image: require("../img/tarot39.png") },
  { id: 40, image: require("../img/tarot40.png") },
  { id: 41, image: require("../img/tarot41.png") },
  { id: 42, image: require("../img/tarot42.png") },
  { id: 43, image: require("../img/tarot43.png") },
  { id: 44, image: require("../img/tarot44.png") },
];

export default tarotData;
