export const QuestionData = [
  {
    id: 1,
    title: "好きな人と一緒に何がしたいですか？",
    answera: "たくさんのところを旅行して一緒に思い出を作りたい",
    answerb: "静かで親密な時間を一緒に過ごしたい",
    type: "EI",
  },
  {
    id: 2,
    title: "どんなタイプの集まりが好きですか？",
    answera: "新しい人と出会えるイベント",
    answerb: "親しい友達との集まり",
    type: "EI",
  },
  {
    id: 3,
    title: "エネルギーをリチャージする方法は？",
    answera: "人と交流することでリチャージする",
    answerb: "一人で過ごすことでリチャージする",
    type: "EI",
  },
  {
    id: 4,
    title: "面白そうな雲の写真が送られてきた。\n私の反応は..",
    answera: "わぁ、その雲は美しいね",
    answerb: "あ、それは恐竜のような形をした雲だと思う",
    type: "SN",
  },
  {
    id: 5,
    title: `"デスノートを持っていることを\n想像したことはありますか？"\nと言われたら、私の反応は...`,
    answera: "本当に？（彼らの想像力はすごいね）",
    answerb: "私も！それで人を殺す方法まで考えてみたことがあるよ（笑）",
    type: "SN",
  },
  {
    id: 6,
    title: "自分についてどう説明しますか？",
    answera: "物事を説明するのは得意だけど、\n頭の中でそれを想像するのは難しい",
    answerb:
      "頭の中で物事をイメージするのは得意だけど、\nそれを言葉で説明するのは難しい",
    type: "SN",
  },
  {
    id: 7,
    title: "「考えておく」と言ったとき、それは..",
    answera: "本当に考えている（深い考え）",
    answerb: "実際はしたくない（回避的な拒絶）",
    type: "TF",
  },
  {
    id: 8,
    title: "難しい問題に直面しているとき、私は..",
    answera: "問題を解決するのに役立つ言葉が欲しい",
    answerb: "現状や感情に共感してほしい",
    type: "TF",
  },
  {
    id: 9,
    title: "彼らは病気で病院に行ったと言った。\n私が気になるのは..",
    answera: "彼らのどの部分が痛いのか？\n病院で何を言ったのか？",
    answerb:
      "それが彼らにとってどれほど難しいか？\n私がそばにいてあげるべきか？",
    type: "TF",
  },
  {
    id: 10,
    title: "一緒にカフェに行ったけど、\nドアが閉まっている！この状況で、私は..",
    answera: "予備に別のカフェを考えていた",
    answerb: "その場で別のカフェを探す",
    type: "JP",
  },
  {
    id: 11,
    title: "彼らは爪切りを探している。\nこの瞬間、私は..",
    answera: "正確な場所を指し示して伝える",
    answerb: "探して「ここらへんにあるかもしれない」と言う",
    type: "JP",
  },
  {
    id: 12,
    title: "新しいレシピを試しています！\nどのように作りますか？",
    answera: "レシピをそのままに従う",
    answerb: "レシピをざっと見て勘で作る",
    type: "JP",
  },
];
