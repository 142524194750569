export const QuestionData = [
  {
    id: 1,
    title: "If I were an Olympian god,\nI would be...",
    answera: "A god who leads and guides",
    answerb: "A god who protects and safeguards",
    type: "EI",
  },
  {
    id: 2,
    title: "When gathering with other gods,\nI would...",
    answera: "Enjoy festivals with many gods",
    answerb: "Interact with a few close gods",
    type: "EI",
  },
  {
    id: 3,
    title: "How would I interact with humans?",
    answera: "Frequently intervene and help them",
    answerb: "Mostly observe them",
    type: "EI",
  },
  {
    id: 4,
    title: "If I were a god,\nwhat would I value more?",
    answera: "Present peace and stability",
    answerb: "Future possibilities and changes",
    type: "SN",
  },
  {
    id: 5,
    title: "What kind of oracle would \nI give to humans?",
    answera: "Practical advice for immediate help",
    answerb: "Prophetic messages hinting at the future",
    type: "SN",
  },
  {
    id: 6,
    title: "What teachings would \nI impart to humans?",
    answera: "Wisdom and knowledge",
    answerb: "Creativity and art",
    type: "SN",
  },
  {
    id: 7,
    title: "When humans are in conflict,\nhow would I intervene?",
    answera: "Present solutions based on logic and facts",
    answerb: "Offer emotional empathy and comfort",
    type: "TF",
  },
  {
    id: 8,
    title: "When I disagree with another god,\nI would...",
    answera: "Persuade with logical arguments",
    answerb: "Approach considering their feelings",
    type: "TF",
  },
  {
    id: 9,
    title: "How would I want to be \nremembered as a god?",
    answera: "Wise and just",
    answerb: "Warm and compassionate",
    type: "TF",
  },
  {
    id: 10,
    title: "How would I approach battle?",
    answera: "Carefully strategize and prepare",
    answerb: "Attack immediately and enjoy the fight",
    type: "JP",
  },
  {
    id: 11,
    title: "How would I manage my schedule?",
    answera: "Plan thoroughly and systematically",
    answerb: "Respond flexibly and spontaneously",
    type: "JP",
  },
  {
    id: 12,
    title: "When plans go awry,\nhow would I respond?",
    answera: "Reorganize and take control",
    answerb: "Adapt spontaneously to the situation",
    type: "JP",
  },
];
