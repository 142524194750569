export const QuestionData = [
  {
    id: 1,
    title:
      "ある日、ゾンビウイルスが突然拡散。\nおおまじめ…何が起こっているの?\nこれは生き残るために団結する時だ!",
    answera: "生存者が集まる避難所に行こう!",
    answerb: "知り合いとベースを作ろう!",
    type: "EI",
  },
  {
    id: 2,
    title: "みんなが集まる状況で、\n生活空間を分割する意見が交わされる…",
    answera: "離れるのは危険じゃない?\n怖いから一緒にいよう",
    answerb: "それでも、独自のスペースは必要じゃない?\n適度に離れる方がいい。",
    type: "EI",
  },
  {
    id: 3,
    title:
      "ようこそ、\n新しき仲間たち!新しい生存者が到着。\nこの瞬間、私はどうするだろうか?",
    answera:
      "質問がたくさんあるよ!どこから来たの?\nこっちに来る途中でゾンビは多かった?全部聞いてみる。",
    answerb: "時間が経てば何でもわかる。\n眠って自分の仕事をする。",
    type: "EI",
  },
  {
    id: 4,
    title: "武器がいっぱいの場所を見つけた!\nどんな武器を選ぶだろう?",
    answera: "実用性が一番だ。\n頑丈そうなバットを選ぼう!",
    answerb: "材料は十分ある。\n色々なものを持って新しい武器を作る。",
    type: "SN",
  },
  {
    id: 5,
    title:
      "眠らぬ夜。\nひとりの生存者が私のところに来て、\n「何を考えているの?」と尋ねてきた。",
    answera: "明日やるべきことを考えていた。",
    answerb: "ただ…ゾンビ黙示録はいつ終わるんだろう?",
    type: "SN",
  },
  {
    id: 6,
    title:
      "避難所がゾンビに占拠された!\n幸いにも別の避難所が受け入れてくれたが、\nルールは少し厳しい。私はどうするだろう?",
    answera: "ルールに慣れて最善を尽くす。",
    answerb: "ルールを参照するが、自分の方法で生きる。",
    type: "SN",
  },
  {
    id: 7,
    title: "同僚がけがをした。\n私は何と言うだろう?",
    answera:
      "「ゾンビに噛まれたの?」\nそれとも幸いにもしてないの?\n早く治療して戻ってきて!",
    answerb: "「大丈夫?とても痛いのか?」\n(心配しつづける)",
    type: "TF",
  },
  {
    id: 8,
    title:
      "その人はゾンビに噛まれていたことがわかった!\n決定は多数決で行われる。\n私はどうするべきか?",
    answera:
      "残りの仲間たちは生き残らなければならない。\n「出ていってくれないか?」",
    answerb:
      "この仲間は我々と耐えてきた。\n即座に追い出すわけにはいかない。\n「もう少し観察できますか?」",
    type: "TF",
  },
  {
    id: 9,
    title:
      "深夜、\n仲間が私のことを話しているのを聞いた。\n聞いて嬉しい言葉は?",
    answera:
      "本当に有能じゃない?\nたくさん知っていて頼りになる。\n彼がいると安心する!",
    answerb: "あの人はとてもいい。\n最後まで一緒にいたい。\n彼なら信頼できる!",
    type: "TF",
  },
  {
    id: 10,
    title:
      "避難所がゾンビにさらされてしまった。\n今は移動が必要だ!でもどこに行くべきか?",
    answera:
      "前回食べ物を取りに行ったAマートに行こう!\nゾンビ対策をより計画的に立てて移動しよう。",
    answerb:
      "Bマートは大きいから、\nそっちに行こう!リスクがあるけど価値がある、\n予期せぬ状況は発生時に対処しよう。",
    type: "JP",
  },
  {
    id: 11,
    title:
      "家族がまだ生きている情報を手に入れた。\n遠くない…私はどうするだろう?",
    answera:
      "遅れずに彼らを救出しなければならない。\n計画を最初に立てよう。動き、避難経路、\nプランB、そして備品を確認しよう。",
    answerb:
      "今すぐ彼らを救出しなければ!\n計画を大まかに立ててすぐに出発しよう!",
    type: "JP",
  },
  {
    id: 12,
    title:
      "救出成功!今は帰りの旅だけが残っている。\n避難所に急ぎ戻っていると…\nそれは軍隊だろうか?\n彼らは安全に生存者を避難させている。\nこの瞬間、私はどうするだろう?",
    answera: "仲間に約束したからには戻るのが優先だ。\n避難所までの同行を頼む。",
    answerb:
      "まず避難所に入ろう。\n避難所の場所を伝えているので、\n仲間はすぐに参加できるはずだろう。",
    type: "JP",
  },
];
