import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./NojamMain.css";
import main from "./img/mainImg.png";
import sampleImg from "./img/nojamSample.png";
import Take5 from "../../../../components/Take5/Take5";
import SEO from "../../SEOko";
import { nojamlifeAttributeko } from "../../seoAttributesko";

const NojamMain = () => {
  const navigate = useNavigate();
  const handleClickButton = () => {
    navigate("/test/nojamlife/question");
  };

  const id = "nojamlife";

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="nojam_wrapper">
      <div className="nojam_contents">
        <SEO attribute={nojamlifeAttributeko} id={id} />
        <Take5 />
        <div className="nojame_main_container">
          <div className="nojam_main_img_container">
            <img src={main} alt="main_img" />
          </div>
          <div className="nojam_main_sample_img">
            <img src={sampleImg} alt="sampleImg" />
          </div>
          <div className="nojam_main_btn_container">
            <button className="nojam_main_btn" onClick={handleClickButton}>
              테스트 하기
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NojamMain;
