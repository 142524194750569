import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Card from "./Lang/ko/Cardko";
import "./Home.css";
import TestList from "./Lang/ko/TestListko.json";
import Logo from "./img/logo.png";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga";
import { homeAttributeko } from "./Lang/ko/seoAttributesko";
import Footer from "./components/Footer/Footer";
import LanguageSelector from "./LanguageSelector";
import TestCategorySelector from "./TestCategorySelector";
import ScrollToTopButton from "./ScrollToTopButton";
import { Helmet } from "react-helmet";

function Home() {
  const navigate = useNavigate();
  const [testList, setTestList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");

  const _eventSenderGA = (category, action, label) => {
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    });
  };
  _eventSenderGA("Clicking", "Main Home");

  const gotoHome = () => {
    navigate("/");
  };

  useEffect(() => {
    setTestList(TestList);
  }, []);

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };

  const filteredTestList = testList.filter((test) => {
    return (
      selectedCategory === "All" ||
      (test.categories && test.categories.includes(selectedCategory))
    );
  });

  const url = "https://zombiembti.net/";

  return (
    <div className="Home">
      <Helmet>
        <title>{homeAttributeko.name}</title>
        <meta name="description" content={homeAttributeko.desc} />
        <meta property="og:url" content={url} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={homeAttributeko.name} />
        <meta property="og:description" content={homeAttributeko.desc} />
        <meta property="og:image" content={homeAttributeko.imageUrl} />
      </Helmet>
      <div className="Home_contents">
        <header className="Home-header">
          <div onClick={gotoHome} className="logo-container">
            <img src={Logo} alt="logo" />
            <h2 className="logo-title">테이크파이브 테스트</h2>
          </div>
          <p className="sub-title">
            심리테스트, mbti테스트, 성격테스트, <br />
            유형테스트, 연애테스트 모음
          </p>
        </header>
        <LanguageSelector />
        <TestCategorySelector onSelectCategory={handleCategorySelect} />
        <ScrollToTopButton />
        <div className="TestList_container">
          {filteredTestList.map((test) => (
            <div key={test.id} className="TestList_container2">
              <Link to={`/test/${test.id}`}>
                <Card test={test} />
              </Link>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
