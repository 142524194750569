import React, { useState, useEffect } from "react";
import "./WordtypeRen.css";
import Footer from "../../../../components/Footer/Footer";
import { Link, useNavigate, useLocation } from "react-router-dom";
import EnShareButton from "./EnShareButton";
import { SyncLoader } from "react-spinners";
import Take5 from "../../../../components/Take5/Take5";
import Card from "../../Carden";
import TestList from "../../TestListen.json";
import activityDescriptions from "./data/activityDescriptions.json";
import types from "./data/types.json";
import wt_Q from "./img/wordtype_Q.png";

const WordtypeRen = () => {
  const navigate = useNavigate();

  const [testList, setTestList] = useState([]);
  const location = useLocation();

  const excludedTestId = "wordtypeen";
  const filteredTestList = testList.filter(
    (test) => test.id !== excludedTestId
  );

  useEffect(() => {
    setTestList(TestList);
  }, []);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, []);

  const { selectedActivities } = location.state || { selectedActivities: [] };

  if (!selectedActivities.length) {
    return <div>단어를 선택해주세요</div>;
  }

  const getResultType = (activities) => {
    const categories = activities
      .slice(0, 2)
      .map((activity) => activityDescriptions[activity.name].category);
    const key1 = categories.join(" ");
    const key2 = categories.reverse().join(" ");
    return types[key1] || types[key2] || types["Versatile Personality"];
  };

  const analyzePersonality = (activities) => {
    const personalityTraits = activities.map((activity) => {
      return activityDescriptions[activity.name].description;
    });

    const resultType = getResultType(activities);

    return { personalityTraits, resultType };
  };

  const { personalityTraits, resultType } =
    analyzePersonality(selectedActivities);

  return (
    <>
      <div className="wt_R_Wrapper">
        {loading ? (
          <div className="wt_loading_container">
            <p className="wt_loading_text">Analyzing results...📊</p>
            <SyncLoader
              color={"#414141"}
              loading={loading}
              size={9}
              speedMultiplier={0.5}
              cssOverride={{
                marginTop: "3rem",
                backgroundColor: "transparent",
              }}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        ) : (
          <>
            <Take5 />
            <>
              <div className="All_Test_has_cookie">
                <div className="wt_R_Contents">
                  <img src={wt_Q} alt="title" />
                  <h2 className="wt_R_title">You are...</h2>
                  <h3 className="wt_R_subtitle">{resultType.description}</h3>
                  <img
                    src={require(`${resultType.symbol}`)}
                    alt={resultType.name}
                    className="wt_R_symbol"
                  />
                  <p className="wt_R_subtitle2">{resultType.name}</p>
                  <div className="wt_R_p_container">
                    {personalityTraits.map((trait, index) => (
                      <div key={index} className="wt_R_p">
                        {trait.map((line, lineIndex) => (
                          <p key={lineIndex}>{line}</p>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="wt_IC_container"></div>
                <div className="wt_R_Restart_Btn_Container">
                  <button
                    className="wt_R_Restart_Btn"
                    style={{
                      minWidth: "210px",
                      maxWidth: "210px",
                      minHeight: "50px",
                      boxShadow: "none",
                    }}
                    onClick={() => navigate("/test/en/wordtypeen")}
                  >
                    Do it again
                  </button>
                </div>

                <EnShareButton />
                <div className="TestList_container">
                  {filteredTestList.map((test) => (
                    <div key={test.id} className="TestList_container2">
                      <Link to={`/test/${test.id}`}>
                        <Card test={test} />
                      </Link>
                    </div>
                  ))}
                </div>
                <Footer />
              </div>
            </>
            <></>
          </>
        )}
      </div>
    </>
  );
};

export default WordtypeRen;
