import React from "react";
import "./BlackcatTarotMainen.css";
import { useNavigate } from "react-router-dom";
import Take5 from "../../../../components/Take5/Take5";
import SEO from "../../SEOen";
import { blackcattarotAttributeen } from "../../seoAttributesen";
import AdSenseComponent from "../../../../AdSenseComponent";
import TwinklingStars from "./TwinklingStars";
import main from "./img/blackcat_main.png";

const BlackcatTarotMainen = () => {
  const navigate = useNavigate();

  const handleCardSelect = () => {
    navigate("/test/en/blackcattaroten/select");
  };

  const id = "blackcattaroten";

  return (
    <div className="blackcaten_main_Wrapper">
      <div className="blackcaten_main_contents">
        <SEO attribute={blackcattarotAttributeen} id={id} />
        <Take5 />
        <div className="blackcaten_main_container">
          <img src={main} alt="personalMessgaeTarot" />
        </div>
        <button className="blackcaten_start_btn" onClick={handleCardSelect}>
          Get Start!
        </button>
        <TwinklingStars />
      </div>
      <div className="blackcaten_ADSC_container">
        <AdSenseComponent />
      </div>
    </div>
  );
};

export default BlackcatTarotMainen;
