import ESTJ from "../img/ESTJ.png";
import ESFP from "../img/ESFP.png";
import ENTJ from "../img/ENTJ.png";
import INTP from "../img/INTP.png";
import INTJ from "../img/INTJ.png";
import ISTJ from "../img/ISTJ.png";
import ESTP from "../img/ESTP.png";
import INFP from "../img/INFP.png";
import ISFP from "../img/ISFP.png";
import ISFJ from "../img/ISFJ.png";
import ENFP from "../img/ENFP.png";
import INFJ from "../img/INFJ.png";
import ESFJ from "../img/ESFJ.png";
import ENTP from "../img/ENTP.png";
import ISTP from "../img/ISTP.png";
import ENFJ from "../img/ENFJ.png";

export const resultData = [
  {
    id: 1,
    best: "ESTJ",
    image: `${ESTJ}`,
    title: "한겨울의 아침",
    desc: "책임감이 강하고 일처리 능력이 뛰어난 당신은 냉철해보입니다. 한겨울의 새벽같아요. 리더십이 강하고 유능한 인상을 주죠. \n \n 계획을 잘 세우고 체계적으로 일을 처리하는 것을 좋아해서 꼼꼼하고 세심한 사람으로 보이기도 합니다. 당신은 감정적인 것보다 사실적인 정보에 더 관심이 많습니다. \n \n 현실적이고 직설적인 편이므로 때로는 강압적이고 고집스러워 보이기도 해요. 그치만 어느 곳에서나 안정적인 분위기를 유지하는 역할을 하기 때문에 없어서는 안 될 사람이죠.",
  },
  {
    id: 2,
    best: "ESFP",
    image: `${ESFP}`,
    title: "봄의 정오",
    desc: "활발하고 에너지가 넘치는 당신은 언제나 밝고 따뜻한 빛이 함께 하는 것처럼 보입니다. 마치 봄의 정오같아요. 당신은 다른 사람들과 어울리는 것을 좋아하고 사교성이 뛰어납니다. \n\n 즉흥적이며 현재에 조첨을 맞추고 어떤 경험도 즐기는 모습이 매력적으로 다가옵니다. 반복되는 일을 지루하게 생각하지만 그만큼 신선하고 새로운 것을 경험하려는 의지가 있죠. 자신이 느끼는 감정과 상황을 솔직하게 표현하는 것도 매력 포인트 중 하나랍니다. \n\n 때로는 선입견과 편견이 강해보이지만, 당신은 새로운 경험에 부딪히는 걸 두려워 하지 않고 새로운 사람, 환경, 경험에 끊임없이 적극적으로 다가가는 것으로 보입니다. 대중의 관심을 좋아하는 만큼 많은 이들이 여러분을 좋아하네요.",
  },
  {
    id: 3,
    best: "ENTJ",
    image: `${ENTJ}`,
    title: "붉은 비단",
    desc: "타고난 지도력을 타고난 당신은 언제나 당당해보입니다. 붉은 비단이 잘 어울릴 것 같은 카리스마와  높은 자신감은 보는 사람으로 하여금 확신을 불러일으키죠. \n \n 당신은 현실적이고 목표 지향적인 사람입니다. 타고난 리더십으로 인해 때때로 독단적으로 보일 수 있지만 목적을 이루기 위해 타인과 협력하는 방법을 잘 알고 있지요. 당신은 효율과 성과에 대한 목표를 중요하게 생각합니다. 그래서 목표를 위해 열심히 일하는 사람으로 보이죠. \n \n 의사소통을 명확히 하는 당신의 모습은 남들이 볼 때 엄격하고 직설적이라고 생각할 수도 있습니다. 하지만 그게 매력이라고 하는 사람도 있죠. 정확하고 구체적인 방법으로 상황을 이해하려는 당신에게 지혜를 구하기도 하고요. 당신은 꼭 필요한 사람이랍니다.",
  },
  {
    id: 4,
    best: "INTP",
    image: `${INTP}`,
    title: "신비로운 북극광",
    desc: "당신은 대체로 차분하고 고요한 분위기를 풍기고, 신중하고 조용히 행동합니다. 사실과 원리를 탐구하려는 욕구를 가지고 있어서, 남들이 당신을 볼 때 집중력이 매우 강하고 분석 능력이 뛰어나다고 느낍니다. \n \n 질문에 깊이 있는 답변을 생각하는 당신은 자신만의 독창적인 이론을 만들어냅니다. 당신은 상상력이 풍부하고 창의적이지만 일상적인 일에는 관심이 적을 수 있습니다. 친절하면서도 거리를 두고 접근하는 경향 때문이죠. \n \n 주변 사람들에게 무관심해 보일 수 있지만 자신이 흥미를 느끼는 분야에는 매우 열정적이에요. 당신의 내면은 복잡하고 또 아름답게 얽혀있지요. 이 갭 차이를 매력으로 느끼는 사람들이 많네요.",
  },
  {
    id: 5,
    best: "INTJ",
    image: `${INTJ}`,
    title: "달빛이 떨어지는 바다",
    desc: "당신은 조용하고 침착합니다. 타인과 적당한 거리를 두면서도 깊이 있는 대화를 즐기는 당신을 보면 칠흑처럼 어두운 밤, 달빛이 떨어지는 바다같아요. \n \n 독립적이고 논리적이어서 다소 냉담해보일 수 있지만 특유의 문제 해결 능력으로 남들을 도와주곤 합니다. 효율성과 신속성을 중요시하며, 자신의 비전을 위해 끊임없이 고민하고 계획합니다. \n \n 남들이 봤을 때 열정과 근성이 대단해보입니다. 자신에 대한 심도 있는 탐구를 놓지 않으며, 자기 성찰을 통해 인생의 의미와 방향을 찾아나가는 당신을 보며 주변 사람들은 많은 영감을 얻을 수 있습니다.",
  },
  {
    id: 6,
    best: "ISTJ",
    image: `${ISTJ}`,
    title: "울창한 숲",
    desc: "인내심이 강하고 신중한 당신은 울창한 숲을 연상케합니다. 안정적이고 신뢰할 수 있는 분위기를 뿜어내죠. \n \n 당신은 결정을 내리기 전까지 충분히 정보를 수집하고 평가합니다. 책임감이 높고 집중력이 있으며 꼼꼼한 성격이라 당신은 자신이 처리하는 일에 대해 자신감을 가지고 있습니다. \n \n 진취적이고 맡은 일에 책임을 다하는 모습에 남들은 당신에게 안정감과 신뢰를 느낍니다. 겉보기에 차가워보이지만 남들이 기억하기 힘든 세세한 추억까지 간직하는 면모를 보이기도 합니다. ",
  },
  {
    id: 7,
    best: "ESTP",
    image: `${ESTP}`,
    title: "떠오르는 해",
    desc: "적극적이고 에너지가 넘치는 당신은 떠오르는 해를 닮았습니다. 도전적이고 스릴 있는 경험을 즐기며, 어떤 상황에서도 두려움 없이 나아가죠. \n \n 새로운 환경이나 상황에 빠르게 적응하고, 대처능력이 뛰어나 어떤 상황도 유연하게 대처하는 당신을 보며 주위 사람들은 감탄하곤 합니다. 당신의 쾌활함은 보는 이들의 기분을 좋게 합니다. 긍정적인 에너지를 주는 사람이에요. \n \n 당신은 자신감 있는 언행으로 명확한 의사표현을 하면서도, 다른 사람들의 관점을 존중하고 이해하는 데에 능숙합니다. 상대방의 입장을 고려할 줄 아는 관용까지 갖췄군요!",
  },
  {
    id: 8,
    best: "INFP",
    image: `${INFP}`,
    title: "조화로운 팔레트",
    desc: "상상력이 풍부하고 창의적인 당신은 여러가지 색이 조화를 이루는 팔레트 같아요. 다양한 감정을 느끼고 이걸 표현하는 능력이 뛰어나죠. 게다가 공감 능력까지 높아서 많은 이들에게 좋은 친구가 될 수 있는 사람이에요. \n \n  타인의 필요와 가치를 존중할 줄 아는 사람을 어떻게 좋아하지 않을 수 있겠어요? 따뜻한데다 융통성까지 있어 새로운 아이디어나 방법, 관점을 잘 받아들이고 적용할 줄 알아요. \n \n  당신은 계속해서 더 나은 사람이 되고자 하며, 진정성과 자기 신뢰를 중요하게 생각합니다. 곁에 오래오래 두고 싶은 사람이에요. ",
  },
  {
    id: 9,
    best: "ISFP",
    image: `${ISFP}`,
    title: "기하학 무늬",
    desc: "타고난 감성을 가진 당신은 예측할 수 없는 기하학적 무늬를 닮았어요. 창의적이고 예술적인 표현력이 뛰어나죠. \n \n 타고난 감성과 상상력으로 누구도 따라할 수 없는 독창적인 무언가를 만드는 일에 뛰어납니다. 디테일에 대해 관심이 높아 남들이 발견하지 못하는 것도 잘 알아채는 눈썰미가 있어요. 그런 모습을 보면서 남들은 당신의 직관과 감각에 감탄합니다. \n \n 타인의 의견과 생각을 존중할 줄 아는 관용을 가졌고, 정서적인 면에서 감수성이 예민하므로 타인의 감정을 잘 느끼고 공감합니다. 예측할 수 없지만, 예측할 수 없기 때문에 더욱 신비로운 사람으로 보여요.",
  },
  {
    id: 10,
    best: "ISFJ",
    image: `${ISFJ}`,
    title: "잘 짜인 직물",
    desc: "신중하고 꼼꼼한 당신은 빈틈없이 짜인 직물을 연상케 합니다. 일에 대해 강한 책임감을 가지고 약속한 일을 반드시 해내는 성실함을 보며 남들은 당신을 ‘믿을 수 있는 사람’으로 인식합니다. 주변에서 높은 신뢰를 받고 있을 가능성이 커요. \n \n  작은 것도 놓치지 않는 섬세함을 타인에게 잘 발휘합니다. 타인의 감정 변화를 잘 파악하고 남들의 필요를 먼저 생각하고 돕는 경향이 있어요. \n \n 톡톡 튀는 성격은 아니지만 어디든 잘 스며들어 안정적인 분위기를 만드는 사람이죠.",
  },
  {
    id: 11,
    best: "ENFP",
    image: `${ENFP}`,
    title: "가능성의 공간",
    desc: "진취적인 당신은 가능성의 공간에서 유영하는 것처럼 보입니다. 하고 싶은 일도 많고 늘 새로운 걸 시도하죠. \n \n 열정적이고 자발적으로 일을 추진하는 경향이 있는데, 그 모습이 보는 사람들마저 동기부여가 되곤 합니다. 활기차고 호기심이 많은 당신은 목표 달성을 위해 끈기와 열정을 가지고 노력해요. \n \n 사교성이 좋아서 새로운 사람들을 만나는 일에 거리낌이 없으며, 사람들과 잘 어울립니다. 그만큼 관계를 중요시해요. 낙관적인 성격이라 어떤 상황에서도 긍정적으로 대처할 수 있기 때문에 주변에 긍정적인 에너지를 마구 발산하네요.",
  },
  {
    id: 12,
    best: "INFJ",
    image: `${INFJ}`,
    title: "햇살 아래 피어난 꽃",
    desc: "차분하고도 따뜻한 당신은 햇살을 받아 조용히 피어난 꽃을 연상케 합니다. 타인을 배려하는 모습과 이해심이 느껴지며, 말이나 행동에서도 섬세함이 느껴집니다. \n \n 인간관계를 중요하게 생각하기 때문에 다른 사람과 깊은 대화를 나누는 것을 좋아하죠. 어려운 상황에서도 인내력과 인내심을 가지고 끈기 있게 문제를 해결하는 성향이 있습니다. 당신의 주변 사람들은 당신의 끈기에 조용히 박수를 보내네요. \n \n 도덕적이고 원리원칙을 중요시하는 가치관을 갖고 있어 올곧고, 잔잔하고, 강단 있는 사람으로 비춰집니다.",
  },
  {
    id: 13,
    best: "ESFJ",
    image: `${ESFJ}`,
    title: "볕이 잘 드는 창가",
    desc: "당신은 조화롭고 평화로운 분위기를 자아냅니다. 마치 햇볕이 잘 드는 창가처럼 온화하고 따사롭네요. \n \n 당신은 대인관계가 좋고 어디서든 갈등을 조정하는 사람, 상담사 역할을 하는 사람입니다. 사람들과 상호작용하는 것에서 즐거움을 느끼고 높은 감수성과 감정적 지각력을 가졌습니다. 그래서 다른 이들의 감정을 잘 이해하고 그들이 느끼는 것을 공감합니다. \n \n 남들은 당신을 보며 ‘친절하다, 배려심이 있다’고 생각합니다. 일의 우선순위를 잘 파악하여 체계적으로 일을 하는 경향이 있으며,  다양한 관점에서 문제 해결을 할 줄 아는 사람입니다. ",
  },
  {
    id: 14,
    best: "ENTP",
    image: `${ENTP}`,
    title: "화려한 폭죽",
    desc: "당신은 호기심이 많고 두뇌회전이 빠릅니다. 마치 어두운 밤에 화려하게 터지는 폭죽같아요. 번뜩이는 아이디어, 뚜렷한 주관, 뛰어난 직관력으로 복잡한 문제도 간결하게 정리해내죠. \n \n 토론, 논쟁을 하며 자신과 타인의 세상을 확장 시키는 것을 좋아합니다. 풍부한 언어 능력으로 주제 한 개를 가지고 몇 시간이나 얘기할 수 있어요. 이런 모습을 보며 다른 사람들은 인정할 수밖에 없다며 혀를 내두릅니다. \n \n 외향적이고 사회적인 상호작용을 즐기지만, 동시에 개인주의 성향과 독립적인 성향을 가지고 있기 때문에 자꾸 궁금해지는 사람입니다.",
  },
  {
    id: 15,
    best: "ISTP",
    image: `${ISTP}`,
    title: "밤하늘의 별",
    desc: "자신만의 시간과 공간이 꼭 필요한 당신은 밤하늘의 별을 닮았습니다. 마음에 없는 이야기를 하지 않고 위선, 가식이 없는 모습이 묵묵히 제자리에서 빛나는 별처럼 보이거든요. \n \n 당신은 문제의 본질을 파악하는 능력이 뛰어나고 실용적인 해결책을 제시하는 편입니다. 직접 문제를 처리하는 손재주와 임기응변까지 갖춘 당신을 보며 주변 사람들은 재주가 많다고 생각합니다. \n \n 남들과 많은 시간을 보내는 타입은 아니기 때문에 오히려 당신을 더욱 신비로운 사람으로 보이게 하네요.",
  },
  {
    id: 16,
    best: "ENFJ",
    image: `${ENFJ}`,
    title: "가을의 억새밭",
    desc: "정이 많고 배려심이 깊은 당신은 포근한 분위기를 연상케 합니다. 가을의 억새밭 같아요. 다른 사람들의 이야기를 경청할 줄 알며, 온화하고 부드러운 태도로 소통하죠. \n \n 자신의 생각과 감정을 나누는 걸 좋아하는 당신은 관계를 중요하게 생각하며 이를 유지하기 위해 노력합니다. 당신이 있는 곳은 언제나 활기차고 기분 좋은 에너지가 감돕니다. \n \n 상대방과 소통하는 데에 능숙한 만큼 자신의 감정도 잘 표현하는 타입입니다. 자신이 헌신하고 베푼 호의를 당연하게 여기거나 이용하려 드는 경우가 종종 있어 마음이 툭 꺾일 때도 있지만, 그래도 괜찮습니다. 당신의 따뜻함을 기억하고 감사해하는 이들이 더 많다는 걸 기억하세요.",
  },
];
