import React, { useEffect } from "react";
import "./BlackcatTarotMain.css";
import { useNavigate } from "react-router-dom";
import Take5 from "../../../../components/Take5/Take5";
import SEO from "../../SEOko";
import { blackcattarotAttributeko } from "../../seoAttributesko";
import AdSenseComponent from "../../../../AdSenseComponent";
import TwinklingStars from "./TwinklingStars";
import main from "./img/blackcat_main.png";

const BlackcatTarotMain = () => {
  const navigate = useNavigate();

  const handleCardSelect = () => {
    navigate("/test/blackcattarot/select");
  };

  const id = "blackcattarot";

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="blackcat_main_Wrapper">
      <div className="blackcat_main_contents">
        <SEO attribute={blackcattarotAttributeko} id={id} />
        <Take5 />
        <div className="blackcat_main_container">
          <img src={main} alt="personalMessgaeTarot" />
        </div>
        <button className="blackcat_start_btn" onClick={handleCardSelect}>
          시작하기
        </button>
        <TwinklingStars />
      </div>
      <div className="blackcat_ADSC_container">
        <AdSenseComponent />
      </div>
    </div>
  );
};

export default BlackcatTarotMain;
