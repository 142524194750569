import React from "react";
import "./RealLoveMainen.css";
import { useNavigate } from "react-router-dom";
import main from "./img/reallove_Main.png";
import Take5 from "../../../../components/Take5/Take5";
import SEO from "../../SEOen";
import { realloveAttributeen } from "../../seoAttributesen";
import AdSenseComponent from "../../../../AdSenseComponent";

const RealLoveMainen = () => {
  const navigate = useNavigate();
  const QPage = () => {
    navigate("/test/en/realloveen/question");
  };

  const id = "realloveen";

  return (
    <div className="realloveen_wrapper">
      <div className="realloveen_contents">
        <SEO attribute={realloveAttributeen} id={id} />
        <Take5 />
        <div className="realloveen_main_container">
          <img src={main} alt="main" />
          <button onClick={QPage} className="realloveen_start_btn">
            LET'S GO!
          </button>
        </div>
      </div>
      <AdSenseComponent />
    </div>
  );
};

export default RealLoveMainen;
