import React from "react";
import ReactGA from "react-ga";
import "./InnerCoupang.css";
import innerimg from "./InnerCoupangImg.png";

const _eventSenderGA = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};

const InnerCoupangBanner = () => {
  window.open("https://link.coupang.com/a/bIVidi", "_blank");
  _eventSenderGA("Clicking", "Click Inner Coupang");
};

const InnerCoupang = () => {
  return (
    <div className="IC_container">
      <div className="IC_img_container" onClick={InnerCoupangBanner}>
        <img src={innerimg} alt="innerimg" />
      </div>
      <p className="IC_p">
        - 쿠팡 파트너스 활동의 일환으로 이에 따른 일정액의 수수료를 제공받습니다
        -
      </p>
    </div>
  );
};

export default InnerCoupang;
