import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Take5 from "../../../../components/Take5/Take5";
import questionData from "./data/questionData.json";
import AlcatineRko from "./AlcatineR";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import "./AlcatineQ.css";

const AlcatineQ = () => {
  const [answers, setAnswers] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const navigate = useNavigate();

  const calculateScore = () => {
    let alcoholScore = 0;
    let coffeeScore = 0;
    let tobaccoScore = 0;

    answers.forEach((answer, index) => {
      const categoryIndex = Math.floor(index / 3);
      const questionIndex = index % 3;
      const score =
        questionData[categoryIndex].questions[questionIndex].choices[answer]
          .score;

      switch (questionData[categoryIndex].category) {
        case "alcohol":
          alcoholScore += score;
          break;
        case "coffee":
          coffeeScore += score;
          break;
        case "tobacco":
          tobaccoScore += score;
          break;
        default:
          break;
      }
    });

    // 각 카테고리의 스코어를 0부터 100 사이의 값으로 변환 (반올림)
    alcoholScore = Math.round((alcoholScore / 300) * 100);
    coffeeScore = Math.round((coffeeScore / 300) * 100);
    tobaccoScore = Math.round((tobaccoScore / 200) * 100);

    return {
      alcoholScore,
      coffeeScore,
      tobaccoScore,
    };
  };

  const handleAnswer = (choiceIndex) => {
    const updatedAnswers = [...answers, choiceIndex];
    setAnswers(updatedAnswers);

    if (currentQuestionIndex < questionData.length * 3 - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      const result = calculateScore();
      navigate("/test/alcatine/result", { state: { result } });
    }
  };

  const Question = () => {
    const currentCategoryIndex = Math.floor(currentQuestionIndex / 3);
    const currentQuestion =
      questionData[currentCategoryIndex].questions[currentQuestionIndex % 3];

    return (
      <div className="alcatine_Q_Wrapper">
        <Take5 />
        <Progress
          percent={progressPercent}
          status="error"
          theme={{
            error: {
              symbol: "🍺",
              color: "#c6e78d",
            },
          }}
        />
        <h3 className="alcatine_Q_category">{currentQuestion.category}</h3>
        <p className="alcatine_Q_question">{currentQuestion.question}</p>
        {currentQuestion.choices.map((choice, choiceIndex) => (
          <button
            className="alcatine_Q_button"
            key={choiceIndex}
            onClick={() => handleAnswer(choiceIndex)}
          >
            {choice.text}
          </button>
        ))}
      </div>
    );
  };

  if (currentQuestionIndex === questionData.length * 3) {
    const result = calculateScore();
    return <AlcatineRko result={result} />;
  }

  const progressPercent =
    (currentQuestionIndex / (questionData.length * 3)) * 100;

  return (
    <>
      <Question />
    </>
  );
};

export default AlcatineQ;
