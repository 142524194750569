import React, { useEffect, useState } from "react";
import "./LovemoodMain.css";
import { useNavigate } from "react-router-dom";
import main from "./img/lovemood_Main.png";
import Take5 from "../../../../components/Take5/Take5";
import SEO from "../../SEOko";
import { lovemoodAttributeko } from "../../seoAttributesko";
import AdSenseComponent from "../../../../AdSenseComponent";

const LovemoodMain = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");

  const handleChange = (e) => {
    setName(e.target.value);
  };

  const QPage = () => {
    if (name.trim() === "") {
      alert("이름을 입력해주세요");
    } else {
      navigate("/test/lovemood/question", { state: { name } });
    }
  };
  const id = "lovemood";

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="lovemood_wrapper">
      <div className="lovemood_contents">
        <SEO attribute={lovemoodAttributeko} id={id} />
        <Take5 />
        <div className="lovemood_main_container">
          <img src={main} alt="main" />
          <div className="lovemood_input_container">
            <input
              type="text"
              placeholder="이름을 입력해주세요"
              value={name}
              onChange={handleChange}
              className="lovemood_name_input"
            />
          </div>
          <button onClick={QPage} className="lovemood_start_btn">
            시작하기
          </button>
        </div>
      </div>
      <AdSenseComponent />
    </div>
  );
};

export default LovemoodMain;
