import React from "react";
import "./TheHouseRen.css";
import Footer from "../../../../components/Footer/Footer";
import { useLocation, Link, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { ResultData } from "./data/ResultData";
import EnShareButton from "./EnShareButton";
import { SyncLoader } from "react-spinners";
import Take5 from "../../../../components/Take5/Take5";
import Card from "../../Carden";
import TestList from "../../TestListen.json";
// import AdSenseComponent from "../../../../AdSenseComponent";

const TheHouseRen = () => {
  const { mbti } = useParams();
  const location = useLocation();
  const [resultData, setResultData] = useState({});
  const navigate = useNavigate();
  const { name } = location.state || { name: "Nameless" };
  const [testList, setTestList] = useState([]);

  const excludedTestId = "thehouseen";
  const filteredTestList = testList.filter(
    (test) => test.id !== excludedTestId
  );

  useEffect(() => {
    setTestList(TestList);
  }, []);

  useEffect(() => {
    const result = ResultData.find((s) => s.best === mbti);
    setResultData(result);
  }, [mbti]);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, []);

  return (
    <>
      <div className="thehouseen_R_Wrapper">
        {loading ? (
          <div className="thehouseen_loading_container">
            <p className="thehouseen_loading_text">Visiting...🏠</p>
            <SyncLoader
              color={"#4d4434"}
              loading={loading}
              size={9}
              speedMultiplier={0.5}
              cssOverride={{
                marginTop: "3rem",
                marginBottom: "5rem",
                backgroundColor: "transparent",
              }}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        ) : (
          <>
            <Take5 />
            <div className="All_Test_has_cookie">
              <div className="thehouseen_R_Contents">
                <h2 className="thehouseen_R_nonTitle">{name}’s house is...</h2>
                <div className="thehouseen_R_ResultImg_Container">
                  <img src={resultData.image} alt="mbti" />
                </div>
                <div className="thehouseen_R_Restart_Btn_Container">
                  <button
                    className="thehouseen_R_Restart_Btn"
                    style={{
                      minWidth: "210px",
                      maxWidth: "210px",
                      minHeight: "50px",
                      boxShadow: "none",
                    }}
                    onClick={() => navigate("/test/en/thehouseen")}
                  >
                    Do it again
                  </button>
                </div>
                <EnShareButton />
                <div className="TestList_container">
                  {filteredTestList.map((test) => (
                    <div key={test.id} className="TestList_container2">
                      <Link to={`/test/en/${test.id}`}>
                        <Card test={test} />
                      </Link>
                    </div>
                  ))}
                </div>
                <Footer />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default TheHouseRen;
