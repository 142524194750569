import React, { useEffect, useState } from "react";
import tarotData from "./data/tarotData";
import "./BlackcatTarotR.css";
import Footer from "../../../../components/Footer/Footer";
import { Link, useNavigate, useLocation } from "react-router-dom";
import ShareButton from "./ShareButton";
import { SyncLoader } from "react-spinners";
import Take5 from "../../../../components/Take5/Take5";
import CoupangBanner from "../../CoupangBanner/CoupangBanner";
import Card from "../../Cardko";
import TestList from "../../TestListko.json";
import CoupangPromotionCP from "../../CoupangPromotionCP/CoupangPromotionCP";
// import AdSenseComponent from "../../../../AdSenseComponent";
import title from "./img/tarot_title.png";
import TwinklingStars from "./TwinklingStars";
import InnerCoupang from "../../InnerCoupang/InnerCoupang";

const BlackcatTarotR = () => {
  const [randomCard, setRandomCard] = useState(null);
  const navigate = useNavigate();
  const [showResult, setShowResult] = useState(false);
  const [hasCookie, setHasCookie] = useState(false);
  const [testList, setTestList] = useState([]);
  const location = useLocation();
  const cardId = location.state?.selectedCardId;

  const excludedTestId = "blackcattarot";
  const filteredTestList = testList.filter(
    (test) => test.id !== excludedTestId
  );

  useEffect(() => {
    setTestList(TestList);
  }, []);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, []);

  useEffect(() => {
    setHasCookie(document.cookie.indexOf("coupang=true") !== -1);
  }, []);

  const handleClick = () => {
    setShowResult(true);
    handleResultShow();
  };

  const handleResultShow = () => {
    const resultContainer = document.querySelector(".blackcat_no_cookie");
    resultContainer.style.overflow = "visible";
    resultContainer.style.height = "100%";
  };

  useEffect(() => {
    const cardIndex = Math.floor(Math.random() * tarotData.length);
    const selectedCard = tarotData[cardIndex];
    setRandomCard(selectedCard);
  }, [cardId]);

  return (
    <>
      <div className="blackcat_R_Wrapper">
        {loading ? (
          <div className="blackcat_loading_container">
            <p className="blackcat_loading_text">카드 꺼내는 중...🔮</p>
            <SyncLoader
              color={"#ffffff"}
              loading={loading}
              size={9}
              speedMultiplier={0.5}
              cssOverride={{
                marginTop: "3rem",
                backgroundColor: "transparent",
              }}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
            <CoupangPromotionCP />
            <div className="blackcat_ADSC_container">
              {/* <AdSenseComponent /> */}
            </div>
          </div>
        ) : (
          <>
            {hasCookie ? (
              <>
                <div className="All_Test_has_cookie">
                  <div className="blackcat_R_Contents">
                    <TwinklingStars />
                    <Take5 />
                    <img src={title} alt="blackcat_title" />
                    <p className="blackcat_R_p">카드의 결과는...</p>
                    <div
                      className="blackcat_R_ResultImg_Container"
                      id="coupangDiv"
                    >
                      {randomCard && (
                        <div className="blackcat_R_imgContainer">
                          <img
                            src={randomCard.image}
                            alt={`Card ${randomCard.id}`}
                          />
                          <p>{randomCard.interpretation}</p>
                        </div>
                      )}
                    </div>
                    <div className="blackcat_IC_container">
                      <InnerCoupang />
                    </div>
                    <div className="blackcat_R_Restart_Btn_Container">
                      <button
                        className="blackcat_R_Restart_Btn"
                        style={{
                          minWidth: "210px",
                          maxWidth: "210px",
                          minHeight: "50px",
                          boxShadow: "none",
                        }}
                        onClick={() => navigate("/test/blackcattarot")}
                      >
                        테스트 다시하기
                      </button>
                    </div>
                    <ShareButton />
                    <div className="TestList_container">
                      {filteredTestList.map((test) => (
                        <div key={test.id} className="TestList_container2">
                          <Link to={`/test/${test.id}`}>
                            <Card test={test} />
                          </Link>
                        </div>
                      ))}
                    </div>
                    <Footer />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="blackcat_no_cookie_container">
                  <div className="blackcat_no_cookie">
                    <div className="blackcat_R_Contents">
                      <Take5 />
                      <TwinklingStars />
                      <div
                        className="blackcat_R_ResultImg_Container"
                        id="coupangDiv"
                      >
                        <img src={title} alt="blackcat_title" />
                        <p className="blackcat_R_p">카드의 결과는...</p>

                        {randomCard && (
                          <div className="blackcat_R_imgContainer">
                            <img
                              src={randomCard.image}
                              alt={`Card ${randomCard.id}`}
                            />
                            <p>{randomCard.interpretation}</p>
                          </div>
                        )}
                      </div>
                      <div className="blackcat_Coupang_div">
                        <CoupangBanner handleClick={handleClick} />
                        <div className="blackcat_ADSC_container">
                          <div className="blackcat_ADSC_container">
                            {/* <AdSenseComponent /> */}
                          </div>
                        </div>
                      </div>
                      {showResult && (
                        <>
                          <div className="blackcat_IC_container">
                            <InnerCoupang />
                          </div>
                          <div className="blackcat_R_Restart_Btn_Container">
                            <button
                              className="blackcat_R_Restart_Btn"
                              style={{
                                minWidth: "210px",
                                maxWidth: "210px",
                                minHeight: "50px",
                                boxShadow: "none",
                              }}
                              onClick={() => navigate("/test/blackcattarot")}
                            >
                              테스트 다시하기
                            </button>
                          </div>
                          <ShareButton />
                          <div className="TestList_container">
                            {filteredTestList.map((test) => (
                              <div
                                key={test.id}
                                className="TestList_container2"
                              >
                                <Link to={`/test/${test.id}`}>
                                  <Card test={test} />
                                </Link>
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default BlackcatTarotR;
