export const QuestionData = [
  {
    id: 1,
    title: "내가 에너지를 충전하는 법은?",
    answera: "남들과 즐겁게 놀면서 충전하는 편",
    answerb: "혼자 알아서 충전하는 편",
    type: "EI",
  },
  {
    id: 2,
    title: "내가 더 선호하는 장소는?",
    answera: "SNS에서 난리난 핫플레이스",
    answerb: "조용하고 여유를 즐길 수 있는 곳",
    type: "EI",
  },
  {
    id: 3,
    title: "3박 4일로 국내여행을 가기로 했어.\n누구와 함께 가고 싶어?",
    answera: "친구나 연인, 가족",
    answerb: "혼자",
    type: "EI",
  },
  {
    id: 4,
    title: "기차로 이동하는 날,\n어떤 노래를 들을까?",
    answera: "평소에 듣는 플레이리스트 재생!",
    answerb: "지금의 기분, 분위기에 \n몰입할 수 있는 플레이리스트!",
    type: "SN",
  },
  {
    id: 5,
    title: "노래를 들으며 \n기차 밖을 보는 중..🚃 \n이때 나는?",
    answera: "날씨 좋네, 기분 좋다~",
    answerb: "(이미 나는 영화 주인공)",
    type: "SN",
  },
  {
    id: 6,
    title: `세상에! 엄청 멋진\n공예품을 발견했어. 이때 나는?`,
    answera: "와 대박.... (감상한다)",
    answerb: "와 재료가 뭐지? \n어떻게 만들었을까?",
    type: "SN",
  },
  {
    id: 7,
    title: `여행중인 내게 친구가 말해.\n"부럽다ㅠㅠ난 일하는 중"\n이때 나는?`,
    answera: `ㅋㅋㅋ곧 주말이다 힘내라~!`,
    answerb: `많이 바빠?\n담에 같이 놀러가자!!`,
    type: "TF",
  },
  {
    id: 8,
    title: "숙소에 도착했는데 문제가 좀 생겼어.\n이때 선호하는 응대 방식은?",
    answera: `문제가 생긴 이유에 대해 설명 후\n사과, 빠른 해결`,
    answerb: `내가 겪는 불편에 충분한 공감 후\n 사과, 해결, 작은 서비스`,
    type: "TF",
  },
  {
    id: 9,
    title: `숙소 측에서 사과의 의미로 \n와인 한 병을 줬어. \n이때 내 속마음은?`,
    answera: "오, 나중에 곁들일 것만 \n사 오면 되겠다.",
    answerb: "잘 해결돼서 괜찮은데 와인까지! \n기분 좋다ㅎㅎ",
    type: "TF",
  },
  {
    id: 10,
    title: "꼭 가보고 싶은 식당이 \n문을 닫았어. 이때 나는?",
    answera: "(갑자기 스트레스 빡 받음) \n하 씨 어디 가지...",
    answerb: "(좀 당황스럽지만 어쩔 수 없지)\n흠 주변에 먹을만한 곳 또 없나?",
    type: "JP",
  },
  {
    id: 11,
    title: "여행 마지막 날!\n오늘 내 일정은?",
    answera: "이미 정해져있음",
    answerb: "그 때 그 때 정함",
    type: "JP",
  },
  {
    id: 12,
    title: "집에 도착한 나는?",
    answera: "대강이라도 짐 정리를 해둬야 \n마음이 편해!",
    answerb: "아우~ 짐 정리? 나중에 할래",
    type: "JP",
  },
];
