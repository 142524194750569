import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Take5 from "../../../../components/Take5/Take5";
import "./SistersQ.css";
import questions from "./data/questions.json";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import QbackImg from "./img/Q_backImg.png";

const SistersQ = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { olderSister, youngerSister } = location.state || {};
  const [answers, setAnswers] = useState({});
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const handleAnswerChange = (questionId, type) => {
    const updatedAnswers = {
      ...answers,
      [questionId]: type,
    };
    setAnswers(updatedAnswers);

    if (currentQuestionIndex < questions.length - 1) {
      setTimeout(() => {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
        window.scrollTo(0, 0);
      }, 100); // 답변 후 잠깐의 지연 시간 추가
    } else {
      // 마지막 질문일 경우 결과 계산 후 제출
      handleSubmit(updatedAnswers);
    }
  };

  const handleSubmit = (updatedAnswers) => {
    const categories = {
      Q: 0,
      B: 0,
      T: 0,
      C: 0,
      E: 0,
      D: 0,
      A: 0,
      S: 0,
    };

    const categoryQuestions = {
      Q_B: [1, 2, 3], // 티격태격형/조화형 질문 3개
      T_C: [4, 5, 6], // 비슷형/상반형 질문 3개
      E_D: [7, 8, 9], // 감정적으로 교류/데면데면형 질문 3개
      A_S: [10, 11, 12], // 활동적/차분형 질문 3개
    };

    for (const category in categoryQuestions) {
      const questionIds = categoryQuestions[category];
      questionIds.forEach((id) => {
        const type = updatedAnswers[id];
        if (type) {
          categories[type]++;
        }
      });
    }

    const resultType = `${categories.Q >= categories.B ? "Q" : "B"}${
      categories.T >= categories.C ? "T" : "C"
    }${categories.E >= categories.D ? "E" : "D"}${
      categories.A >= categories.S ? "A" : "S"
    }`;

    navigate(`/test/sisters/result/${resultType}`, {
      state: { olderSister, youngerSister, resultType },
    });
  };

  const calculateProgress = () => {
    const answeredQuestions = Object.keys(answers).length;
    return Math.round((answeredQuestions / questions.length) * 100);
  };

  const currentQuestion = questions[currentQuestionIndex];

  return (
    <div className="sisters_Q_Wrapper">
      <Take5 />

      <Progress
        percent={calculateProgress()}
        theme={{
          active: {
            symbol: `👩🏻‍❤️‍👩🏼`,
            color: "#21bfdf7a",
          },
        }}
      />
      <div className="sisters_Q_BackImg">
        <img src={QbackImg} alt="sisters" />
      </div>
      <div className="sisters_Q_Contents">
        <div key={currentQuestion.id} className="sisters_Q_question">
          <h3 className="sisters_Q_Title">{currentQuestion.question}</h3>
          <div className="sisters_Q_Btn_Container">
            {currentQuestion.options.map((option, index) => (
              <button
                key={index}
                className={`sisters_Q_option_button ${
                  answers[currentQuestion.id] === option.type ? "selected" : ""
                }`}
                onClick={() =>
                  handleAnswerChange(currentQuestion.id, option.type)
                }
              >
                {option.text}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SistersQ;
