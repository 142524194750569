import React, { useEffect } from "react";
import "./AdSenseComponent.css";

const AdSenseComponent = () => {
  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  return (
    <div className="Ad_container">
      <ins
        className="adsbygoogle"
        style={{
          display: "inline-block",
          width: 340,
          height: 330,
        }}
        data-ad-client="ca-pub-1190530836873529"
        data-ad-slot="5409828135"
      ></ins>
    </div>
  );
};

export default AdSenseComponent;
