import React from "react";
import "./EnShareButton.css";
import Swal from "sweetalert2";
import { CopyToClipboard } from "react-copy-to-clipboard";
import share from "./img/share.png";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";

const Sawl = () => {
  require("sweetalert2");
  Swal.fire("Copy!");
};

function EnSharebutton() {
  const currentUrl = "https://zombiembti.net/test/en/yourmooden";
  const testTitle = "Your Mood TEST | TAKEFIVE TEST";

  return (
    <>
      <div className="FlexContainer_en">
        <CopyToClipboard text={currentUrl}>
          <div className="URLShareButton_en" onClick={Sawl}>
            <img src={share} alt="url" />
          </div>
        </CopyToClipboard>
        <div className="GridContainer_en">
          <FacebookShareButton
            style={{ marginRight: "20px", marginTop: "5px" }}
            url={currentUrl}
            quote={testTitle}
            hashtag={"#" + testTitle.replace(/\s/g, "")}
          >
            <FacebookIcon
              size={32}
              round={true}
              borderRadius={24}
            ></FacebookIcon>
          </FacebookShareButton>

          <TwitterShareButton
            style={{ marginRight: "20px", marginTop: "5px" }}
            url={currentUrl}
            title={testTitle}
            hashtags={[
              "yourmooodTEST",
              "mbtitest",
              "Personalitytest",
              "Typetest",
              "Takefivetest",
            ]}
          >
            <TwitterIcon size={32} round={true} borderRadius={24}></TwitterIcon>
          </TwitterShareButton>
        </div>
      </div>
    </>
  );
}

export default EnSharebutton;
