export const QuestionData = [
  {
    id: 1,
    title: "I was busy throughout the weekdays. \nAs the weekend approaches...",
    answera: "I need to de-stress! \nI'm going out to have fun.",
    answerb: "Home is the best... I'll be alone.",
    type: "EI",
  },
  {
    id: 2,
    title:
      "I'm hanging out with a friend, \nand if my friend asks, \n'Can I invite my friend?'",
    answera: "Your friend is my friend! \nInvite them!",
    answerb: "Let's hang out another time!",
    type: "EI",
  },
  {
    id: 3,
    title: "Among friends, my role is...",
    answera: "I'm the one who talks a lot.",
    answerb: "I'm mainly the listener.",
    type: "EI",
  },
  {
    id: 4,
    title: "If a friend says,\n 'Why were we born?'",
    answera: "? Well, we were just born... \nWhat's the matter?",
    answerb: "I engage in a debate about the purpose of human existence.",
    type: "SN",
  },
  {
    id: 5,
    title: "My preferred style of \nsong lyrics is...",
    answera: "Lyrics that resonate with \neveryday life and reality.",
    answerb:
      "How do they come up with such lyrics? Lyrics that evoke imagination and admiration.",
    type: "SN",
  },
  {
    id: 6,
    title: "When explaining something, I...",
    answera: "Express it with straightforward facts, no frills",
    answerb: "Describe it richly with various metaphors",
    type: "SN",
  },
  {
    id: 7,
    title: "If I hear the words \n'I really like you!'",
    answera: "Why? What aspects do you like about me? (I'm really curious)",
    answerb: "Oh my... really? (That's so touching)",
    type: "TF",
  },
  {
    id: 8,
    title: "When I say 'I just had \na car accident,' it means...",
    answera:
      "It means you should keep this in mind because we need to deal with it first.",
    answerb: "Could you please ask me about my current condition?",
    type: "TF",
  },
  {
    id: 9,
    title:
      "If someone says \n'It seems like you're doing it casually, \nbut why are you so good at it?'",
    answera: "(I take it as a compliment)",
    answerb: "(I worked hard, and you say I did it casually; it's hurtful)",
    type: "TF",
  },
  {
    id: 10,
    title: "When I plan a trip...",
    answera: "Efficiency in terms of time and itinerary is a top priority.",
    answerb: "I'll go wherever my feet take me.",
    type: "JP",
  },
  {
    id: 11,
    title: "My desired daily life is...",
    answera: "A stable daily life with routines.",
    answerb: "A daily life that's constantly new and diverse.",
    type: "JP",
  },
  {
    id: 12,
    title: "After eating, I...",
    answera: "Immediately do the dishes.",
    answerb: "I'll do it later.",
    type: "JP",
  },
];
