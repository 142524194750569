import React, { useEffect, useState, useMemo } from "react";
import "./Pick2024R.css";
import Footer from "../../../../components/Footer/Footer";
import { Link, useNavigate, useLocation } from "react-router-dom";
import ShareButton from "./ShareButton";
import { MoonLoader } from "react-spinners";
import Take5 from "../../../../components/Take5/Take5";
import CoupangBanner from "../../CoupangBanner/CoupangBanner";
import Card from "../../Cardko";
import femaleWord from "./data/femaleWord.json";
import manWords from "./data/manWord.json";
import TestListko from "../../TestListko.json";
import pick2024backImg from "./img/pick2024backImg.png";
import scoreImages from "./data/scoreImages.js";
import maleImages from "./data/maleImages.js";
import CoupangPromotionCP from "../../CoupangPromotionCP/CoupangPromotionCP";
// import AdSenseComponent from "../../../../AdSenseComponent.js";
import InnerCoupang from "../../InnerCoupang/InnerCoupang.js";

function Pick2024R() {
  const navigate = useNavigate();
  const location = useLocation();
  const gender = location.state?.gender || "female";
  const targetScores = useMemo(() => {
    return location.state?.targetScores || [];
  }, [location.state?.targetScores]);
  const name = location.state?.name || "";

  const [showResult, setShowResult] = useState(false);
  const [hasCookie, setHasCookie] = useState(false);
  const [testList, setTestList] = useState([]);

  const excludedTestId = "pick2024";
  const filteredTestList = testList.filter(
    (test) => test.id !== excludedTestId
  );

  useEffect(() => {
    setTestList(TestListko);
  }, []);

  useEffect(() => {
    setHasCookie(document.cookie.indexOf("coupang=true") !== -1);
  }, []);

  const handleClick = () => {
    setShowResult(true);
    handleResultShow();
  };

  const handleResultShow = () => {
    const resultContainer = document.querySelector(".All_Test_no_cookie");
    resultContainer.style.overflow = "visible";
    resultContainer.style.height = "100%";
  };

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3500);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {}, 0);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const renderPickRecipe = () => {
    const getTwoWordByScore = (score) => {
      let wordList;
      let imageList;
      if (gender === "male") {
        wordList = manWords;
        imageList = maleImages;
      } else {
        wordList = femaleWord;
        imageList = scoreImages;
      }

      const wordObj = wordList.find((wordObj) => wordObj.score === score);
      const imageObj = imageList.find((image) => image.score === score);

      return wordObj && imageObj
        ? { word: wordObj.word, image: imageObj.image }
        : null;
    };

    const twoWord = getTwoWordByScore(targetScores[1]);
    let genderImage;

    if (gender === "male") {
      genderImage = twoWord?.image;
    } else {
      genderImage = scoreImages.find(
        (image) => image.score === targetScores[1]
      )?.image;
    }

    return (
      <div className="pick2024_result_all_container">
        <div className="pick2024_resultImg">
          <img src={pick2024backImg} alt="" />
        </div>

        <div className="pick2024_reslut_container">
          <div className="pick2024_input_name">
            <span className="pick2024_name">{name}</span>님은
            <br />
            2024년도에...
          </div>
          <div className="pick2024_two_container">
            <span className="pick2024_two">{twoWord?.word}</span>
          </div>
        </div>
        <div className="pickImg_container">
          {genderImage && (
            <img src={genderImage} alt={`Score ${targetScores[1]}`} />
          )}
        </div>
        <p className="pick2024_desc">꾹 눌러 이미지를 저장하세용!</p>
      </div>
    );
  };

  return (
    <>
      <div className="pick2024_R_Wrapper">
        {loading ? (
          <div className="pick2024_loading_container">
            <p className="pick2024_loading_text">
              운세 뽑는 중...🪽
              <MoonLoader
                color={"#1fadff"}
                loading={loading}
                size={50}
                cssOverride={{
                  marginTop: "2rem",
                  backgroundColor: "transparent",
                }}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </p>
            <CoupangPromotionCP />
            {/* <AdSenseComponent /> */}
          </div>
        ) : (
          <>
            <Take5 />
            {hasCookie ? (
              <>
                <div className="pick2024_has_cookie">
                  <div className="pick2024_R_Contents">
                    <div className="pick2024_R_Result_Container">
                      {renderPickRecipe()}
                    </div>
                    <div className="pick2024_IC_container">
                      <InnerCoupang />
                    </div>
                    <div className="pick2024_R_Restart_Btn_Container">
                      <button
                        className="pick2024_R_Restart_Btn"
                        style={{
                          minWidth: "210px",
                          maxWidth: "210px",
                          minHeight: "50px",
                          boxShadow: "none",
                        }}
                        onClick={() => navigate("/test/pick2024")}
                      >
                        테스트 다시하기
                      </button>
                    </div>
                    <ShareButton />
                    {/* <AdSenseComponent /> */}
                    <div className="TestList_container">
                      {filteredTestList.map((test) => (
                        <div key={test.id} className="TestList_container2">
                          <Link to={`/test/${test.id}`}>
                            <Card test={test} />
                          </Link>
                        </div>
                      ))}
                    </div>
                    <Footer />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="All_Test_no_cookie_container">
                  <div className="All_Test_no_cookie">
                    <div className="pick2024_R_Contents">
                      <div className="pick2024_R_Result_Container">
                        {renderPickRecipe()}
                      </div>
                      <div className="pick2024_Coupang_div">
                        <CoupangBanner handleClick={handleClick} />
                        {/* <AdSenseComponent /> */}
                      </div>

                      {showResult && (
                        <>
                          <div className="pick2024_IC_container">
                            <InnerCoupang />
                          </div>
                          <div className="pick2024_R_Restart_Btn_Container">
                            <button
                              className="pick2024_R_Restart_Btn"
                              style={{
                                minWidth: "210px",
                                maxWidth: "210px",
                                minHeight: "50px",
                                boxShadow: "none",
                              }}
                              onClick={() => navigate("/test/pick2024")}
                            >
                              테스트 다시하기
                            </button>
                          </div>
                          <ShareButton />
                          {/* <AdSenseComponent /> */}
                          <div className="TestList_container">
                            {filteredTestList.map((test) => (
                              <div
                                key={test.id}
                                className="TestList_container2"
                              >
                                <Link to={`/test/${test.id}`}>
                                  <Card test={test} />
                                </Link>
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}
export default Pick2024R;
