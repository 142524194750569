import React, { useState } from "react";
import "./BlackcartTarotCarden.css";
import { useNavigate } from "react-router-dom";
import Take5 from "../../../../components/Take5/Take5";
import SEO from "../../SEOen";
import { blackcattarotAttributeen } from "../../seoAttributesen";
import AdSenseComponent from "../../../../AdSenseComponent";
import TwinklingStars from "./TwinklingStars";
import title from "./img/tarot_title.png";
import cardData from "./data/MainCardData";

const BlackcartTarotCarden = () => {
  const [shuffledCards, setShuffledCards] = useState(
    [...cardData].sort(() => Math.random() - 0.5).slice(0, 4)
  );
  const [isShuffling, setIsShuffling] = useState(false);
  const navigate = useNavigate();

  const handleCardSelect = () => {
    navigate("/test/en/blackcattaroten/result");
  };

  const handleShuffleCards = () => {
    setIsShuffling(true);

    setTimeout(() => {
      const shuffled = [...cardData].sort(() => Math.random() - 0.5);
      const firstFourCards = shuffled.slice(0, 4);
      setShuffledCards(firstFourCards);
      setIsShuffling(false);
    }, 1000);
  };

  const id = "blackcattaroten";

  return (
    <div className="blackcaten_Wrapper">
      <div className="blackcaten_contents">
        <SEO attribute={blackcattarotAttributeen} id={id} />
        <Take5 />
        <div className="blackcaten_card_container">
          <img src={title} alt="personalMessgaeTarot" />
          <p className="blackcaten_card_p">
            Think of the question for which you need advice, and choose one
            card.
          </p>

          <div className="blackcaten_back_container">
            {shuffledCards.map((card) => (
              <img
                key={card.id}
                src={card.image}
                alt={`Card${card.id}`}
                onClick={handleCardSelect}
                className={isShuffling ? "shuffling" : ""}
              />
            ))}
          </div>
        </div>
        <button className="blackcaten_Btn" onClick={handleShuffleCards}>
          Shuffle the cards
        </button>
        <TwinklingStars />
      </div>
      <div className="blackcaten_ADSC_container">
        <AdSenseComponent />
      </div>
    </div>
  );
};

export default BlackcartTarotCarden;
