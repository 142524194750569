import React, { useEffect, useState } from "react";
import "./StabilityMain.css";
import { useNavigate } from "react-router-dom";
import main from "./img/lovethink_Main.png";
import Take5 from "../../../../components/Take5/Take5";
import SEO from "../../SEOko";
import { stabilityAttributeko } from "../../seoAttributesko";
import AdSenseComponent from "../../../../AdSenseComponent";

const StabilityMain = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");

  const handleChange = (e) => {
    setName(e.target.value);
  };

  const QPage = () => {
    if (name.trim() === "") {
      alert("이름을 입력해주세요");
    } else {
      navigate("/test/stability/question", { state: { name } });
    }
  };

  const id = "stability";

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="stability_wrapper">
      <div className="stability_contents">
        <SEO attribute={stabilityAttributeko} id={id} />
        <Take5 />

        <div className="stability_main_container">
          <img src={main} alt="main" />
          <div className="stability_input_container">
            <input
              type="text"
              placeholder="이름을 입력해주세요"
              value={name}
              onChange={handleChange}
              className="stability_name_input"
            />
          </div>
        </div>
        <div className="stability_btn_container">
          <button onClick={QPage} className="stability_start_btn">
            시작하기
          </button>
        </div>
      </div>
      <AdSenseComponent />
    </div>
  );
};

export default StabilityMain;
