import React from "react";
import "./DonutChart.css";

const DonutChart = ({ percentage, radius, color, backgroundColor }) => {
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (percentage / 100) * circumference;
  const roundedPercentage = percentage.toFixed(0); // 소수점 제한

  return (
    <div className="Alcatine_result_chart">
      <div className="donut-chart">
        <svg className="donut-chart__svg">
          <circle
            className="donut-chart__background"
            cx="50"
            cy="50"
            r={radius}
            stroke={backgroundColor}
            strokeWidth="10"
            fill="transparent"
          />
          <circle
            className="donut-chart__progress"
            cx="50"
            cy="50"
            r={radius}
            stroke={color}
            strokeWidth="10"
            strokeDasharray={circumference}
            strokeDashoffset={offset}
            fill="transparent"
          />
        </svg>
        <div className="donut_chart_p_continaer">
          <div className="donut-chart__percentage">{roundedPercentage}%</div>
        </div>
      </div>
    </div>
  );
};

export default DonutChart;
