import React, { useState, useEffect, Fragment } from "react";
import ReactGA from "react-ga";
import { useCookies } from "react-cookie";
import "./CoupangBanner.css";

const CoupangBanner = ({ handleClick }) => {
  const [showBanner, setShowBanner] = useState(true);
  const [countdown, setCountdown] = useState(5);
  const [showCloseText, setShowCloseText] = useState(false);
  const [cookies, setCookie] = useCookies(["coupang"]);

  const _eventSenderGA = (category, action, label) => {
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    });
  };

  useEffect(() => {
    if (countdown === 0) {
      setShowCloseText(true);
      return; // 카운트다운이 0일 때 더 이상 진행하지 않도록 종료
    }

    const interval = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1); // 이전 카운트다운 값에서 1씩 감소
    }, 1000);

    return () => clearInterval(interval); // 컴포넌트 언마운트 시 인터벌 해제
  }, [countdown]);

  const handleBannerClose = () => {
    handleClick();
    setShowBanner(false);
    setCookie("coupang", true, {
      path: "/",
      maxAge: 60 * 60 * 2,
      secure: true,
    });
  };

  useEffect(() => {
    if (cookies.coupang) {
      setShowBanner(false);
    }
  }, [cookies]);

  // 엄마 쿠팡
  const handleIframeClick = () => {
    window.open("https://link.coupang.com/a/3vuei", "_blank");
    setShowBanner(false);
    setCookie("coupang", true, {
      path: "/",
      maxAge: 60 * 60 * 2,
      secure: true,
    });
    _eventSenderGA("Clicking", "MOM_Coupang Banner");
    handleClick();
  };
  return (
    <Fragment>
      {showBanner && (
        <div className="school_ct1">
          <div className="school_ct2">
            <div className="school_coupang_container">
              <p className="school_p">
                쿠팡 방문은 재밌는 콘텐츠를 제공할 수 있는 힘이 돼요! <br />
                테이크파이브의 콘텐츠를 사랑해주셔서 감사합니다💞
              </p>
              <p className="school_count">
                {" "}
                {countdown > 0 ? (
                  countdown
                ) : showCloseText ? (
                  <button
                    className="school_coupang_close_btn"
                    onClick={handleBannerClose}
                  >
                    X
                  </button>
                ) : (
                  ""
                )}
              </p>
              <div className="school_go_to_coupang_container">
                <button
                  className="school_go_to_coupang_btn"
                  onClick={handleIframeClick}
                >
                  <p>버튼 누르고 결과보기</p>
                </button>
                <p className="school_p2">
                  - 쿠팡 파트너스 활동의 일환으로 이에 따른 일정액의 수수료를
                  제공받습니다 -
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default CoupangBanner;
