import React from "react";
import "./SchoolResult.css";
import Footer from "../../../../components/Footer/Footer";
import { MoonLoader } from "react-spinners";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { ResultData } from "./data/resultdata";
import ShareButton from "./ShareButton";
import ReactGA from "react-ga";
import Take5 from "../../../../components/Take5/Take5";
import CoupangBanner from "./CoupangBanner";
import Card from "../../Cardko";
import TestList from "../../TestListko.json";

const SchoolResult = () => {
  const { mbti } = useParams();
  const [resultData, setResultData] = useState({});
  const navigate = useNavigate();
  const [showResult, setShowResult] = useState(false);
  const [hasCookie, setHasCookie] = useState(false);
  const [testList, setTestList] = useState([]);

  const excludedTestId = "school";
  const filteredTestList = testList.filter(
    (test) => test.id !== excludedTestId
  );

  useEffect(() => {
    setTestList(TestList);
  }, []);

  useEffect(() => {
    // 결과를 찾을 때 동적으로 읽어온 MBTI 유형을 사용
    const result = ResultData.find((s) => s.best === mbti);
    setResultData(result);
    ReactGA.pageview(window.location.pathname);
  }, [mbti]);

  useEffect(() => {
    setHasCookie(document.cookie.indexOf("coupang=true") !== -1);
  }, []);

  const handleClick = () => {
    setShowResult(true);
    handleResultShow();
  };

  const handleResultShow = () => {
    const resultContainer = document.querySelector(".School_no_cookie");
    resultContainer.style.overflow = "visible";
    resultContainer.style.height = "100vh";
  };

  useEffect(() => {
    const result = ResultData.find((s) => s.best === mbti);
    setResultData(result);
    ReactGA.pageview(window.location.pathname);
  }, [mbti]);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2500);
  }, []);

  const reloadBtn = () => {
    navigate("/test/school");
  };

  return (
    <>
      <div className="School_Hash">
        {loading ? (
          <MoonLoader
            color={"#57cdff"}
            loading={loading}
            size={90}
            cssOverride={{
              marginTop: "15rem",
              marginBottom: "20rem",
              backgroundColor: "transparent",
            }}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        ) : (
          <>
            <div className="School_R_Wrapper">
              <Take5 />
              {hasCookie ? (
                <>
                  <div className="School_has_cookie">
                    <div className="School_R_Title">초딩 MBTI</div>
                    <div className="School_R_SubTitle">나는 그때...🧒🏻</div>
                    <div className="School_R_Img">
                      <img src={resultData.image} alt="" />
                    </div>
                    <div className="School_R_Btn_Container">
                      <button
                        className="School_R_Restart_Btn"
                        onClick={reloadBtn}
                      >
                        다시하기
                      </button>
                      <ShareButton />
                    </div>
                    <div className="TestList_container">
                      {filteredTestList.map((test) => (
                        <div key={test.id} className="TestList_container2">
                          <Link to={`/test/${test.id}`}>
                            <Card test={test} />
                          </Link>
                        </div>
                      ))}
                    </div>
                    <Footer />
                  </div>
                </>
              ) : (
                <>
                  <div className="School_no_cookie_container">
                    <div className="School_no_cookie">
                      <div className="School_R_Title">초딩 MBTI</div>
                      <div className="School_R_SubTitle">나는 그때...🧒🏻</div>
                      <div className="School_coupang_banner">
                        <CoupangBanner handleClick={handleClick} />
                      </div>
                      <div className="School_R_Img">
                        <img src={resultData.image} alt="" />
                      </div>
                      {showResult && (
                        <>
                          <div className="School_R_Btn_Container">
                            <button
                              className="School_R_Restart_Btn"
                              onClick={reloadBtn}
                            >
                              다시하기
                            </button>

                            <ShareButton />
                          </div>
                          <div className="TestList_container">
                            {filteredTestList.map((test) => (
                              <div
                                key={test.id}
                                className="TestList_container2"
                              >
                                <Link to={`/test/${test.id}`}>
                                  <Card test={test} />
                                </Link>
                              </div>
                            ))}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default SchoolResult;
