export const QuestionData = [
  {
    question: "취미 모임에 갔는데\n내 스타일인 사람이 있네?",
    answers: [
      {
        option: "(오호라… 넌 내꺼다!)\n안녕하세요! 성함이 어떻게 되세요?",
        score: 5,
      },
      { option: "(ㅎㅎ자연스럽게 친해져야지)\n조용히 기회를 노린다", score: 1 },
    ],
  },
  {
    question: " 연인이 없다는 걸 확인한 나는…",
    answers: [
      { option: "(호감을 적극적으로 표현한다)", score: 5 },
      { option: "(천천히 알아가자!)", score: 0 },
    ],
  },
  {
    question: "다같이 술을 마시러 갔어.\n이때 나는,",
    answers: [
      { option: "(오늘이 기회다!)\n옆자리를 사수한다", score: 4 },
      { option: "(부담스러워하진 않을까?)\n같은 테이블로 만족한다", score: 1 },
    ],
  },
  {
    question:
      "나도 모르게 그 사람을 빤히 쳐다봤나 봐\n눈이 마주쳤어! 이때 나는?",
    answers: [
      { option: "헛. 너무 쳐다봤나?\n나도 모르게 눈을 피한다", score: 0 },
      { option: "눈을 피하지 않고 싱긋 웃어준다", score: 5 },
    ],
  },
  {
    question: "그 사람이 조금 취한 것 같은데...",
    answers: [
      { option: "(너무 티내는 건 쑥스러우니까…)\n여기 물 좀 마셔요", score: 2 },
      { option: "(둘만 있을 기회다!)\n잠깐 바람 쐴래요?", score: 5 },
    ],
  },
  {
    question: "어라, 그 사람이 밖으로\n나가는 것 같은데?",
    answers: [
      { option: "걱정되니까 따라나간다", score: 5 },
      { option: "궁금하지만 쳐다보기만 한다", score: 0 },
    ],
  },
  {
    question: "그 사람이 주변 사람들과\n인스타 친구를 맺고 있어!",
    answers: [
      { option: "(헉 나도나도!)\n저랑도 맞팔해요!", score: 4 },
      { option: "(나한테도 물어봐주기를 기다린다)", score: 0 },
    ],
  },
  {
    question: "이상형 이야기가 나왔어. \n이때 나는?",
    answers: [
      {
        option: "(누군가가 물어보길 기다리다가\n그 사람의 대답을 기억한다)",
        score: 0,
      },
      {
        option: "(단도직입적으로 묻는다)\nㅇㅇ씨는 이상형이 뭐예요?",
        score: 5,
      },
    ],
  },
  {
    question: "이제 내 이상형 이야기 할 차례야.\n이때 나는?",
    answers: [
      { option: "(콕 집어서)\n저는… ㅇㅇ씨 같은 사람이요^-^", score: 5 },
      {
        option: "(그 사람의 특징을 돌려서)\n저는 눈이 예쁘고, 목소리가 좋은…?",
        score: 2,
      },
    ],
  },
  {
    question: "이제 집에 가는 분위기!\n단둘이 이야기 할 기회가 생겼는데...",
    answers: [
      { option: "(적극 어필)\nㅇㅇ씨, 모임 전에 밥 한번 먹을래요?", score: 5 },
      { option: "(아직은 천천히!)\n다음 모임에도 오실 거죠~?", score: 3 },
    ],
  },
  {
    question: "나는 그 사람에게 먼저 연락을",
    answers: [
      { option: "할 수 있다!", score: 4 },
      { option: "해볼까..? 말까...?", score: 2 },
    ],
  },
];
