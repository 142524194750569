const manWords = [
  { score: 1, image: require("../img/scoreImg/score63.png") },
  { score: 2, image: require("../img/scoreImg/score64.png") },
  { score: 3, image: require("../img/scoreImg/score65.png") },
  { score: 4, image: require("../img/scoreImg/score66.png") },
  { score: 5, image: require("../img/scoreImg/score67.png") },
  { score: 6, image: require("../img/scoreImg/score68.png") },
  { score: 7, image: require("../img/scoreImg/score69.png") },
  { score: 8, image: require("../img/scoreImg/score70.png") },
  { score: 9, image: require("../img/scoreImg/score48.png") },
  { score: 10, image: require("../img/scoreImg/score49.png") },
  { score: 11, image: require("../img/scoreImg/score50.png") },
  { score: 12, image: require("../img/scoreImg/score51.png") },
  { score: 13, image: require("../img/scoreImg/score1.png") },
  { score: 14, image: require("../img/scoreImg/score16.png") },
  { score: 15, image: require("../img/scoreImg/score17.png") },
  { score: 16, image: require("../img/scoreImg/score18.png") },
  { score: 17, image: require("../img/scoreImg/score19.png") },
  { score: 18, image: require("../img/scoreImg/score41.png") },
  { score: 19, image: require("../img/scoreImg/score21.png") },
  { score: 20, image: require("../img/scoreImg/score22.png") },
  { score: 21, image: require("../img/scoreImg/score23.png") },
  { score: 22, image: require("../img/scoreImg/score24.png") },
  { score: 23, image: require("../img/scoreImg/score25.png") },
  { score: 24, image: require("../img/scoreImg/score13.png") },
  { score: 25, image: require("../img/scoreImg/score9.png") },
  { score: 26, image: require("../img/scoreImg/score2.png") },
  { score: 27, image: require("../img/scoreImg/score3.png") },
  { score: 28, image: require("../img/scoreImg/score4.png") },
  { score: 29, image: require("../img/scoreImg/score5.png") },
  { score: 30, image: require("../img/scoreImg/score6.png") },
  { score: 31, image: require("../img/scoreImg/score7.png") },
  { score: 32, image: require("../img/scoreImg/score31.png") },
  { score: 33, image: require("../img/scoreImg/score32.png") },
  { score: 34, image: require("../img/scoreImg/score52.png") },
  { score: 35, image: require("../img/scoreImg/score53.png") },
  { score: 36, image: require("../img/scoreImg/score54.png") },
  { score: 37, image: require("../img/scoreImg/score8.png") },
  { score: 38, image: require("../img/scoreImg/score14.png") },
  { score: 39, image: require("../img/scoreImg/score46.png") },
  { score: 40, image: require("../img/scoreImg/score47.png") },
  { score: 41, image: require("../img/scoreImg/score33.png") },
  { score: 42, image: require("../img/scoreImg/score34.png") },
  { score: 43, image: require("../img/scoreImg/score35.png") },
  { score: 44, image: require("../img/scoreImg/score36.png") },
  { score: 45, image: require("../img/scoreImg/score37.png") },
  { score: 46, image: require("../img/scoreImg/score38.png") },
  { score: 47, image: require("../img/scoreImg/score15.png") },
  { score: 48, image: require("../img/scoreImg/score2.png") },
  { score: 49, image: require("../img/scoreImg/score44.png") },
  { score: 50, image: require("../img/scoreImg/score45.png") },
  { score: 51, image: require("../img/scoreImg/score42.png") },
  { score: 52, image: require("../img/scoreImg/score43.png") },
  { score: 53, image: require("../img/scoreImg/score60.png") },
  { score: 54, image: require("../img/scoreImg/score61.png") },
  { score: 55, image: require("../img/scoreImg/score62.png") },
  { score: 56, image: require("../img/scoreImg/score12.png") },
  { score: 57, image: require("../img/scoreImg/score39.png") },
  { score: 58, image: require("../img/scoreImg/score40.png") },
  { score: 59, image: require("../img/scoreImg/score26.png") },
  { score: 60, image: require("../img/scoreImg/score27.png") },
  { score: 61, image: require("../img/scoreImg/score28.png") },
  { score: 62, image: require("../img/scoreImg/score29.png") },
  { score: 63, image: require("../img/scoreImg/score30.png") },
  { score: 64, image: require("../img/scoreImg/score55.png") },
  { score: 65, image: require("../img/scoreImg/score56.png") },
  { score: 66, image: require("../img/scoreImg/score57.png") },
  { score: 67, image: require("../img/scoreImg/score58.png") },
  { score: 68, image: require("../img/scoreImg/score59.png") },
  { score: 69, image: require("../img/scoreImg/score10.png") },
  { score: 70, image: require("../img/scoreImg/score11.png") },
];

export default manWords;
