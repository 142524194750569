export const questionData = [
  {
    id: 1,
    title: "あなたの第一印象に対する\n最も一般的なコメントは何ですか?",
    answera: "エネルギッシュ",
    answerb: "冷静",
    type: "EI",
  },
  {
    id: 2,
    title: "あなたにとってどちらの日が\nより快適ですか?",
    answera: "人と交流する忙しい日",
    answerb: "一人で静かに自分のことをする静かな日",
    type: "EI",
  },
  {
    id: 3,
    title: "新しい人と会うとき、\n通常どのような態度を取りますか?",
    answera: "会話でリードしようとする",
    answerb: "ある程度の距離から観察する",
    type: "EI",
  },

  {
    id: 4,
    title: "あなたが主に経験する\nストレスのタイプは何ですか?",
    answera: "迅速に決定する必要のあるタスク",
    answerb: "まだ起こっていないが\nまもなく起こりそうなこと",
    type: "SN",
  },
  {
    id: 5,
    title: "想像力や夢想に頻繁にふけりますか?",
    answera: "いいえ",
    answerb: "はい",
    type: "SN",
  },
  {
    id: 6,
    title: "他の人に話をするとき、\n通常どのようにアプローチしますか?",
    answera: "明確で体系的に出来事の順序を示す",
    answerb:
      "出来事の順序を示しながら、\n時折自分の考えや感情を隠喩を使って表現する",
    type: "SN",
  },

  {
    id: 7,
    title:
      "友達がうっかりあなたの\n持ち物を壊してしまった。\nどの謝罪があなたを\nもっと良く感じさせるでしょうか?",
    answera: "出来事の状況を説明する",
    answerb: "心から謝る",
    type: "TF",
  },
  {
    id: 8,
    title: "あなたはどちらをより重視しますか?",
    answera: "論理、合理的な分析",
    answerb: "感情、感情の表現",
    type: "TF",
  },
  {
    id: 9,
    title:
      "久しぶりに知り合いにメッセージを送ったが、\n奇妙なことに返信がない。\nあなたの心に思い浮かぶのは何ですか?",
    answera: "彼らの電話番号が変わったのか?",
    answerb: "私に対して何か彼らが\n腹を立てていることがあるのか?",
    type: "TF",
  },
  {
    id: 10,
    title: "計画通りにいかない日。\nあなたの心に思い浮かぶのは?",
    answera: "(...うるさい、早くこの日を終わらせたい)",
    answerb: "(まあ、今日は計画通りにいっていない。\nこんな日もあるよね?)",
    type: "JP",
  },
  {
    id: 11,
    title: "計画を立てるとき、\n通常はBプランや\nCプランについて考えますか?",
    answera: "はい",
    answerb: "いいえ",
    type: "JP",
  },
  {
    id: 12,
    title: "不確かな可能性に簡単に\n身を投じることができますか?",
    answera:
      "本当にやらなければならない場合はやりますが...本当にやる必要がありますか?",
    answerb: "それが面白そうならすぐにやります",
    type: "JP",
  },
];
