import React from "react";
import "./ZombieQuestion.css";
import { Progress } from "react-sweet-progress";
import "react-sweet-progress/lib/style.css";
import { useNavigate } from "react-router-dom";
import { QuestionData } from "./data/questionData";
import Take5 from "../../../../components/Take5/Take5";

const ZombieQuestion = () => {
  const [questionNo, setQuestionNo] = React.useState(0);
  const [totalScore, setTotalScore] = React.useState([
    { id: "EI", score: 0 },
    { id: "SN", score: 0 },
    { id: "TF", score: 0 },
    { id: "JP", score: 0 },
  ]);
  const navigate = useNavigate();

  const handleClickButton = (no, type) => {
    const newScore = totalScore.map((s) =>
      s.id === type ? { id: s.id, score: s.score + no } : s
    );

    setTotalScore(newScore);

    if (QuestionData.length !== questionNo + 1) {
      // 다음문제로 문제수 증가
      setQuestionNo(questionNo + 1);
    } else {
      // mbti도출
      const mbti = newScore.reduce(
        (acc, curr) =>
          acc +
          (curr.score >= 2 ? curr.id.substring(0, 1) : curr.id.substring(1, 2)),
        ""
      );
      navigate(`/test/zombie/result/${mbti}`);
    }
  };

  return (
    <div className="Zombie_Q_Wrapper">
      <Take5 />
      <Progress
        percent={(questionNo / QuestionData.length) * 100}
        status="error"
        theme={{
          error: {
            symbol: "🧟‍♀️",
            color: "#d81731",
          },
        }}
      />
      <div className="Zombie_Q_container">
        <div className="Zombie_Q_contents">
          <div className="Zombie_Q_question">
            {QuestionData[questionNo].title}
          </div>
          <div className="Zombie_Q_btn_container">
            <button
              className="Zombie_Q_btn_style"
              style={{
                minWidth: "310px",
                maxWidth: "310px",
                minHeight: "120px",
                maxHeight: "120px",
                boxShadow: "none",
              }}
              onClick={() =>
                handleClickButton(1, QuestionData[questionNo].type)
              }
              type="button"
            >
              {QuestionData[questionNo].answera}
            </button>
            <button
              className="Zombie_Q_btn_style"
              style={{
                minWidth: "310px",
                maxWidth: "310px",
                minHeight: "120px",
                maxHeight: "120px",
                boxShadow: "none",
              }}
              onClick={() =>
                handleClickButton(0, QuestionData[questionNo].type)
              }
              type="button"
            >
              {QuestionData[questionNo].answerb}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ZombieQuestion;
