import React from "react";
import "./PlaylistMainen.css";
import { useNavigate } from "react-router-dom";
import main from "./img/playlist_main.png";
import Take5 from "../../../../components/Take5/Take5";
import SEO from "../../SEOen";
import { playlistAttributeen } from "../../seoAttributesen";
import AdSenseComponent from "../../../../AdSenseComponent";

const PlaylistMainen = () => {
  const navigate = useNavigate();
  const rpGotoQ = () => {
    navigate("/test/en/playlisten/question");
  };

  const id = "playlisten";

  return (
    <div className="playlisten_wrapper">
      <div className="playlisten_contents">
        <SEO attribute={playlistAttributeen} id={id} />
        <Take5 />
        <div className="playlisten_main_container">
          <img src={main} alt="main" />
          <button onClick={rpGotoQ} className="playlisten_start_btn_container">
            Get Start!
          </button>
        </div>
      </div>
      <AdSenseComponent />
    </div>
  );
};

export default PlaylistMainen;
