import React, { useState } from "react";
import "./TestCategorySelector.css";

function TestCategorySelector({ onSelectCategory }) {
  const categories = ["All", "MBTI", "연애", "성격유형", "그 외"];
  const [selectedCategory, setSelectedCategory] = useState("All");

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    onSelectCategory(category);
  };

  return (
    <div className="TestCategorySelector">
      {categories.map((category) => (
        <button
          key={category}
          className={selectedCategory === category ? "active" : ""}
          onClick={() => handleCategoryClick(category)}
        >
          {category}
        </button>
      ))}
    </div>
  );
}

export default TestCategorySelector;
