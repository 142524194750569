import React from "react";
import "./ZombieMainjp.css";
import { useNavigate } from "react-router-dom";
import main from "./img/zombiejp_Main.png";
import Take5 from "../../../../components/Take5/Take5";
import SEO from "../../SEOjp";
import { zombieAttributejp } from "../../seoAttributesjp";
import AdSenseComponent from "../../../../AdSenseComponent";

const ZombieMainjp = () => {
  const navigate = useNavigate();
  const QPage = () => {
    navigate("/test/jp/zombiejp/question");
  };

  const id = "zombiejp";

  return (
    <div className="zombiejp_wrapper">
      <div className="zombiejp_contents">
        <SEO attribute={zombieAttributejp} id={id} />
        <Take5 />
        <div className="zombiejp_main_container">
          <img src={main} alt="main" />
          <button onClick={QPage} className="zombiejp_start_btn">
            始める
          </button>
        </div>
      </div>
      <AdSenseComponent />
    </div>
  );
};

export default ZombieMainjp;
