import React, { useState, useEffect } from "react";
import "./LoveBalanceR.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { QuestionData } from "./data/QuestionData";
import Footer from "../../../../components/Footer/Footer";
import ShareButton from "./ShareButton";
import { SyncLoader } from "react-spinners";
import Take5 from "../../../../components/Take5/Take5";
import CoupangBanner from "../../CoupangBanner/CoupangBanner";
import Card from "../../Cardko";
import TestList from "../../TestListko.json";
import CoupangPromotionCP from "../../CoupangPromotionCP/CoupangPromotionCP";
// import AdSenseComponent from "../../../../AdSenseComponent";
import lovebalanceRback from "./img/lovebalanceR_back.png";
import InnerCoupang from "../../InnerCoupang/InnerCoupang";

const LoveBalanceR = () => {
  const location = useLocation();
  const selectedAnswers = location.state?.selectedAnswers || [];
  const navigate = useNavigate();
  const [showResult, setShowResult] = useState(false);
  const [hasCookie, setHasCookie] = useState(false);
  const [testList, setTestList] = useState([]);

  const excludedTestId = "lovebalance";
  const filteredTestList = testList.filter(
    (test) => test.id !== excludedTestId
  );

  useEffect(() => {
    setTestList(TestList);
  }, []);

  useEffect(() => {
    setHasCookie(document.cookie.indexOf("coupang=true") !== -1);
  }, []);

  const handleClick = () => {
    setShowResult(true);
    handleResultShow();
  };

  const handleResultShow = () => {
    const resultContainer = document.querySelector(".lovebalance_no_cookie");
    resultContainer.style.overflow = "visible";
    resultContainer.style.height = "100%";
  };

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, []);

  return (
    <>
      <div className="lovebalance_R_Wrapper">
        {loading ? (
          <div className="lovebalance_loading_container">
            <p className="lovebalance_loading_text">선택지 정리중..⚖️</p>
            <SyncLoader
              color={"#119eab"}
              loading={loading}
              size={9}
              speedMultiplier={0.5}
              cssOverride={{
                marginTop: "3rem",
                backgroundColor: "transparent",
              }}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
            <CoupangPromotionCP />
            {/* <AdSenseComponent /> */}
          </div>
        ) : (
          <>
            <Take5 />
            {hasCookie ? (
              <>
                <div className="All_Test_has_cookie">
                  <div className="lovebalance_R_Container">
                    <div
                      className="lovebalance_R_ResultImg_Container"
                      id="coupangDiv"
                    >
                      <img src={lovebalanceRback} alt="back" />
                      <div className="lovebalance_R_Contents">
                        {QuestionData.map((question, index) => (
                          <div key={index} className="lovebalance_R_question">
                            <p className="lovebalance_R_button">
                              <span
                                className={`lovebalance_R_option ${
                                  selectedAnswers[index] === question.answera
                                    ? "selected"
                                    : ""
                                }`}
                              >
                                {question.answera}
                              </span>
                            </p>
                            <span className="lovebalance_R_title">
                              {question.title}
                            </span>
                            <p className="lovebalance_R_button">
                              <span
                                className={`lovebalance_R_option ${
                                  selectedAnswers[index] === question.answerb
                                    ? "selected"
                                    : ""
                                }`}
                              >
                                {question.answerb}
                              </span>
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="lovebalance_IC_container">
                    <InnerCoupang />
                  </div>
                  <div className="lovebalance_R_Restart_Btn_Container">
                    <button
                      className="lovebalance_R_Restart_Btn"
                      style={{
                        minWidth: "210px",
                        maxWidth: "210px",
                        minHeight: "50px",
                        boxShadow: "none",
                      }}
                      onClick={() => navigate("/test/lovebalance")}
                    >
                      테스트 다시하기
                    </button>
                  </div>
                  <ShareButton />
                  <div className="TestList_container">
                    {filteredTestList.map((test) => (
                      <div key={test.id} className="TestList_container2">
                        <Link to={`/test/${test.id}`}>
                          <Card test={test} />
                        </Link>
                      </div>
                    ))}
                  </div>
                  <Footer />
                </div>
              </>
            ) : (
              <>
                <div className="lovebalance_cookie_container">
                  <div className="lovebalance_no_cookie">
                    <div className="lovebalance_R_Container">
                      <div
                        className="lovebalance_R_ResultImg_Container"
                        id="coupangDiv"
                      >
                        <img src={lovebalanceRback} alt="back" />
                        <div className="lovebalance_R_Contents">
                          {QuestionData.map((question, index) => (
                            <div key={index} className="lovebalance_R_question">
                              <p className="lovebalance_R_button">
                                <span
                                  className={`lovebalance_R_option ${
                                    selectedAnswers[index] === question.answera
                                      ? "selected"
                                      : ""
                                  }`}
                                >
                                  {question.answera}
                                </span>
                              </p>
                              <span className="lovebalance_R_title">
                                {question.title}
                              </span>
                              <p className="lovebalance_R_button">
                                <span
                                  className={`lovebalance_R_option ${
                                    selectedAnswers[index] === question.answerb
                                      ? "selected"
                                      : ""
                                  }`}
                                >
                                  {question.answerb}
                                </span>
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="lovebalance_Coupang_div">
                      <CoupangBanner handleClick={handleClick} />
                      {/* <AdSenseComponent /> */}
                    </div>
                    {showResult && (
                      <>
                        <div className="lovebalance_IC_container">
                          <InnerCoupang />
                        </div>
                        <div className="lovebalance_R_Restart_Btn_Container">
                          <button
                            className="lovebalance_R_Restart_Btn"
                            style={{
                              minWidth: "210px",
                              maxWidth: "210px",
                              minHeight: "50px",
                              boxShadow: "none",
                            }}
                            onClick={() => navigate("/test/lovebalance")}
                          >
                            테스트 다시하기
                          </button>
                        </div>
                        <ShareButton />
                        <div className="TestList_container">
                          {filteredTestList.map((test) => (
                            <div key={test.id} className="TestList_container2">
                              <Link to={`/test/${test.id}`}>
                                <Card test={test} />
                              </Link>
                            </div>
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default LoveBalanceR;
