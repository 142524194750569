import React, { useEffect, useState } from "react";
import "./SistersMain.css";
import { useNavigate } from "react-router-dom";
import main from "./img/MainImg.png";
import Take5 from "../../../../components/Take5/Take5";
import SEO from "../../SEOko";
import { sistersAttributeko } from "../../seoAttributesko";
import AdSenseComponent from "../../../../AdSenseComponent";

const SistersMain = () => {
  const navigate = useNavigate();

  const [sisterNames, setSisterNames] = useState({
    olderSister: "",
    youngerSister: "",
  });

  const QPage = () => {
    if (!sisterNames.olderSister || !sisterNames.youngerSister) {
      alert("이름을 입력해줘!");
      return;
    }
    navigate("/test/sisters/question", { state: sisterNames });
  };

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSisterNames({
      ...sisterNames,
      [name]: value,
    });
  };

  return (
    <div className="sisters_wrapper">
      <div className="sisters_contents">
        <SEO attribute={sistersAttributeko} id="sisters" />
        <Take5 />
        <div className="sisters_main_container">
          <img src={main} alt="main" />
          <div className="sisters_input_container">
            <input
              type="text"
              name="olderSister"
              placeholder="이름을 적어줘!"
              value={sisterNames.olderSister}
              onChange={handleInputChange}
              className="sister_input"
            />
            <input
              type="text"
              name="youngerSister"
              placeholder="이름을 적어줘!"
              value={sisterNames.youngerSister}
              onChange={handleInputChange}
              className="sister_input"
            />
          </div>
          <button onClick={QPage} className="sisters_start_btn">
            시작하기
          </button>
        </div>
      </div>
      <AdSenseComponent />
    </div>
  );
};

export default SistersMain;
