import React, { useEffect } from "react";
import "./RP2024loveMain.css";
import { useNavigate } from "react-router-dom";
import Take5 from "../../../../components/Take5/Take5";
import RP2024_main from "./img/RP2024_main.png";
import SEO from "../../SEOko";
import { rollingpaper2024loveAttributeko } from "../../seoAttributesko";
import AdSenseComponent from "../../../../AdSenseComponent";

const RP2024loveMain = () => {
  const navigate = useNavigate();
  const rpGotoQ = () => {
    navigate("/test/rollingpaper2024love/question");
  };

  const id = "rollingpaper2024love";

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="RP2024love_wrapper">
      <div className="RP2024love_contents">
        <SEO attribute={rollingpaper2024loveAttributeko} id={id} />
        <Take5 />
        <div className="RP2024love_main_container">
          <img src={RP2024_main} alt="main" />

          <button onClick={rpGotoQ} className="RP2024love_start_btn_container">
            시작하기
          </button>
        </div>
      </div>
      <AdSenseComponent />
    </div>
  );
};

export default RP2024loveMain;
